import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
const JobSeekerSection = ({
  type,
  title,
  cat,
  customSearch,
  filteredJobs,
  catList,
  country,
}) => {
  const [jobsList, setJobsList] = useState([]);
  const [iList, setiList] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/getrows/category")
      .then((response) => {
        if (response.data.result && Array.isArray(response.data.result)) {
          const data = {};
          for (const category of response.data.result) {
            data[category.id] = category;
          }
          setiList(data);
        } else {
          console.error("Invalid data format in API response");
        }
        setLoading(false); // Move setLoading inside the then block after setiList
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Ensure loading state is updated in case of error
      });
  }, []);

  //console.log(catList);
  // console.log(filteredJobs);
  return (
    <>
      <>
        <div
          className="home1-featured-area mb-120"
          style={{ background: "white !important", marginTop: "-80px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex flex-wrap align-items-end justify-content-md-between justify-content-start gap-3"></div>
            </div>
            <div className="row g-4">
              {filteredJobs.length > 0 &&
                filteredJobs.map((j, index) => {
                  return (
                    <div className="col-xl-4 col-lg-6" key={j.basic_info_id}>
                      <div
                        className="feature-card"
                        style={{
                          border: "2px solid #00a4e5",
                        }}
                      >
                        <div className="company-area">
                          <div className="company-details">
                            <div className="name-location">
                              <h5>
                                <a href={`/seeker_profile/${j.basic_info_id}`}>
                                  {`${j.first_name} ${j.last_name}`.length > 25
                                    ? `${j.first_name} ${j.last_name}`.slice(
                                        0,
                                        25
                                      ) + "..."
                                    : `${j.first_name} ${j.last_name}`}
                                </a>
                              </h5>
                              <p>
                                {j.designation +
                                  " | " +
                                  j.years_experience +
                                  "+ years experience"}
                              </p>
                              <p>
                                {j.country_name
                                  ? j.country_name
                                  : "No country selected"}
                              </p>
                            </div>
                            <div className="bookmark">
                              <i className="bi bi-bookmark" />
                            </div>
                          </div>
                        </div>
                        <div className="job-discription">
                          <ul>
                            <li>
                              <img src="assets/images/icon/arrow2.svg" alt="" />
                              {/* <p>
                                <span className="title">
                                  Current Job place:
                                </span>{" "}
                                {j.current_job_place}
                              </p> */}
                              <p>
                                <span className="title">
                                  Current Job place:
                                </span>{" "}
                                {j.current_job_place.length > 7
                                  ? j.current_job_place.substring(0, 7) + "..."
                                  : j.current_job_place}
                              </p>
                            </li>
                            <li>
                              <img src="assets/images/icon/arrow2.svg" alt="" />
                              <p>
                                <span className="title">Education:</span>{" "}
                                {/* {j.qualification} */}
                                {j.qualification.length > 10
                                  ? j.qualification.substring(0, 10) + "..."
                                  : j.qualification}
                              </p>
                            </li>
                            <li>
                              <img src="assets/images/icon/arrow2.svg" alt="" />
                              <p>
                                <span className="title">Current Salary:</span>{" "}
                                {/* {j.current_salary} */}
                                {j.current_salary.length > 10
                                  ? j.current_salary.substring(0, 10) + "..."
                                  : j.current_salary}
                              </p>
                            </li>
                            <li>
                              <img src="assets/images/icon/arrow2.svg" alt="" />
                              <p>
                                <span className="title">Expected Salary:</span>{" "}
                                {j.expected_salary}
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="job-type-apply">
                          <div
                            className="apply-btn"
                            style={{
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <a
                              href={`/seeker_profile/` + j.basic_info_id}
                              style={{
                                background: "#01aff3",
                                width: "100%",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                color: "white",
                                padding: "20px 0px",
                              }}
                            >
                              View Profile
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default JobSeekerSection;
