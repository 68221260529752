import React, { useState } from "react";
import Navigation from "../../components/Navbar/Navbar";
import TrendingCategorySection from "./TrendingCategorySection";
import JobsSection from "./JobsSection";
import { useEffect } from "react";
import axios from "axios";
import Footer from "../../components/Footer";
import { Button, Dropdown, Menu } from "antd";
import styles from "./homepage.module.css";
import { useRef } from "react";
import TopCompanies from "./TopCompanies";
import JobsSectionCustom from "./JobsSectionCustom";
import JobSeekerSection from "./JobSeekerSection";
import JobSeekerSectionCustom from "./JobSeekerSectionCustom";
const HomePage = () => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [catList, setCatList] = useState([]);
  const [catListF, setCatListF] = useState([]);
  const [selectedCat, setSelectedCat] = useState(0);
  const [jobCustomSearch, setJobCustomSearch] = useState(false);
  const [seekerCustomSearch, setSeekerCustomSearch] = useState(false);
  const [title, setTitle] = useState("Enter Title");
  //const [countryList, setCountryList] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [pakistan, setpakistan] = useState([]);
  const [pakistanSeekers, setpakistanSeekers] = useState([]);
  const [gulfcounties, setgulfcountiesn] = useState([]);
  const [gulfcountriesSeekers, setgulfcountriesSeekers] = useState([]);
  const [allcountriesSeekers, setallcountriesSeekers] = useState([]);
  const [euJobs, setEuJobs] = useState([]);
  const [euJobsSeekers, setEuJobsSeekers] = useState([]);
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  const [countrySelected, setCountrySelected] = useState("none");
  const [customFilterJob, setCustomFilterJobs] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  useEffect(() => {
    axios
      // .get("np")
      .get(process.env.REACT_APP_BASE_URL + "/static/getCategories")
      .then((response) => setCatList(response.data.result));
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/getrows/category")
      .then((response) => {
        setCatListF(response.data.result);
      });
  }, []);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/pakistanjobs")
      .then((response) => {
        console.log(response, "pakistan jobs");
        setpakistan(response.data.result);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/europejobs")
      .then((response) => {
        console.log(response, "eu jobs");
        setEuJobs(response.data.result);
      });
    // axios
    //   .get(process.env.REACT_APP_BASE_URL + "/static/europejobsSeekers")
    //   .then((response) => {
    //     console.log(response, "eu jobs Seekers");
    //     setEuJobsSeekers(response.data.result);
    //   });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/gulfcountries")
      .then((response) => {
        console.log(response, "gulf");
        setgulfcountiesn(response.data.result);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/static/allcountriesSeekers")
      .then((response) => {
        console.log(response, "all seekers");
        setallcountriesSeekers(response.data.result);
      });
  }, []);
  useEffect(() => {
    if (jobCustomSearch) {
      axios
        .post(
          process.env.REACT_APP_BASE_URL +
            `/static/jobCustomSearch/${
              title == "" ? "Enter Title" : title
            }/${selectedCat}`
        )
        .then((response) => {
          setFilteredJobs(response.data.result);
          setJobCustomSearch(true);

          setCustomFilterJobs(response.data.result);
        });
    }
  }, [jobCustomSearch, title, selectedCat]);
  useEffect(() => {
    if (seekerCustomSearch) {
      axios
        .post(
          process.env.REACT_APP_BASE_URL +
            `/static/seekerCustomSearch/${title == "" ? "Enter Title" : title}`
        )
        .then((response) => {
          setFilteredJobs(response.data.result);
          setSeekerCustomSearch(true);

          setCustomFilterJobs(response.data.result);
        });
    }
  }, [seekerCustomSearch, title]);
  const items = [
    {
      key: "1",
      label: (
        <a target="" rel="noopener noreferrer" href="/seekersignin">
          As Seeker
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="" rel="noopener noreferrer" href="/companysignin">
          As Employer
        </a>
      ),
    },
  ];
  const secondItems = [
    {
      key: "1",
      label: (
        <a target="" rel="noopener noreferrer" href="/signup/1">
          As Seeker
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="" rel="noopener noreferrer" href="/signup/2">
          As Employer
        </a>
      ),
    },
  ];
  return (
    <>
      <Navigation />
      <div className="hero1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className={`hero-content ${styles.heroContainer}`}>
                <div className={`${styles.heroHeading}`}>
                  <h1>
                    Find Best Job Seekers <span>Today.</span>
                  </h1>
                </div>
                <p className={`${styles.heroHeading}`}>
                  Find <span>thousands</span> of job seekers that matches your
                  requierement with our easy-to-use jobseeker portal.
                </p>
                <div className={`job-search-area ${styles.mobileSignup}`}>
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomRight"
                    arrow
                  >
                    <Button>Login</Button>
                  </Dropdown>
                  <Dropdown
                    overlay={
                      <Menu>
                        {secondItems.map((item) => (
                          <Menu.Item key={item.key}>{item.label}</Menu.Item>
                        ))}
                      </Menu>
                    }
                    placement="bottomRight"
                    arrow
                  >
                    <Button>SignUp</Button>
                  </Dropdown>
                </div>
                {/* <div className={`counter-area ${styles.counterContainer}`}>
                  <div className="row g-lg-4 g-md-5 gy-5 justify-content-center">
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single">
                        <div className="counter-icon">
                          <img src="assets/images/icon/job2.svg" alt="image" />
                        </div>
                        <div className="coundown">
                          <p>Live Jobs</p>
                          <div className="d-flex align-items-center gap-2">
                            <h3 className="odometer">20223</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single">
                        <div className="counter-icon">
                          <img
                            src="assets/images/icon/companies.svg"
                            alt="image"
                          />
                        </div>
                        <div className="coundown">
                          <p>Companies</p>
                          <div className="d-flex align-items-center gap-2">
                            <h3 className="odometer">804</h3>
                            <span>+</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single">
                        <div className="counter-icon">
                          <img
                            src="assets/images/icon/candidates.svg"
                            alt="image"
                          />
                        </div>
                        <div className="coundown">
                          <p>Candidates</p>
                          <div className="d-flex align-items-center gap-2">
                            <h3 className="odometer">500</h3>
                            <span>+</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single">
                        <div className="counter-icon">
                          <img
                            src="assets/images/icon/new-jobs.svg"
                            alt="image"
                          />
                        </div>
                        <div className="coundown">
                          <p>New Jobs</p>
                          <div className="d-flex align-items-center gap-2">
                            <h3 className="odometer">102</h3>
                            <span>+</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className={`job-search-area ${styles.mobileJobSearch}`}>
                  {/* <div className="tabs">
                    <button
                      className={
                        "btn  tablinks justify-content-center align-items-center p-2" +
                        (activeTab === 1 ? " active show" : "")
                      }
                      onClick={() => handleTabClick(1)}
                    >
                      <h6 className="m-0">Job Seeker</h6>
                    </button>
                    <button
                      className={
                        "btn  tablinks justify-content-center align-items-center p-2" +
                        (activeTab === 2 ? " active show" : "")
                      }
                      onClick={() => handleTabClick(2)}
                    >
                      <h6 className="m-0">Jobs</h6>
                    </button>
                  </div> */}
                  <div className="tabs d-flex">
                    <button
                      className={
                        "btn tablinks justify-content-center align-items-center custom-width p-2" +
                        (activeTab === 1 ? " active show" : "")
                      }
                      onClick={() => handleTabClick(1)}
                    >
                      <h6 className="m-0">Job Seeker</h6>
                    </button>
                    <button
                      className={
                        "btn tablinks justify-content-center align-items-center custom-width p-2" +
                        (activeTab === 2 ? " active show" : "")
                      }
                      onClick={() => handleTabClick(2)}
                    >
                      <h6 className="m-0">Jobs</h6>
                    </button>
                  </div>

                  <div className="code-snippets">
                    {activeTab === 1 && (
                      <form>
                        <div className="form-inner job-title">
                          <input
                            type="text"
                            placeholder="Title"
                            value={title == "Enter Title" ? "" : title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        {/* <div className="form-inner category">
                          <select
                            className="select1"
                            style={{
                              border: "none",
                              padding: "10px",
                              width: "200px",
                              background: "transparent",
                              marginTop: "6px",
                            }}
                          >
                            <option value={0}>Category</option>
                            {catListF.length > 0
                              ? catListF.map((c) => (
                                  <option key={c.id} value={c.id}>{c.name}</option>
                                ))
                              : ""}
                          </select>
                        </div> */}
                        <a href="#searhjob">
                          <div className="form-inner">
                            <br />
                            <button
                              type="submit"
                              className="primry-btn-2 "
                              onClick={(e) => {
                                e.preventDefault();
                                executeScroll();
                                setSeekerCustomSearch(true);
                              }}
                            >
                              <img
                                src="assets/images/icon/search-icon.svg"
                                alt=""
                              />{" "}
                              Search
                            </button>
                            <a href="/jobseekers">Advanced Search</a>
                          </div>
                        </a>
                      </form>
                    )}
                    {activeTab === 2 && (
                      <form>
                        <div className="form-inner job-title">
                          <input
                            type="text"
                            placeholder="Job Title"
                            value={title == "Enter Title" ? "" : title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <div className="form-inner category">
                          <select
                            className="select1"
                            style={{
                              border: "none",
                              padding: "10px",
                              width: "200px",
                              background: "transparent",
                              marginTop: "6px",
                            }}
                          >
                            <option value={0}>Category</option>
                            {catListF.length > 0
                              ? catListF.map((c) => (
                                  <option value={c.id}>{c.name}</option>
                                ))
                              : ""}
                          </select>
                        </div>
                        <a href="#searhjob">
                          <div className="form-inner">
                            <br />
                            <button
                              type="submit"
                              className="primry-btn-2 "
                              onClick={(e) => {
                                e.preventDefault();
                                executeScroll();
                                setJobCustomSearch(true);
                              }}
                            >
                              <img
                                src="assets/images/icon/search-icon.svg"
                                alt=""
                              />{" "}
                              Search
                            </button>
                            <a href="/jobs">Advanced Search</a>
                          </div>
                        </a>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      {activeTab === 2 && jobCustomSearch && (
        <div className="mt-5" style={{ marginBottom: "-150px" }}>
          <div className="container">
            <h1>Search Results</h1>
          </div>
          <div ref={myRef}>
            <JobsSectionCustom
              type={1}
              customSearch={jobCustomSearch}
              filteredJobs={customFilterJob}
              catList={catListF}
              country={countrySelected}
            />
          </div>
        </div>
      )}
      <br />
      <br />
      {activeTab === 2 && (
        <div>
          <div className="container">
            <h1>Gulf Jobs</h1>
          </div>
          <div ref={myRef} className="mt-5">
            <JobsSection
              type={1}
              customSearch={jobCustomSearch}
              filteredJobs={gulfcounties}
              catList={catListF}
              country="gulf"
            />
          </div>
          <div style={{ marginTop: "-150px" }}>
            <div className="container">
              <h1>Pakistani Jobs</h1>
            </div>
            <div ref={myRef} className="mt-1">
              <JobsSection
                type={1}
                customSearch={jobCustomSearch}
                filteredJobs={pakistan}
                catList={catListF}
                country="gulf"
              />
            </div>
          </div>
          <div style={{ marginTop: "-150px", marginBottom: "-150px" }}>
            <div className="container">
              <h1>European Jobs</h1>
            </div>
            <div ref={myRef} className="mt-1">
              <JobsSection
                type={1}
                customSearch={jobCustomSearch}
                filteredJobs={euJobs}
                catList={catListF}
                country="gulf"
              />
            </div>
          </div>
        </div>
      )}

      {activeTab === 1 && seekerCustomSearch && (
        <div className="mt-5" style={{ marginBottom: "-150px" }}>
          <div className="container">
            <h1>Search Results</h1>
          </div>
          <div ref={myRef}>
            <JobSeekerSectionCustom
              type={1}
              customSearch={seekerCustomSearch}
              filteredJobs={customFilterJob}
              catList={catListF}
              country={countrySelected}
            />
          </div>
        </div>
      )}
      {activeTab === 1 && (
        <div>
          <div className="container">
            <h1>All Job Seekers</h1>
          </div>
          <div ref={myRef} className="mt-5">
            <JobSeekerSection
              type={1}
              customSearch={seekerCustomSearch}
              filteredJobs={allcountriesSeekers}
              catList={catListF}
              country="all"
            />
          </div>
          {/* <div style={{ marginTop: "-150px" }}>
            <div className="container">
              <h1>Pakistani Job Seekers</h1>
            </div>
            <div ref={myRef} className="mt-1">
              <JobSeekerSection
                type={1}
                customSearch={seekerCustomSearch}
                filteredJobs={pakistanSeekers}
                catList={catListF}
                country="pakistan"
              />
            </div>
          </div> */}
          {/* <div style={{ marginTop: "-150px", marginBottom: "-150px" }}>
            <div className="container">
              <h1>European Job Seekers</h1>
            </div>
            <div ref={myRef} className="mt-1">
              <JobSeekerSection
                type={1}
                customSearch={seekerCustomSearch}
                filteredJobs={euJobsSeekers}
                catList={catListF}
                country="eu"
              />
            </div>
          </div> */}
        </div>
      )}
      <TrendingCategorySection catList={catList} />
      {/* <TopCompanies catList={catList} /> */}
      <div className="home1-work-process mb-120">
        <div className="container">
          <div className="row mb-60">
            <div className="col-12 d-flex justify-content-center">
              <div className="section-title1 text-center">
                <h2>
                  OGS Working <span>Process</span>
                </h2>
                <p>To find an energetic jobseeker that fulfill your needs.</p>
              </div>
            </div>
          </div>
          <div className="row g-lg-4 gy-5 justify-content-center">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="single-work-process one text-center">
                <div className="icon">
                  <img src="assets/images/icon/company_signup.svg" alt="" />
                </div>
                <div className="work-content">
                  <h5>
                    <a href="register.html">Account Create</a>
                  </h5>
                  <p>Create your account as Company &amp; be confident.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="single-work-process two text-center">
                <div className="icon">
                  <img src="assets/images/icon/seeker.svg" alt="" />
                </div>
                <div className="work-content">
                  <h5>
                    <a href="edit-profile.html">Find Jobseeker</a>
                  </h5>
                  <p>Find a jobseeker that matches your requirements.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="single-work-process one text-center">
                <div className="icon">
                  <img src="assets/images/icon/review_profile.svg" alt="" />
                </div>
                <div className="work-content">
                  <h5>
                    <a href="job-listing1.html">Review Profile </a>
                  </h5>
                  <p>Review Jobseeker Profle for details</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="single-work-process two text-center">
                <div className="icon">
                  <img src="assets/images/icon/hire.svg" alt="" />
                </div>
                <div className="work-content">
                  <h5>
                    <a href="job-listing1.html">Hire Jobseeker</a>
                  </h5>
                  <p>Hire a jobseeker with ease.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home1-review-area mb-120">
        <div className="container">
          <div className="row mb-60">
            <div className="col-12 d-flex flex-wrap align-items-end justify-content-md-between justify-content-start gap-3">
              <div className="section-title1">
                <h2>
                  Trusted User <span>Reviews</span>
                </h2>
                <p>
                  To choose your trending job dream &amp; to make future bright.
                </p>
              </div>
              <div className="swiper-btn1 d-flex align-items-center"></div>
            </div>
          </div>
          <div className="row">
            <div className="swiper testimonial-slider1">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-card">
                    <div className="author-img">
                      <img src="assets/images/bg/hr.jpg" alt="" />
                      <div className="quat-icon">
                        <img src="assets/images/icon/quat-icon.svg" alt="" />
                      </div>
                    </div>
                    <div className="testimonial-content">
                      <div className="author-review-area">
                        <div className="author-area">
                          <h5>Faysel bin Al Haij</h5>
                          <span>HR</span>
                        </div>
                        <ul>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-half" />
                          </li>
                        </ul>
                      </div>
                      <p>
                        We're thrilled to recommend OGS MANPOWER for their
                        exceptional recruitment services. Their professionalism
                        and attention to detail impressed us. The team was
                        helpful and responsive, making the process seamless. We
                        highly endorse OGS MANPOWER for any company's
                        recruitment needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-card">
                    <div className="author-img">
                      <img
                        src="assets/images/bg/testimonial-author2.png"
                        alt=""
                      />
                      <div className="quat-icon">
                        <img src="assets/images/icon/quat-icon.svg" alt="" />
                      </div>
                    </div>
                    <div className="testimonial-content">
                      <div className="author-review-area">
                        <div className="author-area">
                          <h5>Jordan Miycol</h5>
                          <span>UI/UX Engineer</span>
                        </div>
                        <ul>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-half" />
                          </li>
                        </ul>
                      </div>
                      <p>
                        On the other hand, we denounce with to the righteous
                        indignation and dislike the men who are so beguiled to
                        demoralized by the charms of pleasure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
