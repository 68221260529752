import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";

import { useParams } from "react-router-dom";
// import amiriFont from './public/assets/fonts/Amiri-Regular.ttf';
import jsPDF from "jspdf";

// import headerLogo from './assets/images/header1-logo.svg';

import image1 from "./logo.png";
import image2 from "./image-1715193665176.jpg";
import image3 from "./image-1715193665176.jpg";
import image4 from "./image-1715193665176.jpg";
import image5 from "./image-1715193665176.jpg";

Font.register({
  family: "Amiri",
  src: "/assets/fonts/Amiri-Regular.ttf",
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "left",
    fontFamily: "Oswald",
    color: "#0039a6",
  },
  text: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  imageLogo: {
    float: "right",
    border: "2px solid black",
    width: 100,
    height: 100,
    display: "block",
    marginleft: "auto",
    marginright: "auto",
  },
  // image: {
  //   marginVertical: 15,
  //   marginHorizontal: 100,
  // },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  author: {
    fontSize: 8,
    fontWeight: 200,
  },
  address: {
    fontSize: 8,
    fontWeight: 200,
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
    width: "100%",
  },
  header: {
    borderTop: 2,
  },
  bold: {
    fontWeight: "bold",
    margin: 3,
  },

  tableHeaderCell: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: "gray",
    padding: 8,
    marginTop: 5,
    margin: 20,
    border: "1px solid black",
    borderright: "1px solid #C1C3D1",
    bordercollapse: "separate",
  },
  colum: {
    border: 2,
    fontSize: 12,
  },

  textbold: {
    fontSize: 9,
  },

  secondRow: {
    fontSize: 7,
  },

  image: {
    width: 100,
    height: 100,
    // objectFit: "cover",
    // marginRight: 5,
  },
  headerCell: {
    display: "table",
    flexDirection: "row",
    padding: 10,
    marginTop: 75,
    // marginLeft: 15,
  },

  // <--- table Heading --->

  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    // fontSize: 8,
    // border: 2,
    // height: "5%",
  },
  row2: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#87CEFA",
  },
  cell: {
    flex: 1,
    padding: 2,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 15,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  // <--- table --->

  table2: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    // borderWidth: 1,
    borderColor: "#bfbfbf",
  },
  row3: {
    display: "flex",
    flexDirection: "row",
  },
  cell1: {
    flex: 1,
    padding: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10,
  },
  cardrow: {
    display: "flex",
    flexDirection: "row",
    padding: 30,
    // alignItems: "center",
    // justifyContent: "center",
  },

  imgcell: {
    flex: 1,
    padding: 5,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    alignItems: "center",
    justifyContent: "center",
  },
  imageLicence: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  cv: {
    // border: 2,
    marginBottom: 5,
    display: "flex",
  },
  cvtext: {
    color: "#0039a6",
    //textAlign: "center",
    marginLeft: "45%",
    marginTop: "5%",
    display: "flex",
  },
});

const Pdffile = ({ record_id }) => {
  const [records, setRecords] = useState([]);
  let { id } = useParams();

  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/static/jobseeker_data/" + record_id
      );
      setRecords(response.data.result[0]);
      console.log(records);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createAndDownloadPdf = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add text to the PDF
    doc.text("Hello World!", 10, 10);

    // Save the PDF
    doc.save("newPdf.pdf");
  };
  return (
    <Document>
      <Page style={styles.body}>
        <View style={[styles.headertop, { flexDirection: "row" }]}>
          <View style={styles.headertext}>
            <Text style={styles.title}>OGS MANPOWER</Text>
            <Text style={styles.author}>
              Recruiting Agency Overseas Employment Promoter
            </Text>
            <Text style={styles.author}>License No MPD/2978/Rwp</Text>
          </View>

          <View style={[styles.img, { margin: 5, height: 40 }]}>
            <Image
              style={[styles.imageLogo, { float: "left" }]}
              src={image1}
              cache={false}
            />
          </View>
          <View
            style={[styles.headertext, { float: "left", marginLeft: "20px" }]}
          >
            <Text style={[styles.title, { fontFamily: "Amiri" }]}>
              او جی ایس مین پاور
            </Text>
            <Text
              style={[
                styles.author,
                { fontFamily: "Amiri", marginLeft: "20px" },
              ]}
            >
              وكالة توظيف مروج التوظيف في الخارج
            </Text>
            <Text
              style={[
                styles.author,
                { fontFamily: "Amiri", marginLeft: "20px" },
              ]}
            >
              رقم الترخيص MPD/2978/روالبندي
            </Text>
          </View>
        </View>

        {/* <Image style={styles.image} src="/images/quijote1.jpg" /> */}
        <View style={[styles.row, styles.header]}></View>

        <Text style={styles.address}>
          Office#5, 2nd Floor, Noor Plaza Near Utillity Store, Chandni Chowk,
          Rawalpindi-Pakistan Ph: +92 51 4906572, Cell & Whatsapp: +92 300
          5352636
        </Text>
        <Text style={styles.address}>
          Website: www.ogsmanpower.com Email:ceo@ogsmanpower.com,
          ceoogs@gmail.com Skype ID: ceo.ogs
        </Text>

        <View style={[styles.table2, { marginTop: 10 }]}>
          <View style={[styles.row3, { backgroundColor: "#F6F9F9" }]}>
            <View style={styles.cell1}>
              <Text
                style={[
                  styles.secondRow,
                  { color: "green", fontSize: 13, fontFamily: "Amiri" },
                ]}
              >
                {" "}
                CV السيرة الذاتية{" "}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.table2}>
          <View style={[styles.row3, { backgroundColor: "#F6F9F9" }]}>
            <View style={styles.cell1}>
              <Text
                style={[styles.secondRow, { color: "green", fontSize: 13 }]}
              >
                {" "}
                APPLIED FOR{" "}
              </Text>
            </View>
            <View style={styles.cell1}>
              <Text
                style={[styles.secondRow, { color: "green", fontSize: 13 }]}
              >
                PRIVATE DRIVER
              </Text>
            </View>
            <View style={styles.cell1}>
              <Text
                style={[
                  styles.secondRow,
                  { color: "green", fontSize: 13, fontFamily: "Amiri" },
                ]}
              >
                {" "}
                سائق خاص
              </Text>
            </View>
            <View style={styles.cell1}>
              <Text
                style={[
                  styles.secondRow,
                  { color: "green", fontSize: 14, fontFamily: "Amiri" },
                ]}
              >
                {" "}
                قدم على
              </Text>
            </View>
          </View>
        </View>

        <View style={{ border: "solid", height: "75%" }}>
          <View style={{ flexDirection: "row", height: "18%" }}>
            <View style={[styles.img, { margin: 5 }]}>
              <Image
                style={styles.image}
                src={
                  records.profile_image
                    ? process.env.REACT_APP_BASE_URL +
                      "/profile_images/" +
                      records.profile_image
                    : process.env.REACT_APP_BASE_URL +
                      "/profile_images/dummy_profile.jpg"
                }
                cache={false}
              />
            </View>

            <View style={[styles.table2, { marginLeft: 50 }]}>
              <View style={styles.row3}>
                <View style={[styles.cell1, {}]}>
                  <Text style={[styles.secondRow, { fontSize: 8 }]}>
                    {" "}
                    Country
                  </Text>
                </View>
                <View style={[styles.cell1, { fontWeight: "bold" }]}>
                  <Text style={[styles.secondRow, { fontSize: 7 }]}>
                    {records && records.country_name !== undefined
                      ? records.country_name
                      : ""}
                  </Text>
                </View>
                <View
                  style={[
                    styles.cell1,
                    {
                      fontWeight: "bold",
                      color: "#0039a6",
                      fontFamily: "Amiri",
                    },
                  ]}
                >
                  <Text style={styles.secondRow}> متوقع موقع</Text>
                </View>
                <View style={[styles.cell1, { fontWeight: "bold" }]}>
                  <Text style={[styles.secondRow, { fontSize: 8 }]}>
                    {" "}
                    EXPECTED SALARY
                  </Text>
                </View>
                <View style={[styles.cell1, { fontWeight: "bold" }]}>
                  <Text style={[styles.secondRow, { fontSize: 8 }]}>
                    {records && records.expected_salary !== undefined
                      ? records.expected_salary
                      : ""}
                  </Text>
                </View>
                <View
                  style={[
                    styles.cell1,
                    {
                      fontWeight: "bold",
                      color: "#0039a6",
                      fontFamily: "Amiri",
                    },
                  ]}
                >
                  <Text style={styles.secondRow}> مُتوقع مرتب</Text>
                </View>
              </View>
              <View style={[styles.textblock, { fontSize: 10, marginTop: 15 }]}>
                <Text style={styles.textbold}>
                  {records && records.career_objective !== undefined
                    ? records.career_objective
                    : ""}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.row2}>
              <View style={styles.cell}>
                <Text>PERSONAL INFORMATION</Text>
              </View>
              <View style={styles.cell}>
                <Text>PASSPORT DETAIL</Text>
              </View>
            </View>
          </View>

          <View style={styles.table2}>
            <View style={styles.row3}>
              <View style={[styles.cell1, {}]}>
                <Text style={[styles.textbold]}> FULL NAME</Text>
              </View>
              <View style={styles.cell1}>
                <Text style={[styles.secondRow, {}]}>
                  {" "}
                  {records && records.first_name !== undefined
                    ? records.first_name + " " + records.last_name
                    : ""}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  الاسم الكامل
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> PASSPORT NO</Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>
                  {records && records.passport_number !== undefined
                    ? records.passport_number
                    : ""}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  رقم جواز السفر
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table2}>
            <View style={[styles.row3, { backgroundColor: "#F6F9F9" }]}>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> NATIONALITY </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>
                  {records && records.country_name !== undefined
                    ? records.country_name
                    : ""}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  جنسية
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> CNIC NO </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>
                  {records && records.national_id !== undefined
                    ? records.national_id
                    : ""}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  رقم التصنيع باستخدام
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table2}>
            <View style={styles.row3}>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> RELIGION </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>
                  {records && records.religion_name !== undefined
                    ? records.religion_name
                    : ""}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  دِين
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> DATE OF BIRTH</Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>
                  {records && records.dob !== undefined && records.dob !== "Invalid date" ? records.dob : "-"}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  تاريخ الميلاد
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table2}>
            <View style={[styles.row3, { backgroundColor: "#F6F9F9" }]}>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> AGE </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>
                  {records && records.age !== undefined ? records.age : ""}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  عمر
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> DATE OF ISSUE</Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>Not Provided</Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  تاريخ المسألة
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table2}>
            <View style={styles.row3}>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> ADDRESS </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>
                  {records && records.present_address !== undefined
                    ? records.present_address
                    : ""}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  عنوان
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> DATE OF EXPIRY </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>Not Provided</Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  تاريخ الانتهاء
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table2}>
            <View
              style={[
                styles.row3,
                { backgroundColor: "#F6F9F9", fontFamily: "Amiri" },
              ]}
            >
              <View style={styles.cell1}>
                <Text style={styles.textbold}> MARITAL STATUS</Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>
                  {records && records.marital_status_str !== undefined
                    ? records.marital_status_str
                    : ""}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  الحالة الاجتماعية
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> PLACE OF ISSUE</Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}> Not Provided </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  مكان الإصدار
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table2}>
            <View style={styles.row3}>
              <View style={styles.cell1}>
                <Text style={styles.textbold}> QUALIFICATIONS </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>
                  {records && records.edu_level !== undefined
                    ? records.edu_level
                    : ""}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    { color: "#0039a6", fontFamily: "Amiri" },
                  ]}
                >
                  {" "}
                  مؤهلات
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.table2}>
            <View style={[styles.row3, { backgroundColor: "#87CEFA" }]}>
              <View style={[styles.cell1, { backgroundColor: "#87CEFA" }]}>
                <Text
                  style={[
                    styles.textbold,
                    {
                      fontSize: 12,
                      fontWeight: "bold",
                      textDecoration: "underline",
                    },
                  ]}
                >
                  EXPERIENCE{" "}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table2}>
            <View style={styles.row3}>
              <View style={styles.cell1}>
                <Text style={styles.textbold}>PERIOD </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.textbold}>POSITION </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.textbold}>COUNTRY </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.textbold}>DETIALS </Text>
              </View>
            </View>
          </View>

          <View style={styles.table2}>
            <View style={styles.row3}>
              <View style={[styles.cell1, { backgroundColor: "#F6F9F9" }]}>
                <Text style={styles.secondRow}>Not Provided </Text>
              </View>
              <View style={[styles.cell1, { backgroundColor: "#F6F9F9" }]}>
                <Text style={styles.secondRow}>
                  {records && records.designation !== undefined
                    ? records.designation
                    : ""}
                </Text>
              </View>
              <View style={[styles.cell1, { backgroundColor: "#F6F9F9" }]}>
                <Text style={styles.secondRow}>Not Provided </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>Not Provided </Text>
              </View>
            </View>
          </View>
          {/* <View style={styles.table2}>
            <View style={styles.row3}>
              <View style={[styles.cell1, { backgroundColor: "#F6F9F9" }]}>
                <Text style={styles.secondRow}>03 YEARS </Text>
              </View>
              <View style={[styles.cell1, { backgroundColor: "#F6F9F9" }]}>
                <Text style={styles.secondRow}>DRIVER </Text>
              </View>
              <View style={[styles.cell1, { backgroundColor: "#F6F9F9" }]}>
                <Text style={styles.secondRow}>KSA </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>
                  FAMILY DRIVER EXPERANCE IN SAUDI ARABIA.{" "}
                </Text>
              </View>
            </View>
          </View> */}

          <View style={styles.table2}>
            <View style={[styles.row3, { backgroundColor: "#87CEFA" }]}>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    {
                      fontSize: 12,
                      fontWeight: "bold",
                      textDecoration: "underline",
                    },
                  ]}
                >
                  LANGUAGES{" "}
                </Text>
              </View>
              <View style={styles.cell1}>
                <Text
                  style={[
                    styles.secondRow,
                    {
                      fontSize: 12,
                      fontWeight: "bold",
                      textDecoration: "underline",
                    },
                  ]}
                >
                  SKILLS{" "}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table2}>
            <View style={styles.row3}>
              <View style={styles.cell1}>
                <Text style={styles.textbold}>N/A </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>N/A </Text>
              </View>
              <View style={styles.cell1}>
                <Text style={styles.secondRow}>N/A </Text>
              </View>
            </View>
          </View>
        </View>

        {/* <View style={[styles.cvPage, { height: "100%" }]}>
          <View style={{ flexDirection: "row", height: "20%" }}>
            <View style={styles.cv}>
              <Text style={styles.cvtext}>Curriculum Vitae</Text>
            </View>
          </View>

          <View style={[styles.cv, { margin: "2px" }]}>
            <Text style={[styles.personalinfo, { color: "#0039a6" }]}>
              Personal Information{" "}
            </Text>
          </View>

          <View
            style={[
              styles.img,
              {
                flexDirection: "row",
                height: "20%",
                marginLeft: "80%",
                marginTop: "2px",
              },
            ]}
          >
            <Image
              style={styles.image}
              src={
                records.profile_image
                  ? process.env.REACT_APP_BASE_URL +
                    "/profile_images/" +
                    records.profile_image
                  : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
              }
              cache={false}
            />
          </View>
          <View style={[styles.cv, { flexDirection: "row" }]}>
            <View
              style={[
                styles.cv,
                { marginTop: "2px", margin: "2px", width: "50%" },
              ]}
            >
              <Text style={[styles.text, { fontSize: 10 }]}>
                Name:{" "}
                {records && records.first_name !== undefined
                  ? records.first_name + " " + records.last_name
                  : ""}{" "}
              </Text>
              <Text style={[styles.text, { fontSize: 10 }]}>
                {" "}
                DoB: {records.dob}
              </Text>
              <Text style={[styles.text, { fontSize: 10 }]}>
                Marital status:
                {records && records.marital_status_str !== undefined
                  ? records.marital_status_str
                  : ""}
              </Text>
              <Text style={[styles.text, { fontSize: 10 }]}>
                Occupation:{" "}
                {records && records.designation !== undefined
                  ? records.designation
                  : ""}
              </Text>
              <Text style={[styles.text, { fontSize: 10 }]}>Skills: N/A</Text>
              <Text style={[styles.text, { fontSize: 10 }]}>
                Years of experience in country: N/A
              </Text>
            </View>

            <View
              style={[
                styles.cv,
                {
                  marginTop: "2px",
                  margin: "2px",
                  fontSize: 5,
                  width: "45%",
                  display: "flex",
                  lineheight: 1.5,
                },
              ]}
            >
              <Text style={[styles.text, { fontSize: 10 }]}>
                {" "}
                ID number:{" "}
                {records && records.national_id !== undefined
                  ? records.national_id
                  : ""}
              </Text>
              <Text style={[styles.text, { fontSize: 10 }]}> Gender: Male</Text>
              <Text style={[styles.text, { fontSize: 10 }]}>
                Religion:{" "}
                {records && records.religion_name !== undefined
                  ? records.religion_name
                  : ""}
              </Text>
              <Text style={[styles.text, { fontSize: 10 }]}>
                {" "}
                Mobile:{" "}
                {records && records.phone !== undefined ? records.phone : ""}
              </Text>
              <Text style={[styles.text, { fontSize: 10 }]}>
                Qualification:{" "}
                {records && records.edu_level !== undefined
                  ? records.edu_level
                  : ""}
              </Text>
              <Text style={[styles.text, { fontSize: 10 }]}>
                Years of experience abroad: N/A
              </Text>
            </View>
          </View>

          <View style={styles.pasinfo}>
            <Text
              style={[
                styles.passportinfo,
                { fontSize: 15, float: "left", color: "#0039a6" },
              ]}
            >
              {" "}
              Passport Information
            </Text>
            <View style={[styles.img, { float: "right", marginLeft: "80%" }]}>
              <Image
                style={styles.image}
                src={
                  records.passport_photo
                    ? process.env.REACT_APP_BASE_URL +
                      "/profile_images/" +
                      records.passport_photo
                    : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                }
                cache={false}
              />
            </View>
            <View style={[styles.cv, { flexDirection: "row" }]}>
              <View
                style={[
                  styles.cv,
                  {
                    marginTop: "2px",
                    margin: "2px",
                    fontSize: 5,
                    width: "50%",
                  },
                ]}
              >
                <Text style={[styles.text, { fontSize: 10 }]}>
                  Passport No:{" "}
                  {records && records.passport_number !== undefined
                    ? records.passport_number
                    : ""}
                </Text>
                <Text style={[styles.text, { fontSize: 10 }]}>
                  {" "}
                  Issue date: N/A
                </Text>
              </View>
              <View
                style={[
                  styles.cv,
                  {
                    marginTop: "2px",
                    margin: "2px",
                    fontSize: 5,
                    width: "50%",
                  },
                ]}
              >
                <Text style={[styles.text, { fontSize: 10 }]}>
                  {" "}
                  Expiration date: N/A
                </Text>
                <Text style={[styles.text, { fontSize: 10 }]}>
                  {" "}
                  Issue place: N/A
                </Text>
              </View>
            </View>
            <Text
              style={[
                styles.passportinfo,
                { fontSize: 15, float: "left", color: "#0039a6" },
              ]}
            >
              {" "}
              Address Information
            </Text>
            <View
              style={[
                styles.cv,
                { marginTop: "2px", margin: "2px", fontSize: 5, width: "50%" },
              ]}
            >
              <Text style={[styles.text, { fontSize: 10 }]}>
                {" "}
                Country:{" "}
                {records && records.country_name !== undefined
                  ? records.country_name
                  : ""}
              </Text>
              <Text style={[styles.text, { fontSize: 10 }]}> City: N/A</Text>
              <Text style={[styles.text, { fontSize: 10 }]}>
                {" "}
                Address:{" "}
                {records && records.present_address !== undefined
                  ? records.present_address
                  : ""}
              </Text>
            </View>

            <Text
              style={[
                styles.passportinfo,
                { fontSize: 15, float: "left", color: "#0039a6" },
              ]}
            >
              {" "}
              Emergency Contact
            </Text>

            <View style={[styles.cv, { flexDirection: "row" }]}>
              <View
                style={[
                  styles.cv,
                  {
                    marginTop: "2px",
                    margin: "2px",
                    fontSize: 5,
                    width: "50%",
                  },
                ]}
              >
                <Text style={[styles.text, { fontSize: 10 }]}>Name: N/A</Text>
                <Text style={[styles.text, { fontSize: 10 }]}>
                  Mobile No: N/A
                </Text>
              </View>
              <View
                style={[
                  styles.cv,
                  {
                    marginTop: "2px",
                    margin: "2px",
                    fontSize: 5,
                    width: "50%",
                  },
                ]}
              >
                <Text style={[styles.text, { fontSize: 10 }]}>
                  {" "}
                  Kinship: N/A
                </Text>
                <Text style={[styles.text, { fontSize: 10 }]}>
                  Address: N/A
                </Text>
              </View>
            </View>
          </View>
        </View> */}

        <View style={styles.container}>
          <View
            style={[
              styles.video,
              {
                flexDirection: "row",
                display: "flex",
                marginTop: "10%",
                paddingTop: 30,
                paddingBottom: 30,
              },
            ]}
          >
            <Text
              style={[
                styles.secondRow,
                {
                  fontSize: 15,
                  // float: "left",
                  textAlign: "center",
                  paddingRight: 10,
                },
              ]}
            >
              Worker Video Link:{" "}
            </Text>
            <Text
              style={[
                styles.secondRow,
                {
                  display: "flex",
                  fontSize: 15,
                  color: "blue",
                  textDecoration: "underline",
                },
              ]}
            >
              https://youtube.com/shorts/Sa8_FaFBuBE?feature=share{" "}
            </Text>
          </View>
        </View>

        <View style={[styles.container, {}]}>
          <View style={styles.cardrow}>
            <Image
              style={[
                styles.imageLicence,
                { paddingTop: 10, paddingBottom: 10 },
              ]}
              src={
                records.profile_image
                  ? process.env.REACT_APP_BASE_URL +
                    "/profile_images/" +
                    records.profile_image
                  : process.env.REACT_APP_BASE_URL +
                      "/profile_images/dummy_profile.jpg"
              }
              cache={false}
            />
          </View>
        </View>
        <View style={[styles.container, {}]}>
          <View style={styles.cardrow}>
            <Image
              style={[
                styles.imageLicence,
                { paddingTop: 10, paddingBottom: 10 },
              ]}
              src={
                records.id_photo
                  ? process.env.REACT_APP_BASE_URL +
                    "/profile_images/" +
                    records.id_photo
                  : process.env.REACT_APP_BASE_URL +
                      "/profile_images/dummy_profile.jpg"
              }
              cache={false}
            />
          </View>
          <View style={styles.cardrow}>
            <Image
              style={[
                styles.imageLicence,
                { paddingTop: 10, paddingBottom: 10 },
              ]}
              src={
                records.passport_photo
                  ? process.env.REACT_APP_BASE_URL +
                    "/profile_images/" +
                    records.passport_photo
                  : process.env.REACT_APP_BASE_URL +
                      "/profile_images/dummy_profile.jpg"
              }
              cache={false}
            />
          </View>
        </View>
        {/* <View style={[styles.container, {}]}>
          <View style={styles.cardrow}>
            <Image
              style={[
                styles.imageLicence,
                { paddingTop: 10, paddingBottom: 10 },
              ]}
              src={
                records.id_photo
                  ? process.env.REACT_APP_BASE_URL +
                    "/profile_images/" +
                    records.id_photo
                  : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
              }
              cache={false}
            />
          </View>
        </View> */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default Pdffile;
