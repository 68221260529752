import React, { useState } from "react";
import "react-phone-number-input/style.css";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { Alert, Space, Menu } from "antd";
import Select from "react-select";
import CustomAlert from "../../components/Alerts/CustomAlert";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navbar/Navbar";
import { useEffect } from "react";
import Footer from "../../components/Footer";
import { Routes, Route, useParams } from "react-router-dom";
//require('dotenv').config();
const Authenticate = () => {
  const { typeParams } = useParams();
  const { Option } = Select;
  //console.log("TYPE PARAMS " + typeParams);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [typeSelected, setTypeSelected] = useState(
    typeParams !== undefined && typeParams !== "" ? typeParams : 1
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyType, setCompanyType] = useState("");

  const [loading, setLoading] = useState(false);
  const [resultCode, setResultCode] = useState();

  const [hidePassword, setHidePassword] = useState(true);

  const [fieldsError, setFieldsError] = useState(false);
  const [industryList, setIndustryList] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(1);

  const [companyErrorMessage, setCompanyErrorMessage] = useState("");
  const [companyError, setCompanyError] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [rank, setRank] = useState("owner");

  useEffect(() => {
    axios
      .get(`https://backend.ogsmanpower.com/static/getrows/category`)
      .then((response) => {
        setIndustryList(response.data.result);
      });
    setPhone("+92");
  }, []);

  const isValidEmail = (value) => {
    console.log(value);
    // Basic email validation using regular expression
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    console.log(emailPattern.test(value));
    return emailPattern.test(value);
  };

  return (
    <>
      <Navigation />
      <div
        className="register-area pt-120 mb-120"
        style={{ backgroundColor: "#F8F8F8" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-wrapper">
                <div className="form-title">
                  <h3>Register Account</h3>
                  <span />
                </div>
                <div className="register-tab">
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        className={`nav-link ${
                          typeSelected == 1 ? "active" : ""
                        }`}
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                        onClick={() => setTypeSelected(1)}
                      >
                        Jobseeker
                      </button>
                      <button
                        className={`nav-link ${
                          typeSelected == 2 ? "active" : ""
                        }`}
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                        onClick={() => setTypeSelected(2)}
                      >
                        Company
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className={`${
                        typeSelected == 1 ? "active" : ""
                      } tab-pane fade show`}
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <form>
                        <div className="row">
                          {resultCode === "" || resultCode == null ? (
                            ""
                          ) : resultCode == 1 ? (
                            <CustomAlert
                              type="success"
                              message="Login Successful"
                            />
                          ) : (
                            <CustomAlert
                              type="error"
                              message="Email Already Exists"
                            />
                          )}
                          <br />
                          <br />
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="firstname1">First Name*</label>
                              <div className="input-area">
                                <img
                                  src="assets/images/icon/user-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="firstname1"
                                  name="firstname1"
                                  placeholder="Mr. Robert"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="lastname1">Last Name*</label>
                              <div className="input-area">
                                <img
                                  src="assets/images/icon/user-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="lastname1"
                                  name="lastname1"
                                  placeholder="Jonson"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-inner mb-25">
                              <label htmlFor="email">Email*</label>
                              <div className="input-area">
                                <img
                                  src="assets/images/icon/email-2.svg"
                                  alt=""
                                />
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="info@example.com"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-inner mb-25">
                              <label htmlFor="email">Phone Number*</label>
                              <div className="input-area">
                                <PhoneInput
                                  placeholder="Enter phone number"
                                  value={phone}
                                  onChange={setPhone}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="password">Password*</label>
                              <input
                                type={hidePassword ? "password" : "text"}
                                name="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <i
                                className="bi bi-eye-slash"
                                id="togglePassword"
                                onClick={() => setHidePassword(!hidePassword)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner">
                              <label htmlFor="password2">
                                Confirm Password*
                              </label>
                              <input
                                type={hidePassword ? "password" : "text"}
                                name="confirmpassword"
                                id="password2"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                              <i
                                className="bi bi-eye-slash"
                                id="togglePassword2"
                                onClick={() => setHidePassword(!hidePassword)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                              <div className="form-group two">
                                <input type="checkbox" id="html1" />
                                <label htmlFor="html1">
                                  Here, I will agree company terms &amp;
                                  conditions.
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-inner">
                              {fieldsError ? (
                                <CustomAlert
                                  type="error"
                                  message="All fields are required"
                                />
                              ) : (
                                ""
                              )}
                              <button
                                className="primry-btn-2"
                                type="button"
                                // onClick={() => candidateSignup()}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    firstName == "" ||
                                    lastName == "" ||
                                    phone == "" ||
                                    email == "" ||
                                    password == "" ||
                                    confirmPassword == ""
                                  ) {
                                    setFieldsError(true);
                                    setTimeout(() => {
                                      setFieldsError(false);
                                    }, 2000);
                                  } else if (!isValidEmail(email)) {
                                    alert("Invalid Email Format");
                                  } else if (confirmPassword != password) {
                                    alert("Password must match");
                                  } else {
                                    axios
                                      .post(
                                        process.env.REACT_APP_BASE_URL +
                                          "/signup",
                                        // "https://backend.ogsmanpower.com/signup",
                                        {
                                          firstName: firstName,
                                          lastName: lastName,
                                          email: email,
                                          password: password,
                                          email: email,
                                          phone: phone,
                                          type: typeSelected,
                                        }
                                      )
                                      .then((res) => {
                                        setResultCode(res.data.code);
                                        if (res.data.code == 1) {
                                          localStorage.setItem(
                                            "accessToken",
                                            res.data.token
                                          );
                                          window.setTimeout(() => {
                                            navigate(
                                              `/seekeronboard/${firstName}/${lastName}/${email}/${phone}`
                                            );
                                          }, 3000);
                                        }
                                        setLoading(false);
                                      });
                                  }
                                }}
                              >
                                Sign Up
                              </button>
                            </div>
                          </div>
                          <h6>
                            Already have an account?{" "}
                            <a href="/seekersignin"> Login</a> Here
                          </h6>
                        </div>
                      </form>
                    </div>
                    <div
                      className={`${
                        typeSelected == 2 ? "active" : ""
                      } tab-pane fade show`}
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <form>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="firstname">First Name*</label>
                              <div className="input-area">
                                <img
                                  src="assets/images/icon/user-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="firstname"
                                  name="firstname"
                                  placeholder="Mr. Robert"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="lastname">Last Name*</label>
                              <div className="input-area">
                                <img
                                  src="assets/images/icon/user-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="lastname"
                                  name="lastname"
                                  placeholder="Jonson"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="email">Phone Number*</label>
                              <div className="input-area">
                                <PhoneInput
                                  placeholder="Enter phone number"
                                  value={phone}
                                  onChange={setPhone}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="email">Email*</label>
                              <div className="input-area">
                                <img
                                  src="assets/images/icon/email-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="email1"
                                  name="email"
                                  placeholder="info@example.com"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="companyname">Company Name*</label>
                              <div className="input-area">
                                <img
                                  src="assets/images/icon/company-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="companyname"
                                  name="companyname"
                                  placeholder="Mr. Robert"
                                  value={companyName}
                                  onChange={(e) =>
                                    setCompanyName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label>Company Type*</label>
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                isClearable={isClearable}
                                isRtl={isRtl}
                                isSearchable={isSearchable}
                                name="color"
                                options={industryList.map((industry) => ({
                                  value: industry.id,
                                  label: industry.name,
                                }))}
                                style={{ width: "100%" }}
                                onChange={(e) => setSelectedIndustry(e.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="password">Password*</label>
                              <input
                                type={hidePassword ? "password" : "text"}
                                name="password"
                                id="password3"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <i
                                className="bi bi-eye-slash"
                                id="togglePassword3"
                                onClick={() => setHidePassword(!hidePassword)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner">
                              <label htmlFor="password4">
                                Confirm Password*
                              </label>
                              <input
                                type={hidePassword ? "password" : "text"}
                                name="confirmpassword"
                                id="password4"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                              <i
                                className="bi bi-eye-slash"
                                id="togglePassword4"
                                onClick={() => setHidePassword(!hidePassword)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-inner mb-25">
                              <label htmlFor="lastname1">
                                Select Position*
                              </label>
                              <select
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "1px solid lightgrey",
                                }}
                                onChange={(e) => setRank(e.target.value)}
                              >
                                <option value="owner">Owner</option>
                                <option value="hr">HR</option>
                                <option value="hiring_manager">
                                  Hiring Manager
                                </option>
                                <option value="department_head">
                                  Department Head
                                </option>
                                <option value="recruitment_team">
                                  Recruitment Team
                                </option>
                                <option value="technical_expert">
                                  Technical Expert
                                </option>
                                <option value="legal_compliance">
                                  Legal and Compliance Team
                                </option>
                                <option value="diversity_inclusion">
                                  Diversity and Inclusion Team
                                </option>
                                <option value="employee_referral">
                                  Employee Referral Program
                                </option>
                                <option value="executive_leadership">
                                  Executive Leadership
                                </option>
                                <option value="contractor">
                                  Contractor/External Recruiter
                                </option>
                                <option value="onboarding_team">
                                  Onboarding Team
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                              <div className="form-group two">
                                <input type="checkbox" id="html" />
                                <label htmlFor="html">
                                  Here, I will agree company terms &amp;
                                  conditions.
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-inner">
                              {fieldsError ? (
                                <CustomAlert
                                  type="error"
                                  message="All fields are required"
                                />
                              ) : (
                                ""
                              )}
                              {companyError ? (
                                <CustomAlert
                                  type="error"
                                  message={companyErrorMessage}
                                />
                              ) : (
                                ""
                              )}
                              <br />
                              <br />
                              <button
                                className="primry-btn-2"
                                type="submit"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    firstName == "" ||
                                    lastName == "" ||
                                    phone == "" ||
                                    email == "" ||
                                    companyName == "" ||
                                    password == "" ||
                                    confirmPassword == ""
                                  ) {
                                    setFieldsError(true);
                                    setTimeout(() => {
                                      setFieldsError(false);
                                    }, 2000);
                                  } else if (!isValidEmail(email)) {
                                    alert("Invalid Email Format");
                                  } else if (confirmPassword != password) {
                                    alert("Password must match");
                                  } else {
                                    axios
                                      .post(
                                        process.env.REACT_APP_BASE_URL +
                                          "/onboard/company",
                                        {
                                          firstName: firstName,
                                          lastName: lastName,
                                          phone: phone,
                                          email: email,
                                          companyName: companyName,
                                          password: password,
                                          companyType: selectedIndustry,
                                          rank: rank,
                                        }
                                      )
                                      .then((response) => {
                                        if (response.data.code == 99) {
                                          setCompanyErrorMessage(
                                            response.data.message
                                          );
                                          setCompanyError(true);
                                          setTimeout(() => {
                                            setCompanyError(false);
                                          }, 5000);
                                        } else if (response.data.code == 1) {
                                          localStorage.setItem(
                                            "accessToken",
                                            response.data.token
                                          );
                                          navigate("/company_portal");
                                        }
                                      });
                                  }
                                }}
                              >
                                Sign Up
                              </button>
                            </div>
                          </div>
                          <h6>
                            Already have an account?{" "}
                            <a href="/companysignin"> Login</a> Here
                          </h6>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Authenticate;
