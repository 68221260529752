import React, { useEffect, useState } from "react";
import Navigation from "../../components/Navbar/Navbar";
import axios from "axios";
import Footer from "../../components/Footer";
import { Button, Modal, Input, Space, Select } from "antd";
import jwtDecode from "jwt-decode";
import CustomAlert from "../../components/Alerts/CustomAlert";
const JobListing = () => {
  const [jobList, setJobList] = useState([]);

  const [catList, setCatList] = useState([]);
  const [selectedSalary, setSelectedSalary] = useState("none");
  const [vacanciesSelected, setVacanciesSelected] = useState(0);

  const [selectedCat, setSelectedCat] = useState(0);

  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState("-1");

  const [experienceSelected, setExperienceSelected] = useState("-1");
  const [genderSelected, setGenderSelected] = useState("na");

  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);
  const [companyErrorMessage, setCompanyErrorMessage] = useState("");
  const [companyError, setCompanyError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/countries")
      .then((response) => {
        setCountryList(response.data.result);
      });
  }, []);

  //Category Axios
  useEffect(() => {
    axios
      // .get("np")
      .get(process.env.REACT_APP_BASE_URL + "/static/getrows/category")
      .then((response) => {
        setCatList(response.data.result);
      });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `/static/jobseekers/${selectedSalary}/${selectedCat}/${countrySelected}/${experienceSelected}`
      )
      .then((response) => {
        setJobList(response.data.result);
        setIsLoading(false);
        checkRecords(response.data.result); // Call checkRecords here
      })
      .catch((error) => console.error("Error fetching job seekers:", error));
    setIsLoading(false);
  }, [
    selectedCat,
    selectedSalary,
    vacanciesSelected,
    countrySelected,
    experienceSelected,
    genderSelected,
  ]);

  // function for clearing filters
  const clearFilters = () => {
    setSelectedSalary("none");
    setSelectedCat(0);
    setCountrySelected("-1");
    setExperienceSelected("-1");
  };
  const getFilteredDate = (e) => {
    let date = new Date(e);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  const [disabledButtons, setDisabledButtons] = useState([]);
  // useEffect(() => {
  //   // Check if record should be disabled when component mounts
  //   checkRecordForAllButtons();
  // }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleGender = (value) => {
    console.log(`selected ${value}`);
  };
  function logCompanyHiringRequest(company_id, seeker_id) {
    axios
      .post(process.env.REACT_APP_BASE_URL + "/static/add_hiring_request", {
        company: company_id,
        seeker: seeker_id,
      })
      .then((response) => {
        if (response.data.code === 0) {
          alert("Error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const checkRecords = (jobs) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      const promises = jobs.map((job) =>
        checkRecord(decodedToken.userId, job.basic_info_id)
      );

      Promise.all(promises)
        .then((results) => {
          setDisabledButtons(results);
        })
        .catch((error) => {
          console.error("Error checking records for all buttons:", error);
        });
    }
  };

  const checkRecord = (company_id, seeker_id) => {
    return axios
      .post(process.env.REACT_APP_BASE_URL + "/static/check_hiring_record", {
        company: company_id,
        seeker: seeker_id,
      })
      .then((response) => {
        if (response.data.code === 1) {
          console.log(company_id, seeker_id, "match");
          return true;
        } else {
          console.log(company_id, seeker_id, "Not match");
          return false;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        return false;
      });
  };

  function sendMail(company_id, seeker_id) {
    axios
      .post(process.env.REACT_APP_BASE_URL + "/email_seeker_request", {
        company: company_id,
        seeker: seeker_id,
      })
      .then((response) => {
        if (response.data.code === 0) {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  return (
    <>
      <Navigation />
      <div className="inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-content text-center">
                <h1>Jobseeker Listing</h1>
                <span />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Jobseeker Listing{" "}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="job-listing-area pt-20 mb-20">
        <div className="container">
          {/* <div className="row">
        <button onClick={clearFilters}>Clear Filters</button>
        </div> */}
          <div className="d-flex justify-content-end my-3">
            <button className="btn btn-secondary" onClick={clearFilters}>
              Clear Filters
            </button>
          </div>
          <div className="col-lg-12 order-lg-1 order-2">
            <div className="job-sidebar">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="job-widget">
                    <div className="check-box-item">
                      <h5 className="job-widget-title">Category</h5>
                      <div className="checkbox-container">
                        <select
                          className="form-select"
                          data-placeholder="Select an option"
                          onChange={(e) => setSelectedCat(e.target.value)}
                        >
                          {catList.map((c) => (
                            <option key={c.id} value={c.id}>
                              {c.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="job-widget">
                    <div className="check-box-item">
                      <h5 className="job-widget-title">Expected Salary</h5>
                      <div className="checkbox-container">
                        <select
                          className="form-select"
                          onChange={(e) => setSelectedSalary(e.target.value)}
                        >
                          <option value="none" selected>
                            Select Salary
                          </option>
                          <option value="10,000 - 20,000">
                            10,000 - 20,000
                          </option>
                          <option value="20,000 - 30,000">
                            20,000 - 30,000
                          </option>
                          <option value="30,000 - 40,000">
                            30,000 - 40,000
                          </option>
                          <option value="40,000 - 50,000">
                            40,000 - 50,000
                          </option>
                          <option value="50,000 - 60,000">
                            50,000 - 60,000
                          </option>
                          <option value="60,000 - 70,000">
                            60,000 - 70,000
                          </option>
                          <option value="70,000 - 80,000">
                            70,000 - 80,000
                          </option>
                          <option value="80,000 - 90,000">
                            80,000 - 90,000
                          </option>
                          <option value="90,000 - 100,000">
                            90,000 - 100,000
                          </option>
                          <option value="100,000+">100,000+</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="job-widget">
                    <div className="check-box-item">
                      <h5 className="job-widget-title">Country</h5>
                      <div className="checkbox-container">
                        <select
                          className="form-select"
                          onChange={(e) => setCountrySelected(e.target.value)}
                        >
                          <option value="-1" selected>
                            Select Country
                          </option>
                          {countryList.map((q) => (
                            <option key={q.id} value={q.id}>
                              {q.country_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="job-widget">
                    <div className="check-box-item">
                      <h5 className="job-widget-title">Experience</h5>
                      <div className="checkbox-container">
                        <select
                          className="form-select"
                          onChange={(e) =>
                            setExperienceSelected(e.target.value)
                          }
                        >
                          <option value="-1" selected>
                            Select Experience
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10+</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-lg-4 gy-5">
            {/* <div className="col-lg-4 order-lg-1 order-2">
              <div className="job-sidebar">
                <div className="job-widget style-1 mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Category</h5>
                    <div className="checkbox-container">
                      <select
                        className="js-example-basic-multiple"
                        data-placeholder="Select an option"
                        style={{
                          width: "100%",
                          padding: "10px",
                          border: "1px solid lightgrey",
                        }}
                        onChange={(e) => setSelectedCat(e.target.value)}
                      >
                        {catList.map((c) => (
                          <option value={c.id}>{c.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="job-widget mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Expected Salary</h5>
                    <div className="checkbox-container">
                      <div className="input-area">
                        <select
                          className="select1"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "1px solid lightgrey",
                          }}
                          onChange={(e) => setSelectedSalary(e.target.value)}
                        >
                          <option value="none" selected>
                            Select Salary
                          </option>
                          <option value="10,000 - 20,000">
                            10,000 - 20,000
                          </option>
                          <option value="20,000 - 30,000">
                            20,000 - 30,000
                          </option>
                          <option value="30,000 - 40,000">
                            30,000 - 40,000
                          </option>
                          <option value="40,000 - 50,000">
                            40,000 - 50,000
                          </option>
                          <option value="50,000 - 60,000">
                            50,000 - 60,000
                          </option>
                          <option value="60,000 - 70,000">
                            60,000 - 70,000
                          </option>
                          <option value="70,000 - 80,000">
                            70,000 - 80,000
                          </option>
                          <option value="80,000 - 90,000">
                            80,000 - 90,000
                          </option>
                          <option value="90,000 - 100,000">
                            90,000 - 100,000
                          </option>
                          <option value="100,000+">100,000+</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-widget mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Country</h5>
                    <div className="checkbox-container">
                      <div className="input-area">
                        <select
                          className="select1"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "1px solid lightgrey",
                          }}
                          onChange={(e) => setCountrySelected(e.target.value)}
                        >
                          <option value={"-1"} selected>
                            Select Country
                          </option>
                          {countryList.map((q) => (
                            <option key={q.id} value={q.id}>
                              {q.country_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
               
                <div className="job-widget mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Experience</h5>
                    <div className="checkbox-container">
                      <div className="input-area">
                        <select
                          className="select1"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "1px solid lightgrey",
                          }}
                          onChange={(e) =>
                            setExperienceSelected(e.target.value)
                          }
                        >
                          <option value="-1" selected>
                            Select Experience
                          </option>
                          <option value="1"> 1 </option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10+</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-12 order-lg-2 order-1">
              <div className="job-listing-wrrap">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                  }}
                >
                  <Modal
                    title=""
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <div>
                      <h4>Thanks for your interest.</h4>
                    </div>
                    <div>
                      <p>You will be contacted soon by OGS Manpower</p>
                    </div>
                  </Modal>
                </div>
                <div className="row ">
                  {jobList.length === 0 ? (
                    isLoading ? (
                      <div className="col-lg-12 mb-30 text-center">
                        <p>Loading...</p>
                      </div>
                    ) : (
                      <div
                        className="col-lg-12 mb-30"
                        style={{ textAlign: "center" }}
                      >
                        <p style={{ color: "#00a4e5" }}>
                          <b>No records found.</b>
                        </p>
                      </div>
                    )
                  ) : (
                    jobList.map((j, index) => (
                      <div className="col-lg-12 mb-30" key={j.basic_info_id}>
                        <div className="job-listing-card">
                          <div className="job-top">
                            <div className="job-list-content">
                              <div className="company-area">
                                <div className="company-details">
                                  <div className="name-location">
                                    <h5>
                                      <a
                                        href={`/seeker_profile/${j.basic_info_id}`}
                                      >
                                        {j.first_name + " " + j.last_name}
                                      </a>
                                    </h5>
                                    <p>{j.designation}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="job-discription">
                                <ul>
                                  <li>
                                    <p>
                                      <span className="title">
                                        Expected Salary:
                                      </span>{" "}
                                      {j.expected_salary}
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <span className="title">Experience:</span>{" "}
                                      {j.years_experience}
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/* <div className="bookmark">
                            <i className="bi bi-bookmark-fill" />
                          </div> */}
                          </div>
                          <div className="job-type-apply">
                            <div className="job-type">
                              <span className="light-blue">
                                {j.country_name}
                              </span>
                            </div>
                            {/* <div className="apply-btn">
                            <a href={`/job_details/${j.basic_info_id}`}>
                              <span>
                                <img
                                  src="assets/images/icon/apply-ellipse.svg"
                                  alt=""
                                />
                              </span>
                              Hire Now
                            </a>
                          </div> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "10px",
                              }}
                            >
                              <Button
                                type="button"
                                className="btn btn-info lh-1"
                                //onClick={showModal}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  if (localStorage.getItem("accessToken")) {
                                    console.log("ok");
                                    const decodedToken = jwtDecode(
                                      localStorage.getItem("accessToken")
                                    );
                                    if (decodedToken.userRole == 2) {
                                      {
                                        setDisabledButtons(
                                          (prevDisabledButtons) => {
                                            const updatedDisabledButtons = [
                                              ...prevDisabledButtons,
                                            ];
                                            updatedDisabledButtons[
                                              index
                                            ] = true;
                                            return updatedDisabledButtons;
                                          }
                                        );
                                        showModal();

                                        await logCompanyHiringRequest(
                                          decodedToken.userId,
                                          j.basic_info_id
                                        );
                                        await sendMail(
                                          decodedToken.userId,
                                          j.basic_info_id
                                        );
                                      }
                                    } else {
                                      alert("Login as company to Hire");
                                    }
                                  } else {
                                    alert("Login as company to Hire");
                                  }
                                }}
                                disabled={disabledButtons[index]}
                              >
                                {disabledButtons[index]
                                  ? "Request Sent"
                                  : "Hire Now"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default JobListing;
