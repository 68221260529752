import React, { useRef, useState } from "react";
import DashboardNavbar from "../../../components/Navbar/DashboardNavbar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect } from "react";
import axios from "axios";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  BlobProvider,
} from "@react-pdf/renderer";
import AdminMenu from "./AdminMenu";

const AllJobs = () => {
  const [noSeekers, setNoSeekers] = useState(0);
  const [noCompanies, setNoCompanies] = useState(0);
  const [noJobs, setNoJobs] = useState(0);
  const [cvCreated, setCvCreated] = useState(0);

  const [jobs, setJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState("none");
  const [genderPref, setGenderPref] = useState("none");
  const [salarySelected, setSalarySelected] = useState("none");
  const [industryList, setIndustryList] = useState([]);
  const [industrySelected, setIndustrySelected] = useState(0);

  const [searchTitle, setSearchTitle] = useState("");

  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/seeker_login")
      .then((response) => setNoSeekers(response.data.result.length));
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/company_login")
      .then((response) => setNoCompanies(response.data.result.length));
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/jobs")
      .then((response) => setNoJobs(response.data.result.length));
    axios
      .get(
        "https://backend.ogsmanpower.com/static/getrows/resume_basic_information"
      )
      .then((response) => setCvCreated(response.data.result.length));
    axios
      .get(
        `https://backend.ogsmanpower.com/static/filterJobs/${countrySelected}/${industrySelected}/${genderPref}/${salarySelected}`
      )
      .then((response) => {
        console.log(response);
        setJobs(response.data.result);
        setJobsLoaded(true);
      });
  }, [countrySelected, industrySelected, genderPref, salarySelected]);

  const approveJob = async (id) => {
    axios
      .get("https://backend.ogsmanpower.com/static/approveJob/" + id)
      .then((response) => {
        if (response.data.code == 1) {
          alert("Job Approved");
        }
      });
  };

  useEffect(() => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries")
      .then((response) => {
        setCountryList(response.data.data);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`https://backend.ogsmanpower.com/static/getrows/category`)
      .then((response) => {
        setIndustryList(response.data.result);
      });
  }, []);
  return (
    <>
      <DashboardNavbar />
      <div
        className="dashboard-area company-dashboard pt-120 mb-120"
        style={{ background: "#f8f8f8" }}
      >
        <div className="container" id="ju">
          <div className="row g-lg-4">
            <div className="col-lg-12 mb-20">
              <div className="dashboard-sidebar">
                <AdminMenu />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="dashboard-inner">
                <div className="new-applied-job-area">
                  <h5>All Jobs:</h5>
                  <div>
                    <div className="row mb-2">
                      <div className="col-md-4">
                        <input
                          style={{
                            width: "100%",
                            padding: "10px 20px",
                            border: "1px solid lightgrey",
                          }}
                          placeholder="Search by title"
                          onChange={(e) => setSearchTitle(e.target.value)}
                        />
                      </div>
                      <div className="col-md-2">
                        <select
                          style={{
                            width: "100%",
                            height: "45px",
                            border: "1px solid lightgrey",
                            padding: "0px 10px",
                          }}
                          onChange={(e) => setCountrySelected(e.target.value)}
                        >
                          <option selected value={"none"}>
                            Filter By Country
                          </option>
                          {countryList.map((q) => (
                            <option key={q.iso2} value={q.country}>
                              {q.country}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-2">
                        <select
                          style={{
                            width: "100%",
                            height: "45px",
                            border: "1px solid lightgrey",
                            padding: "0px 10px",
                          }}
                          onChange={(e) => setGenderPref(e.target.value)}
                        >
                          <option selected value={"none"}>
                            Gender Preference
                          </option>
                          <option value={"male"}>Male</option>
                          <option value={"female"}>Female</option>
                        </select>
                      </div>
                      <div className="col-md-2">
                        <select
                          style={{
                            width: "100%",
                            height: "45px",
                            border: "1px solid lightgrey",
                            padding: "0px 10px",
                          }}
                          onChange={(e) => setSalarySelected(e.target.value)}
                        >
                          <option selected value={"none"}>
                            Filter By Salary
                          </option>
                          <option value="10,000 - 20,000">
                            {" "}
                            10,000 - 20,000{" "}
                          </option>
                          <option value="20,000 - 30,000">
                            20,000 - 30,000
                          </option>
                          <option value="30,000 - 40,000">
                            30,000 - 40,000
                          </option>
                          <option value="40,000 - 50,000">
                            40,000 - 50,000
                          </option>
                          <option value="50,000 - 60,000">
                            50,000 - 60,000
                          </option>
                          <option value="60,000 - 70,000">
                            60,000 - 70,000
                          </option>
                          <option value="70,000 - 80,000">
                            70,000 - 80,000
                          </option>
                          <option value="80,000 - 90,000">
                            80,000 - 90,000
                          </option>
                          <option value="90,000 - 100,000">
                            90,000 - 100,000
                          </option>
                          <option value="100,000+">100,000+</option>
                        </select>
                      </div>
                      <div className="col-md-2">
                        <select
                          style={{
                            width: "100%",
                            height: "45px",
                            border: "1px solid lightgrey",
                            padding: "0px 10px",
                          }}
                          onChange={(e) => setIndustrySelected(e.target.value)}
                        >
                          <option selected value={0}>
                            Filter By Industry
                          </option>
                          {industryList.map((i) => (
                            <option value={i.id} key={i.id}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="table-wrapper2">
                    <table className="eg-table table job-list-table mb-0">
                      <thead>
                        <tr>
                          <th>Job Title</th>
                          <th>Priority</th>
                          <th>View</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobsLoaded === true
                          ? jobs.map((j) => {
                              if (
                                j.job_title
                                  .toLowerCase()
                                  .includes(searchTitle.toLowerCase()) ||
                                searchTitle.toLowerCase == ""
                              ) {
                                return (
                                  <tr key={j.job_id}>
                                    <td data-label="Job Title">
                                      <div className="job-content">
                                        <h5>
                                          <a href="job-details-for-company.html">
                                            {j.job_title}
                                          </a>
                                        </h5>
                                      </div>
                                    </td>
                                    <td data-label="Priority">
                                      <select onChange={(e) => {
                                        axios.get("https://backend.ogsmanpower.com/static/changePriority/" + j.job_id + "/" + e.target.value).then(response => {
                                          alert(response.data.message);
                                        })
                                      }}>
                                        <option value="1" selected={j.priority == 1}>High</option>
                                        <option value="3" selected={j.priority == 2}>Medium</option>
                                        <option value="5" selected={j.priority == 5}>Low</option>
                                      </select>
                                    </td>
                                    <td data-label="View">
                                    <a
                                            href={`/job_details/${j.job_id}`}
                                            className="primry-btn-2  eg-btn green--btn"
                                            style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}
                                          >
                                            View
                                          </a>
                                    </td>
                                    <td data-label="Edit">
                                    <a
                                            href={`/admin_edit/${j.job_id}`}
                                            className="primry-btn-2 sky--btn"
                                            style={{ padding: "5px 10px", display: 'flex', justifyContent: 'center' }}
                                          >
                                            Edit
                                          </a>
                                    </td>
                                    <td data-label="Action">
                                      <ul className="action-btn-group2">
                                        <li>
                                          <a
                                            href="#"
                                            className="primry-btn-2 sky--btn"
                                            style={{ padding: "5px 10px" }}
                                            onClick={() =>
                                              axios
                                                .get(
                                                  "https://backend.ogsmanpower.com/static/admindelete/" +
                                                    j.job_id
                                                )
                                                .then((response) =>
                                                  alert(response.data.message)
                                                )
                                            }
                                          >
                                            Delete
                                          </a>
                                        </li>
                                        <li>

                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              } else {
                                return null;
                              }
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default AllJobs;
