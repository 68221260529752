import React, { useRef, useState } from "react";
import DashboardNavbar from "../../../components/Navbar/DashboardNavbar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect } from "react";
import axios from "axios";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  BlobProvider,
} from "@react-pdf/renderer";
import AdminMenu from "./AdminMenu";

const AdminDashboard = () => {
  const [noSeekers, setNoSeekers] = useState(0);
  const [noCompanies, setNoCompanies] = useState(0);
  const [noJobs, setNoJobs] = useState(0);
  const [cvCreated, setCvCreated] = useState(0);

  const [jobs, setJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);

  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/seeker_login")
      .then((response) => setNoSeekers(response.data.result.length));
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/company_login")
      .then((response) => setNoCompanies(response.data.result.length));
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/jobs")
      .then((response) => setNoJobs(response.data.result.length));
    axios
      .get(
        "https://backend.ogsmanpower.com/static/getrows/resume_basic_information"
      )
      .then((response) => setCvCreated(response.data.result.length));
    axios
      .get("https://backend.ogsmanpower.com/static/getfrom/jobs/status/0")
      .then((response) => {
        console.log(response);
        setJobs(response.data.result);
        setJobsLoaded(true);
      });
  }, []);

  const approveJob = async (id) => {
    axios
      .get("https://backend.ogsmanpower.com/static/approveJob/" + id)
      .then((response) => {
        if (response.data.code == 1) {
          alert("Job Approved");
        }
      });
  };

  return (
    <>
      <DashboardNavbar />
      <div
        className="dashboard-area company-dashboard pt-120 mb-120"
        style={{ background: "#f8f8f8" }}
      >
        <div className="container" id="ju">
          <div className="row g-lg-4">
            <div className="col-lg-12 mb-20">
              <div className="dashboard-sidebar">
                <AdminMenu />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="dashboard-inner">
                <div className="counter-area">
                  <div className="row g-4 justify-content-center mb-30">
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single">
                        <div className="coundown">
                          <p>Total Seekers</p>
                          <div className="d-flex align-items-center">
                            <h3 className="odometer">{noSeekers}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single two">
                        <div className="coundown">
                          <p>Total Companies</p>
                          <div className="d-flex align-items-center">
                            <h3 className="odometer">{noCompanies}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single three">
                        <div className="coundown">
                          <p>Total Jobs</p>
                          <div className="d-flex align-items-center">
                            <h3 className="odometer">{noJobs}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single four">
                        <div className="coundown">
                          <p>Total CVs</p>
                          <div className="d-flex align-items-center">
                            <h3 className="odometer">{cvCreated}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="new-applied-job-area">
                  <h5>Pending Jobs:</h5>
                  <div className="table-wrapper2">
                    <table className="eg-table table job-list-table mb-0">
                      <thead>
                        <tr>
                          <th>Job Title</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>View</th>
                          <th>Approve</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobsLoaded === true
                          ? jobs.map((j) => (
                              <tr key={j.job_id}>
                                <td data-label="Job Title">
                                  <div className="job-content">
                                    <h5>
                                      <a href="job-details-for-company.html">
                                        {j.job_title}
                                      </a>
                                    </h5>
                                  </div>
                                </td>
                                <td data-label="Action">
                                  <ul className="action-btn-group2">
                                    <li>
                                      <a
                                        href={`/job_details/${j.job_id}`}
                                        className="primry-btn-2  eg-btn green--btn"
                                      >
                                        <svg
                                          width={14}
                                          height={10}
                                          viewBox="0 0 14 10"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M14 5C14 5 11.375 0 7 0C2.625 0 0 5 0 5C0 5 2.625 10 7 10C11.375 10 14 5 14 5ZM1.02637 5C1.44945 4.33193 1.93606 3.70971 2.47887 3.14273C3.605 1.97091 5.145 0.909091 7 0.909091C8.855 0.909091 10.3941 1.97091 11.522 3.14273C12.0648 3.70971 12.5514 4.33193 12.9745 5C12.9237 5.07909 12.8678 5.16636 12.8039 5.26182C12.5108 5.69818 12.0776 6.28 11.522 6.85727C10.3941 8.02909 8.85413 9.09091 7 9.09091C5.145 9.09091 3.60588 8.02909 2.478 6.85727C1.93519 6.29028 1.44946 5.66807 1.02637 5Z" />
                                          <path d="M7 2.72721C6.41984 2.72721 5.86344 2.96665 5.4532 3.39287C5.04297 3.81909 4.8125 4.39717 4.8125 4.99993C4.8125 5.6027 5.04297 6.18078 5.4532 6.60699C5.86344 7.03321 6.41984 7.27266 7 7.27266C7.58016 7.27266 8.13656 7.03321 8.5468 6.60699C8.95703 6.18078 9.1875 5.6027 9.1875 4.99993C9.1875 4.39717 8.95703 3.81909 8.5468 3.39287C8.13656 2.96665 7.58016 2.72721 7 2.72721ZM3.9375 4.99993C3.9375 4.15606 4.26016 3.34676 4.83449 2.75005C5.40882 2.15334 6.18777 1.81812 7 1.81812C7.81223 1.81812 8.59118 2.15334 9.16551 2.75005C9.73984 3.34676 10.0625 4.15606 10.0625 4.99993C10.0625 5.8438 9.73984 6.65311 9.16551 7.24982C8.59118 7.84652 7.81223 8.18175 7 8.18175C6.18777 8.18175 5.40882 7.84652 4.83449 7.24982C4.26016 6.65311 3.9375 5.8438 3.9375 4.99993Z" />
                                        </svg>
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    className="primry-btn-2 sky--btn"
                                    style={{
                                      padding: "5px 10px",
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    onClick={() => approveJob(j.job_id)}
                                  >
                                    Approve
                                  </a>
                                </td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default AdminDashboard;
