import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
const JPJobsSection = ({ type, title, cat, customSearch, filteredJobs }) => {
  const [jobsList, setJobsList] = useState([]);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL +"/static/homepageJobs")
      .then((response) => {
        if (response.data.code == 1) {
          setJobsList(response.data.result);
        }
      });
  }, []);
  return (
    <>
      {type == 1 ? (
        <>
          {customSearch === true ? (
            <div className="home1-featured-area mb-120">
              <div className="container">
                <div className="row mb-60">
                  <div className="col-12 d-flex flex-wrap align-items-end justify-content-md-between justify-content-start gap-3">
                    <div className="section-title1">
                      <h2>
                        Results for <span>your</span> search
                      </h2>
                      <p>
                        To choose your trending job dream &amp; to make future
                        bright.
                      </p>
                    </div>
                    <div className="explore-btn">
                      <a href="/jobs">
                        Explore More{" "}
                        <span>
                          <img
                            src="assets/images/icon/explore-elliose.svg"
                            alt=""
                          />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row g-4">
                  {filteredJobs.map((j) => (
                    <div className="col-xl-4 col-lg-6">
                      <div className="feature-card">
                        <div className="company-area">
                          <div className="company-details">
                            <div className="name-location">
                              <h5>
                                <a href="job-details.html">{j.job_title}</a>
                              </h5>
                              <p>{j.jobLocation}</p>
                            </div>
                            <div className="bookmark">
                              <i className="bi bi-bookmark" />
                            </div>
                          </div>
                        </div>
                        <div className="job-discription">
                          <ul>
                            <li>
                              <img src="assets/images/icon/arrow2.svg" alt="" />
                              <p>
                                <span className="title">Salary:</span>{" "}
                                {j.salary}
                              </p>
                            </li>
                            <li>
                              <img src="assets/images/icon/arrow2.svg" alt="" />
                              <p>
                                <span className="title">Vacancy:</span>{" "}
                                <span>{j.positions}</span>
                              </p>
                            </li>
                            <li>
                              <img src="assets/images/icon/arrow2.svg" alt="" />
                              <p>
                                <span className="title">Min Experience:</span>{" "}
                                <span> {j.min_experience}</span>
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="job-type-apply">
                          <div className="apply-btn">
                            <a href={`/job_details/${j.job_id}`}>
                              <span>
                                <img
                                  src="assets/images/icon/apply-ellipse.svg"
                                  alt=""
                                />
                              </span>
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="home1-featured-area mb-120">
              <div className="container">
                <div className="row mb-60">
                  <div className="col-12 d-flex flex-wrap align-items-end justify-content-md-between justify-content-start gap-3">
                    <div className="section-title1">
                      <h2>
                        Latest <span>Recent</span> Jobs
                      </h2>
                      <p>
                        To choose your trending job dream &amp; to make future
                        bright.
                      </p>
                    </div>
                    <div className="explore-btn">
                      <a href="/jobs">
                        Explore More{" "}
                        <span>
                          <img
                            src="assets/images/icon/explore-elliose.svg"
                            alt=""
                          />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row g-4">
                  {jobsList.length > 0 &&
                    jobsList.map((j) => (
                      <div className="col-xl-4 col-lg-6">
                        <div className="feature-card">
                          <div className="company-area">
                            <div className="company-details">
                              <div className="name-location">
                                <h5>
                                  <a href="job-details.html">{j.job_title}</a>
                                </h5>
                                <p>{j.jobLocation}</p>
                              </div>
                              <div className="bookmark">
                                <i className="bi bi-bookmark" />
                              </div>
                            </div>
                          </div>
                          <div className="job-discription">
                            <ul>
                              <li>
                                <img
                                  src="assets/images/icon/arrow2.svg"
                                  alt=""
                                />
                                <p>
                                  <span className="title">Salary:</span>{" "}
                                  {j.salary}
                                </p>
                              </li>
                              <li>
                                <img
                                  src="assets/images/icon/arrow2.svg"
                                  alt=""
                                />
                                <p>
                                  <span className="title">Vacancy:</span>{" "}
                                  <span>{j.positions}</span>
                                </p>
                              </li>
                              <li>
                                <img
                                  src="assets/images/icon/arrow2.svg"
                                  alt=""
                                />
                                <p>
                                  <span className="title">Min Experience:</span>{" "}
                                  <span> {j.min_experience}</span>
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="job-type-apply">
                            <div className="apply-btn">
                              <a href={`/job_details/${j.job_id}`}>
                                <span>
                                  <img
                                    src="assets/images/icon/apply-ellipse.svg"
                                    alt=""
                                  />
                                </span>
                                Apply Now
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="home1-featured-area mb-120">
          <div className="container">
            <div className="row mb-60">
              <div className="col-12 d-flex flex-wrap align-items-end justify-content-md-between justify-content-start gap-3">
                <div className="section-title1">
                  <h2>
                    Our <span>Featured</span> Jobs
                  </h2>
                  <p>
                    To choose your trending job dream &amp; to make future
                    bright.
                  </p>
                </div>
                <div className="explore-btn">
                  <a href="/jobs">
                    Explore More{" "}
                    <span>
                      <img
                        src="assets/images/icon/explore-elliose.svg"
                        alt=""
                      />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="row g-4">
              {jobsList.length > 0 &&
                jobsList.map((j) => (
                  <div className="col-xl-4 col-lg-6">
                    <div className="feature-card">
                      <div className="company-area">
                        <div className="company-details">
                          <div className="name-location">
                            <h5>
                              <a href="job-details.html">{j.job_title}</a>
                            </h5>
                            <p>{j.jobLocation}</p>
                          </div>
                          <div className="bookmark">
                            <i className="bi bi-bookmark" />
                          </div>
                        </div>
                      </div>
                      <div className="job-discription">
                        <ul>
                          <li>
                            <img src="assets/images/icon/arrow2.svg" alt="" />
                            <p>
                              <span className="title">Salary:</span> {j.salary}
                            </p>
                          </li>
                          <li>
                            <img src="assets/images/icon/arrow2.svg" alt="" />
                            <p>
                              <span className="title">Vacancy:</span>{" "}
                              <span>{j.positions}</span>
                            </p>
                          </li>
                          <li>
                            <img src="assets/images/icon/arrow2.svg" alt="" />
                            <p>
                              <span className="title">Min Experience:</span>{" "}
                              <span> {j.min_experience}</span>
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="job-type-apply">
                        <div className="apply-btn">
                          <a href={`/job_details/${j.job_id}`}>
                            <span>
                              <img
                                src="assets/images/icon/apply-ellipse.svg"
                                alt=""
                              />
                            </span>
                            Apply Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default JPJobsSection;
