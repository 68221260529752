import React, { useRef, useState } from "react";
import DashboardNavbar from "../../../components/Navbar/DashboardNavbar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect } from "react";
import axios from "axios";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  BlobProvider,
} from "@react-pdf/renderer";
import { Button, Modal } from "antd";
import AdminMenu from "./AdminMenu";
import { useNavigate } from "react-router-dom";

const ManageEmployer = () => {
  const [seekerList, setSeekerList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [seekerId, setSeekerId] = useState(0);
  const [profileImage, setProfileImage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userJobs, setUserJobs] = useState([]);

  const [rank, setRank] = useState("owner");
  const navigate = useNavigate();

  useEffect(() => {
    if (seekerId > 0) {
      axios
        .get(
          `https://backend.ogsmanpower.com/static/getfrom/jobs/posted_by/${seekerId}`
        )
        .then((response) => {
          setUserJobs(response.data.result);
        });
    }
  }, [seekerId]);

  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/company_login")
      .then((response) => {
        setSeekerList(response.data.result);
      });
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    axios
      .post("https://backend.ogsmanpower.com/static/adminUpdateEmployer", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        seekerId: seekerId,
      })
      .then((response) => alert(response.data.message));
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDownload = () => {
    window.location.href = "https://backend.ogsmanpower.com/download-company";
  };
  const handleIdCardUpload = async (e) => {
    const newImage = e.target.files[0];
    const formData = new FormData();
    formData.append("image", newImage);
    console.log(e.target.files[0]);
    try {
      const res = await axios.post(
        "https://backend.ogsmanpower.com/static/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await axios
        .post("https://backend.ogsmanpower.com/static/changeCompanyLogo", {
          logoName: res.data.fileName,
          companyId: seekerId,
        })
        .then((response) => console.log(response));
    } catch (err) {
      alert(err);
    }
  };
  return (
    <>
      <DashboardNavbar />
      <div
        className="dashboard-area company-dashboard pt-120 mb-120"
        style={{ background: "#f8f8f8" }}
      >
        <div className="container" id="ju">
          <div className="row g-lg-4">
            <div className="col-lg-12 mb-20">
              <div className="dashboard-sidebar">
                <AdminMenu />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="dashboard-inner">
                <div className="new-applied-job-area">
                  <h5>Manage Employers:</h5>
                  <div className="row">
                    <div className="col-md-3">
                      <select
                        style={{
                          width: "100%",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                        onChange={(e) => setRank(e.target.value)}
                      >
                        <option value="owner">Owner</option>
                        <option value="hr">HR</option>
                        <option value="hiring_manager">Hiring Manager</option>
                        <option value="department_head">Department Head</option>
                        <option value="recruitment_team">
                          Recruitment Team
                        </option>
                        <option value="technical_expert">
                          Technical Expert
                        </option>
                        <option value="legal_compliance">
                          Legal and Compliance Team
                        </option>
                        <option value="diversity_inclusion">
                          Diversity and Inclusion Team
                        </option>
                        <option value="employee_referral">
                          Employee Referral Program
                        </option>
                        <option value="executive_leadership">
                          Executive Leadership
                        </option>
                        <option value="contractor">
                          Contractor/External Recruiter
                        </option>
                        <option value="onboarding_team">Onboarding Team</option>
                      </select>
                    </div>
                  </div>
                  <div className="table-wrapper2">
                    <Modal
                      title="Edit Employer"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <div className="row">
                        <div>
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              backgroundImage: `url(https://backend.ogsmanpower.com/profile_images/${profileImage})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              borderRadius: "100%",
                            }}
                          ></div>
                          <h4 className="mb-4 mt-1">{companyName}</h4>
                        </div>
                        <div className="form-inner mb-25">
                          <label htmlFor="hight">Company Logo*</label>
                          <div className="input-area">
                            <input
                              type="file"
                              id="hight"
                              name="hight"
                              onChange={handleIdCardUpload}
                              placeholder="76*************"
                              style={{
                                border: "1px solid lightgrey",
                                padding: "10px 10px",
                                width: "100%",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-inner mb-25">
                            <label htmlFor="firstname1">First Name*</label>
                            <div className="input-area">
                              <input
                                type="text"
                                id="firstname1"
                                name="firstname"
                                placeholder="Mrs. Jacoline"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                style={{
                                  border: "1px solid gray",
                                  padding: "10px",
                                  width: "100%",
                                  border: "1px solid lightgrey",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="firstname1">Last Name*</label>
                            <div className="input-area">
                              <input
                                type="text"
                                id="firstname1"
                                name="firstname"
                                placeholder="Mrs. Jacoline"
                                style={{
                                  border: "1px solid gray",
                                  padding: "10px",
                                  width: "100%",
                                  border: "1px solid lightgrey",
                                }}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="firstname1">Email*</label>
                            <div className="input-area">
                              <input
                                type="text"
                                id="firstname1"
                                name="firstname"
                                placeholder="Mrs. Jacoline"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{
                                  border: "1px solid gray",
                                  padding: "10px",
                                  width: "100%",
                                  border: "1px solid lightgrey",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="firstname1">Password*</label>
                            <div className="input-area">
                              <input
                                type="password"
                                id="firstname1"
                                name="firstname"
                                placeholder="Mrs. Jacoline"
                                style={{
                                  border: "1px solid lightgrey",
                                  padding: "10px",
                                  width: "100%",
                                }}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <h6>Jobs Posted by {companyName}:</h6>
                        <div style={{ overflowX: "auto" }}>
                          <table style={{ width: "100%", overflow: "scroll" }}>
                            <tr>
                              <th
                                style={{
                                  border: "1px solid lightgrey",
                                  padding: "0 20px",
                                }}
                              >
                                Id
                              </th>
                              <th
                                style={{
                                  border: "1px solid lightgrey",
                                  padding: "0 20px",
                                }}
                              >
                                Title
                              </th>
                              <th
                                style={{
                                  border: "1px solid lightgrey",
                                  padding: "0 20px",
                                }}
                              >
                                Positions
                              </th>
                              <th
                                style={{
                                  border: "1px solid lightgrey",
                                  padding: "0 20px",
                                }}
                              >
                                Country
                              </th>
                              <th
                                style={{
                                  border: "1px solid lightgrey",
                                  padding: "0 20px",
                                }}
                              >
                                Actions
                              </th>
                            </tr>
                            {userJobs.length > 0
                              ? userJobs.map((u) => (
                                  <tr>
                                    <td>{u.job_id}</td>
                                    <td>{u.job_title}</td>
                                    <td>{u.positions}</td>
                                    <td>{u.country}</td>
                                    <td>
                                      <div style={{ display: "flex" }}>
                                        <p
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            navigate("/job_details/" + u.job_id)
                                          }
                                        >
                                          View
                                        </p>
                                        <p
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            navigate("/admin_edit/" + u.job_id)
                                          }
                                        >
                                          Edit
                                        </p>
                                        <p
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            axios
                                              .get(
                                                "https://backend.ogsmanpower.com/static/admindelete/" +
                                                  u.job_id
                                              )
                                              .then((response) =>
                                                alert(response.data.code)
                                              )
                                          }
                                        >
                                          Delete
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              : "Loading"}
                          </table>
                        </div>
                        <div className="col-md-12">
                          <div className="form-inner mb-25">
                            <label htmlFor="firstname1"></label>
                            <div className="input-area">
                              <button
                                className="btn btn-outline-danger"
                                style={{ width: "100%", height: "45px" }}
                                onClick={() => {
                                  axios
                                    .post(
                                      "https://backend.ogsmanpower.com/static/adminDeleteEmployer",
                                      {
                                        seekerId: seekerId,
                                      }
                                    )
                                    .then((response) =>
                                      alert(response.data.message)
                                    );
                                  setIsModalOpen(false);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <table className="eg-table table job-list-table mb-0">
                      <thead>
                        <tr>
                          <th>Unique Id</th>
                          <th>Employer Name</th>
                          <th>Employer Email</th>
                          <th>Phone</th>
                          <th>Role</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {seekerList.map((j) => {
                          if (
                            j.rank.toLowerCase().includes(rank.toLowerCase()) ||
                            rank == ""
                          ) {
                            return (
                              <tr key={j.id}>
                                <td data-label="Employer ID">
                                  <div className="job-content">
                                    <h5>
                                      <a href="job-details-for-company.html">
                                        {j.id}
                                      </a>
                                    </h5>
                                  </div>
                                </td>
                                <td data-label="Name">
                                  {j.first_name + " " + j.last_name}
                                </td>
                                <td data-label="Email">{j.email}</td>
                                <td data-label="Phone">{j.phone}</td>
                                <td data-label="Rank">{j.rank}</td>
                                {/* <td data-label="Jobs">{userJobs.length > 0 ? userJobs.length : 0}</td> */}
                                <td data-label="Action">
                                  <ul className="action-btn-group2">
                                    <li>
                                      <a
                                        href="#"
                                        className="primry-btn-2 eg-btn sky--btn"
                                        onClick={() => {
                                          setFirstName(j.first_name);
                                          setLastName(j.last_name);
                                          setEmail(j.email);
                                          setPassword(j.password);
                                          setPhone(j.phone);
                                          setSeekerId(j.id);
                                          setProfileImage(j.company_logo);
                                          setCompanyName(j.company_name);
                                          showModal();
                                        }}
                                      >
                                        <svg
                                          width={12}
                                          height={12}
                                          viewBox="0 0 12 12"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M11.8798 1.19201C11.9563 1.26957 11.9993 1.37462 11.9993 1.48414C11.9993 1.59365 11.9563 1.6987 11.8798 1.77627L11.0253 2.64024L9.3868 0.98512L10.2413 0.121149C10.3181 0.0435774 10.4223 0 10.5309 0C10.6395 0 10.7437 0.0435774 10.8205 0.121149L11.8798 1.19118V1.19201ZM10.4461 3.22449L8.8076 1.56938L3.22607 7.20836C3.18098 7.2539 3.14704 7.30944 3.12694 7.37056L2.46745 9.36829C2.45549 9.40471 2.45379 9.44377 2.46254 9.48111C2.4713 9.51844 2.49016 9.55259 2.51702 9.57972C2.54388 9.60685 2.57768 9.62591 2.61464 9.63475C2.65161 9.64359 2.69028 9.64188 2.72633 9.62979L4.70399 8.96361C4.76442 8.94355 4.8194 8.90955 4.86456 8.8643L10.4461 3.22532V3.22449Z" />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 10.7585C0 11.0878 0.12947 11.4035 0.359928 11.6363C0.590385 11.8691 0.902953 11.9999 1.22887 11.9999H10.2406C10.5665 11.9999 10.8791 11.8691 11.1095 11.6363C11.34 11.4035 11.4694 11.0878 11.4694 10.7585V5.79319C11.4694 5.68345 11.4263 5.5782 11.3495 5.50061C11.2727 5.42301 11.1685 5.37941 11.0598 5.37941C10.9512 5.37941 10.847 5.42301 10.7702 5.50061C10.6934 5.5782 10.6502 5.68345 10.6502 5.79319V10.7585C10.6502 10.8683 10.607 10.9735 10.5302 11.0511C10.4534 11.1287 10.3492 11.1723 10.2406 11.1723H1.22887C1.12023 11.1723 1.01604 11.1287 0.939222 11.0511C0.862403 10.9735 0.819246 10.8683 0.819246 10.7585V1.6554C0.819246 1.54566 0.862403 1.44041 0.939222 1.36281C1.01604 1.28522 1.12023 1.24162 1.22887 1.24162H6.55397C6.66261 1.24162 6.7668 1.19803 6.84362 1.12043C6.92044 1.04283 6.96359 0.937583 6.96359 0.827842C6.96359 0.718101 6.92044 0.612854 6.84362 0.535256C6.7668 0.457657 6.66261 0.414063 6.55397 0.414062H1.22887C0.902953 0.414063 0.590385 0.544846 0.359928 0.777642C0.12947 1.01044 0 1.32618 0 1.6554V10.7585Z"
                                          />
                                        </svg>
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default ManageEmployer;
