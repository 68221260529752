import React from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "../../../components/Navbar/DashboardNavbar";

const Company = () => {
  return (
    <>
      <DashboardNavbar />
      <Outlet />
    </>
  );
};

export default Company;
