import React from "react";
import { Alert, Space } from "antd";
const CustomAlert = ({ type, message }) => {
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <Alert message={message} type={type} showIcon />
    </Space>
  );
};
export default CustomAlert;
