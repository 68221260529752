import React, { useState } from "react";
import Navigation from "../../../components/Navbar/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button, message, Space, Alert } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import PhoneInput from "react-phone-number-input";
const ProfileStep = ({ basicId }) => {
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [presentAddress, setPresentAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [maritalStatus, setMaritalStatus] = useState(1);
  const [gender, setGender] = useState(1);
  const [religion, setReligion] = useState(1);
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [passportNumber, setPassportNumber] = useState("");

  const [dataLoading, setDataLoading] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [maritalList, setMaritalList] = useState([]);

  const [idCardImage, setIdCardImage] = useState("");
  const [passportImage, setPassportImage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post("https://backend.ogsmanpower.com/static/getResumeProfileDetails", {
        basic_info_id: basicId,
      })
      .then((response) => {
        setFatherName(response.data.result[0].father_name);
        setMotherName(response.data.result[0].mother_name);
        setNationalId(response.data.result[0].national_id);
        setDateOfBirth(response.data.result[0].dob);
        setPresentAddress(response.data.result[0].present_address);
        setPermanentAddress(response.data.result[0].perm_address);
        setHeight(response.data.result[0].height);
        setWeight(response.data.result[0].weight);
        setPassportNumber(response.data.result[0].passport_number);
        setIdCardImage(response.data.result[0].id_photo);
        setPassportImage(response.data.result[0].passport_photo);
        setGender(response.data.result[0].gender);
        setMaritalStatus(response.data.result[0].marital_status);
        setReligion(response.data.result[0].religion);
      });
  }, []);

  const handleIdCardUpload = async (e) => {
    const newImage = e.target.files[0];
    const formData = new FormData();
    formData.append("image", newImage);
    console.log(e.target.files[0]);
    try {
      const res = await axios.post(
        "https://backend.ogsmanpower.com/static/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIdCardImage(res.data.fileName);
    } catch (err) {
      alert(err);
    }
  };
  const handlePassportUpload = async (e) => {
    const newImage = e.target.files[0];
    const formData = new FormData();
    formData.append("image", newImage);
    console.log(e.target.files[0]);
    try {
      const res = await axios.post(
        "https://backend.ogsmanpower.com/static/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setPassportImage(res.data.fileName);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/genders")
      .then((response) => {
        setGenderList(response.data.result);
      });

    axios
      .get("https://backend.ogsmanpower.com/static/getrows/religions")
      .then((response) => {
        setReligionList(response.data.result);
      });

    axios
      .get("https://backend.ogsmanpower.com/static/getrows/marital_status")
      .then((response) => {
        setMaritalList(response.data.result);
      });
  }, []);

  return (
    <div
      className={`tab-pane fade show mt-2`}
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <form className="edit-profile-form profile-form">
        <div className="row">
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="fathername">Father’s Name*</label>
              <div className="input-area">
                <input
                  type="text"
                  id="fathername"
                  name="fathername"
                  placeholder="Mr. Norman Frankly"
                  value={fatherName}
                  onChange={(e) => setFatherName(e.target.value)}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="mothername">Mother's Name*</label>
              <div className="input-area">
                <input
                  type="text"
                  id="mothername"
                  name="mothername"
                  placeholder="Mrs. Marcoline Frankly"
                  value={motherName}
                  onChange={(e) => setMotherName(e.target.value)}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="datepicker5">Date of Birth*</label>
              <div className="input-area">
                <input
                  type="text"
                  id="datepicker5"
                  name="datepicker5"
                  placeholder="03/08/1986"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="nid">National Id*</label>
              <div className="input-area">
                <input
                  type="text"
                  id="nid"
                  name="nid "
                  placeholder="0988 *** *** *** **"
                  value={nationalId}
                  onChange={(e) => setNationalId(e.target.value)}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <div className="salary-wrap">
                <label className="label" htmlFor="preAddress">
                  Present Address*
                </label>
              </div>
              <div className="input-area">
                <input
                  type="text"
                  id="preAddress"
                  name="preAddress "
                  placeholder="Your Address Here..."
                  value={presentAddress}
                  onChange={(e) => setPresentAddress(e.target.value)}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="perAddress">Permanent Address*</label>
              <div className="input-area">
                <input
                  type="text"
                  id="perAddress"
                  name="perAddress"
                  placeholder="Your Address Here..."
                  value={permanentAddress}
                  onChange={(e) => setPermanentAddress(e.target.value)}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label>Marital Status*</label>
              <div className="input-area">
                <select
                  className="select1"
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid lightgrey",
                  }}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                >
                  {maritalList.map((m) => (
                    <>
                      <option
                        key={m.id}
                        value={m.id}
                        selected={m.id == maritalStatus ? true : false}
                      >
                        {m.status}
                      </option>
                    </>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label>Gender*</label>
              <div className="input-area">
                <select
                  className="select1"
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid lightgrey",
                  }}
                  onChange={(e) => setGender(e.target.value)}
                >
                  {genderList.map((g) => (
                    <>
                      <option key={g.id} value={g.id}>
                        {g.gender}
                      </option>
                    </>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-inner mb-25">
              <label>Religion*</label>
              <div className="input-area">
                <select
                  className="select1"
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid lightgrey",
                  }}
                  onChange={(e) => setReligion(e.target.value)}
                >
                  {religionList.map((r) => (
                    <>
                      <option
                        key={r.id}
                        value={r.id}
                        selected={r.id == religion ? true : false}
                      >
                        {r.religion_name}
                      </option>
                    </>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="hight">Height*</label>
              <div className="input-area">
                <input
                  type="text"
                  id="hight"
                  name="hight"
                  placeholder="5.6'"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="weight">Weight*</label>
              <div className="input-area">
                <input
                  type="text"
                  id="weight"
                  name="weight "
                  placeholder={56}
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-inner mb-25">
              <label htmlFor="hight">Passport Number*</label>
              <div className="input-area">
                <input
                  type="text"
                  id="hight"
                  name="hight"
                  placeholder="76*************"
                  value={passportNumber}
                  onChange={(e) => setPassportNumber(e.target.value)}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="hight">Passport Photo*</label>
              <div className="input-area">
                <input
                  type="file"
                  id="hight"
                  name="hight"
                  placeholder="76*************"
                  onChange={handlePassportUpload}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="hight">Government ID Photo*</label>
              <div className="input-area">
                <input
                  type="file"
                  id="hight"
                  name="hight"
                  placeholder="76*************"
                  onChange={handleIdCardUpload}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "10px 10px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-inner">
              <button
                className="primry-btn-2 lg-btn w-unset"
                type="submit"
                disabled={dataLoading}
                onClick={(e) => {
                  e.preventDefault();
                  // if (fatherName == "" || motherName == "" || dateOfBirth == "" || nationalId == "" || presentAddress == "" || permanentAddress == "" || height == "" || weight == "") {
                  //   alert("All fields are required!");
                  // } else {
                  setDataLoading(true);
                  axios
                    .post(
                      "https://backend.ogsmanpower.com/update_onboard/step_two",
                      {
                        father_name: fatherName,
                        mother_name: motherName,
                        dov: dateOfBirth,
                        national_id: nationalId,
                        persent_address: presentAddress,
                        perm_address: permanentAddress,
                        marital_status: maritalStatus,
                        gender: gender,
                        religion: religion,
                        height: height,
                        weight: weight,
                        basic_info_id: basicId,
                        passport_number: passportNumber,
                        passport_photo: passportImage,
                        id_photo: idCardImage,
                      }
                    )
                    .then((response) => {
                      setDataLoading(false);
                      if (response.data.code == 1) {
                        alert("Changes Updated");
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  // }
                }}
              >
                {dataLoading ? "Please wait..." : "Next"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const EducationalStep = ({ stepNumber, stepHandler }) => {
  const [forms, setForms] = useState([]);

  const [educationList, setEducationList] = useState([]);

  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/education_levels")
      .then((response) => {
        setEducationList(response.data.result);
      });
  }, []);

  const handleDuplicate = () => {
    const newForm = {
      educationLevel: 1,
      institute: "Ahad Aman",
      major: "Science",
      result: "21",
      startingPeriod: "10/22/22222",
      endingPeriod: "11/11/1111",
      gpa: "4.5",
    };
    setForms([...forms, newForm]);
  };
  return (
    <div
      className={`tab-pane fade show ${stepNumber == 3 ? "active" : ""}`}
      id="contact"
      role="tabpanel"
      aria-labelledby="contact-tab"
    >
      <form className="edit-profile-form profile-form">
        <div className="section-title2">
          <h5>Educational Qualification:</h5>
        </div>
        <div className="education-row">
          {forms.map((form, index) => (
            <div className="row" key={index}>
              <div className="col-lg-12">
                <div className="info-title">
                  <h6>Academic Information:</h6>
                  <div className="dash" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-25">
                  <label>Education Level*</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/qualification-2.svg" alt="" />
                    <select
                      className="select1"
                      style={{ width: "100%", padding: "10px", border: "none" }}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].educationLevel = e.target.value;
                        setForms(updatedFormData);
                      }}
                    >
                      {educationList.map((e) => (
                        <option value={e.id} key={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-25">
                  <label>My Major*</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/major.svg" alt="" />
                    <input
                      type="text"
                      id="major"
                      name="major"
                      placeholder="Arts, Science, Business..."
                      value={forms[index].major}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].major = e.target.value;
                        setForms(updatedFormData);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-25">
                  <label htmlFor="institute">Institute/University*</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/univercity.svg" alt="" />
                    <input
                      type="text"
                      id="institute"
                      name="institute"
                      placeholder="Type Your Institute Name..."
                      value={forms[index].institute}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].institute = e.target.value;
                        setForms(updatedFormData);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-30">
                  <label htmlFor="gpa">Result/GPA**</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/gpa-2.svg" alt="" />
                    <input
                      type="text"
                      id="gpa"
                      name="gpa"
                      placeholder="4.75"
                      value={forms[index].gpa}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].gpa = e.target.value;
                        setForms(updatedFormData);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-20">
                  <label htmlFor="datepicker10">Starting Period*</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/calender2.svg" alt="" />
                    <input
                      type="text"
                      id="datepicker10"
                      name="ep"
                      placeholder="DD/MM/YY"
                      value={forms[index].startingPeriod}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].startingPeriod = e.target.value;
                        setForms(updatedFormData);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-20">
                  <label htmlFor="datepicker11">Ending Period*</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/calender2.svg" alt="" />
                    <input
                      type="text"
                      id="datepicker11"
                      name="ep"
                      placeholder="DD/MM/YY"
                      value={forms[index].endingPeriod}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].endingPeriod = e.target.value;
                        setForms(updatedFormData);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="add-remove-btn d-flex align-items-center justify-content-between mb-50">
          <div className="add-row">
            <button type="button" className="" onClick={handleDuplicate}>
              Add Education+
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-inner">
            <button
              className="primry-btn-2 lg-btn w-unset"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                for (let i = 0; i < forms.length; i++) {
                  alert(forms.length);
                  axios
                    .post(
                      "https://backend.ogsmanpower.com/onboard/step_three",
                      {
                        educationLevel: forms[i].educationLevel,
                        institute: forms[i].institute,
                        major: forms[i].major,
                        result: 1,
                        startingPeriod: forms[i].startingPeriod,
                        endingPeriod: forms[i].endingPeriod,
                        gpa: forms[i].gpa,
                        basic_info_id: localStorage.getItem("basic_id"),
                      }
                    )
                    .then((response) => stepHandler(4));
                }
              }}
            >
              Update Change
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const EmploymentStep = ({ basicId }) => {
  const [forms, setForms] = useState([]);

  const [industryList, setIndustryList] = useState([]);
  const [industrySelected, setIndustrySelected] = useState(1);
  const [catList, setCatList] = useState([]);
  const [catSelected, setCatSelected] = useState(1);
  const [designation, setDesignation] = useState("");

  const [companyName, setCompanyName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEm = async () => {
      const decodedToken = jwtDecode(localStorage.getItem("accessToken"));
      await axios
        .get(
          "https://backend.ogsmanpower.com/static/getPofessionalInfo/" + basicId
        )
        .then((response) => {
          setDesignation(response.data.result.designation);
          setCompanyName(response.data.result.companyName);
          setIndustrySelected(response.data.result.industrySelected);
        });
    };

    fetchEm();
  }, []);

  useEffect(() => {
    axios
      .get(`https://backend.ogsmanpower.com/static/getrows/category`)
      .then((response) => {
        setIndustryList(response.data.result);
      });
    axios
      .get(
        `https://backend.ogsmanpower.com/static/getdepartment/${industrySelected}`
      )
      .then((response) => {
        setCatList(response.data.result);
      });
  }, [industrySelected]);

  return (
    <div
      className={`tab-pane fade show mt-3`}
      id="professional"
      role="tabpanel"
      aria-labelledby="professional-tab"
    >
      <form className="edit-profile-form profile-form">
        <div className="section-title2">
          <h5>Employment Information:</h5>
        </div>
        <div className="experiences-row">
          <div className="row">
            <div className="col-lg-12">
              <div className="info-title">
                <h6>Add Your Experiences</h6>
                <div className="dash" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-inner mb-25">
                <label htmlFor="companies">Company Name*</label>
                <div className="input-area">
                  <input
                    type="text"
                    id="companies"
                    name="companies"
                    placeholder="Egenslab"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    style={{
                      border: "1px solid lightgrey",
                      padding: "10px 10px",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-inner mb-25">
                <label htmlFor="desigation">Industry*</label>
                <div className="input-area">
                  <select
                    className="select1"
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid lightgrey",
                    }}
                    onChange={(e) => setIndustrySelected(e.target.value)}
                  >
                    {industryList.map((i) => (
                      <option
                        value={i.id}
                        key={i.id}
                        selected={i.id == industrySelected ? true : false}
                      >
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-inner mb-25">
                <label htmlFor="companies">Designation*</label>
                <div className="input-area">
                  <input
                    type="text"
                    id="companies"
                    name="companies"
                    placeholder="Web Developer"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    style={{
                      border: "1px solid lightgrey",
                      padding: "10px 10px",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="add-remove-btn d-flex align-items-center justify-content-between mb-50">
        <div className="add-row">
          <button
            type="button"
            className="add-experiences-row"
          >
            Add New+{" "}
          </button>
        </div>
      </div> */}
        <div className="col-md-12">
          <div className="form-inner">
            <button
              className="primry-btn-2 lg-btn w-unset"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                axios
                  .post(
                    "https://backend.ogsmanpower.com/onboard/step_four_update",
                    {
                      companyName: companyName,
                      industrySelected: industrySelected,
                      designation: designation,
                      basic_info_id: basicId,
                    }
                  )
                  .then((response) => {
                    if (response.data.code == 1) {
                      alert("Data updated");
                    }
                  });
              }}
            >
              Update Change
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const AdminEditResume = () => {
  const { basicIdd } = useParams();

  const [userDesignation, setUserDesignation] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();

  const { firstname, lastname } = useParams();
  const [stepNumber, setStepNumber] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const [dataLoading, setDataLoading] = useState(false);
  const [resumeDetails, setResumeDetails] = useState([]);

  // Basic Information States
  const [firstName, setFirstName] = useState(firstname);
  const [lastName, setLastName] = useState(lastname);
  const [jobPlace, setJobPlace] = useState("");
  const [designation, setDesignation] = useState("");
  const [experienceNo, setExperienceNo] = useState();
  const [qualification, setQualification] = useState([]);
  const [qualificationSelected, setQualificationSelected] = useState(1);
  const [userEmail, setUserEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [objective, setObjective] = useState("");
  const [image, setImage] = useState(null);
  const [profileName, setProfileName] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [basicId, setBasicId] = useState(0);

  const [partSelected, setPartSelected] = useState(1);

  const [oldNationality, setOldNationality] = useState("");
  const [oldCountry, setOldCountry] = useState("");
  const [phoneEdited, setPhoneEdited] = useState(false);
  const [newPhone, setNewPhone] = useState("");
  const [oldSalary, setOldSalary] = useState("");
  const [oldExpSalary, setOldExpSalary] = useState("");
  const [selectedNationality, setSelectedNationality] = useState("");

  //   useEffect(() => {
  //     axios
  //       .get("https://backend.ogsmanpower.com/static/seeker_details", {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         },
  //       })
  //       .then((response) => {
  //         if (
  //           response.data.code == 0 ||
  //           response.data.message == "Unauthorized"
  //         ) {
  //           navigate("/seekersignin");
  //         } else {
  //           setUserName(
  //             response.data.result[0].first_name +
  //               " " +
  //               response.data.result[0].last_name
  //           );
  //         }
  //       });
  //   }, []);

  useEffect(() => {
    axios
      .get(
        "https://backend.ogsmanpower.com/static/getResumeDetailsID/" + basicIdd
      )
      .then((response) => {
        console.log(response);
        setResumeDetails(response.data.result[0]);
        setFirstName(response.data.result[0].first_name);
        setLastName(response.data.result[0].last_name);
        setJobPlace(response.data.result[0].current_job_place);
        setDesignation(response.data.result[0].designation);
        setExperienceNo(response.data.result[0].years_experience);
        setUserEmail(response.data.result[0].email);
        setPhoneNumber(response.data.result[0].phone);
        setObjective(response.data.result[0].career_objective);
        setProfileName(response.data.result[0].profile_image);
        setBasicId(response.data.result[0].basic_info_id);
        setOldNationality(response.data.result[0].selectedNationality);
        setSelectedNationality(response.data.result[0].selectedNationality);
        setOldCountry(response.data.result[0].selectedCountry);
        setOldSalary(response.data.result[0].current_salary);
        setOldExpSalary(response.data.result[0].expected_salary);
        setSelectedCountry(response.data.result[0].setSelectedCountry);
      });
  }, []);

  const [nationalityList, setNationalityList] = useState([
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivian",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Ni-Vanuatu",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
  ]);

  const [currentSalary, setCurrentSalary] = useState("10,000 - 20,000");
  const [expSalary, setExpSalary] = useState("10,000 - 20,000");

  const handleImageChange = async (e) => {
    const newImage = e.target.files[0];
    setImage(newImage); // Update image in state
    const formData = new FormData();
    formData.append("image", newImage);
    console.log(e.target.files[0]);
    try {
      const res = await axios.post(
        "https://backend.ogsmanpower.com/static/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setProfileName(res.data.fileName);
    } catch (err) {
      alert(err);
    }
  };

  const [firstFieldsError, setFirstFieldsError] = useState(false);

  useEffect(() => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries")
      .then((response) => {
        setCountryList(response.data.data);
      });
  }, []);

  // Qualification Axios
  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/industries")
      .then((response) => {
        setQualification(response.data.result);
      });
  }, []);

  const fieldsError = () => {
    messageApi.open({
      type: "error",
      content: "All Fields Are Required",
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const decodedToken = jwtDecode(localStorage.getItem("accessToken"));
        const response = await axios.get(
          "https://backend.ogsmanpower.com/static/getdesignation/" +
            decodedToken.email
        );
        setUserDesignation(response.data.designation);
      } catch (error) {
        // Handle any errors that occur during the API request
        console.error(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const decodedToken = jwtDecode(localStorage.getItem("accessToken"));
        const response = await axios.get(
          "https://backend.ogsmanpower.com/static/getProfile/" +
            decodedToken.email
        );
        setUserProfile(response.data.profile_image);
      } catch (error) {
        // Handle any errors that occur during the API request
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const stepHandler = (e) => {
    setStepNumber(e);
  };
  return (
    <>
      {/* <Navigation /> */}
      {contextHolder}
      <div className="dashboard-area pt-120 mb-120">
        <div className="container">
          <div className="row g-lg-4 gy-5 mb-90">
            <div className="col-lg-3">
              <div className="dashboard-sidebar">
                <div className="single-widget mb-60">
                  <div className="dashboard-menu">
                    <ul>
                      <li>
                        <a className="active" href="/manage_seeker">
                          {"< Admin Panel"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div>
                <label>Select which part you want to edit:</label>
                <br />
                <select
                  className="mt-2"
                  style={{
                    width: "100%",
                    padding: "10px 5px",
                    border: "1px solid lightgrey",
                  }}
                  onChange={(e) => setPartSelected(e.target.value)}
                >
                  <option value={1}>Basic Information</option>
                  <option value={2}>Profile Information</option>
                  <option value={3}>Professional Information</option>
                  {/* <option value={4}>Educational Information</option> */}
                </select>
              </div>
              {partSelected == 1 ? (
                <form className="edit-profile-form profile-form  mb-60 mt-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="firstname1">First Name*</label>
                        <div className="input-area">
                          <input
                            type="text"
                            id="firstname1"
                            name="firstname"
                            placeholder="Mrs. Jacoline"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            style={{
                              border: "1px solid lightgrey",
                              padding: "10px 10px",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="lastname">Last Name*</label>
                        <div className="input-area">
                          <input
                            type="text"
                            id="lastname"
                            name="lastname"
                            placeholder="Frankly"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            style={{
                              border: "1px solid lightgrey",
                              padding: "10px 10px",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-lg-12 position-relative">
                      <input
                        id="file-input"
                        type="file"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          handleImageChange(e);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="jobplace">Current Job Place*</label>
                        <div className="input-area">
                          <input
                            type="text"
                            id="jobplace"
                            name="jobplace"
                            placeholder="Company Name"
                            value={jobPlace}
                            onChange={(e) => setJobPlace(e.target.value)}
                            style={{
                              border: "1px solid lightgrey",
                              padding: "10px 10px",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="desigation">Designation*</label>
                        <div className="input-area">
                          <input
                            type="text"
                            id="desigation"
                            name="desigation"
                            placeholder="UI/UX Engineer"
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                            style={{
                              border: "1px solid lightgrey",
                              padding: "10px 10px",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="experiences">
                          Year of Experiences*
                        </label>
                        <div className="input-area">
                          <input
                            type="text"
                            id="experiences"
                            name="experiences"
                            placeholder="3.5 Years"
                            required
                            value={experienceNo}
                            onChange={(e) => setExperienceNo(e.target.value)}
                            style={{
                              border: "1px solid lightgrey",
                              padding: "10px 10px",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label>Qualification*</label>
                        <div className="input-area">
                          <select
                            className="select1"
                            style={{
                              width: "100%",
                              padding: "10px",
                              border: "none",
                              border: "1px solid lightgrey",
                            }}
                            onChange={(e) =>
                              setQualificationSelected(e.target.value)
                            }
                          >
                            {qualification.map((q) => {
                              return (
                                <>
                                  <option key={q.id} value={q.id}>
                                    {q.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="email1">
                          Email* (Only editable from settings page)
                        </label>
                        <div className="input-area">
                          <input
                            disabled
                            type="text"
                            id="email1"
                            name="email"
                            placeholder="info@example.com"
                            value={userEmail}
                            style={{
                              border: "1px solid lightgrey",
                              padding: "10px 10px",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="phonenumber">Phone Number*</label>
                        <br />
                        <div className="input-area">
                          <PhoneInput
                            placeholder={phoneNumber}
                            value={newPhone}
                            onChange={setNewPhone}
                            style={{
                              border: "1px solid lightgrey",
                              padding: "10px 10px",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label>Country*</label>
                        <div className="input-area">
                          <select
                            className="select1"
                            style={{
                              width: "100%",
                              padding: "10px",
                              border: "1px solid lightgrey",
                            }}
                            onChange={(e) => setSelectedCountry(e.target.value)}
                          >
                            <option key={0} value={oldCountry} selected>
                              {oldCountry}
                            </option>
                            {countryList.map((q) => {
                              return (
                                <>
                                  <option key={q.iso2} value={q.country}>
                                    {q.country}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label>Nationality*</label>
                        <div className="input-area">
                          <select
                            className="select1"
                            style={{
                              width: "100%",
                              padding: "10px",
                              border: "1px solid lightgrey",
                            }}
                            onChange={(e) =>
                              setSelectedNationality(e.target.value)
                            }
                          >
                            <option
                              selected
                              key={oldNationality}
                              value={oldNationality}
                            >
                              {" "}
                              {oldNationality}{" "}
                            </option>
                            {nationalityList.map((q) => {
                              return (
                                <>
                                  <option key={q} value={q}>
                                    {q}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label>Current Salary* | Currently: {oldSalary}</label>
                        <div className="input-area">
                          <select
                            className="select1"
                            style={{
                              width: "100%",
                              padding: "10px",
                              border: "1px solid lightgrey",
                            }}
                            onChange={(e) => setCurrentSalary(e.target.value)}
                          >
                            <option value="10,000 - 20,000">
                              10,000 - 20,000
                            </option>
                            <option value="20,000 - 30,000">
                              20,000 - 30,000
                            </option>
                            <option value="30,000 - 40,000">
                              30,000 - 40,000
                            </option>
                            <option value="40,000 - 50,000">
                              40,000 - 50,000
                            </option>
                            <option value="50,000 - 60,000">
                              50,000 - 60,000
                            </option>
                            <option value="60,000 - 70,000">
                              60,000 - 70,000
                            </option>
                            <option value="70,000 - 80,000">
                              70,000 - 80,000
                            </option>
                            <option value="80,000 - 90,000">
                              80,000 - 90,000
                            </option>
                            <option value="90,000 - 100,000">
                              90,000 - 100,000
                            </option>
                            <option value="100,000+">100,000+</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label>
                          Expected Salary* | Currently: {oldExpSalary}{" "}
                        </label>
                        <div className="input-area">
                          <select
                            className="select1"
                            style={{
                              width: "100%",
                              padding: "10px",
                              border: "1px solid lightgrey",
                            }}
                            onChange={(e) => setExpSalary(e.target.value)}
                          >
                            <option value="10,000 - 20,000" selected>
                              {" "}
                              10,000 - 20,000{" "}
                            </option>
                            <option value="20,000 - 30,000">
                              20,000 - 30,000
                            </option>
                            <option value="30,000 - 40,000">
                              30,000 - 40,000
                            </option>
                            <option value="40,000 - 50,000">
                              40,000 - 50,000
                            </option>
                            <option value="50,000 - 60,000">
                              50,000 - 60,000
                            </option>
                            <option value="60,000 - 70,000">
                              60,000 - 70,000
                            </option>
                            <option value="70,000 - 80,000">
                              70,000 - 80,000
                            </option>
                            <option value="80,000 - 90,000">
                              80,000 - 90,000
                            </option>
                            <option value="90,000 - 100,000">
                              90,000 - 100,000
                            </option>
                            <option value="100,000+">100,000+</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-inner mb-50">
                        <label htmlFor="description">Career Objective*</label>
                        <textarea
                          name="description"
                          id="description"
                          placeholder="Something Write Yourself...."
                          defaultValue={""}
                          value={objective}
                          onChange={(e) => setObjective(e.target.value)}
                          style={{
                            border: "1px solid lightgrey",
                            padding: "10px 10px",
                            width: "100%",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <br />
                      {firstFieldsError ? (
                        <Alert message="All fields are required" type="error" />
                      ) : (
                        ""
                      )}
                      <br />
                      <div className="form-inner">
                        <button
                          disabled={dataLoading}
                          className="primry-btn-2 lg-btn w-unset"
                          onClick={(event) => {
                            event.preventDefault();
                            if (
                              firstName == "" ||
                              lastName == "" ||
                              jobPlace == "" ||
                              designation == "" ||
                              experienceNo == "" ||
                              qualification == "" ||
                              userEmail == "" ||
                              phoneNumber == "" ||
                              objective == "" ||
                              profileName == ""
                            ) {
                              setFirstFieldsError(true);
                              fieldsError();
                            } else {
                              setDataLoading(true);
                              axios
                                .post(
                                  "https://backend.ogsmanpower.com/update_onboard/step_one",
                                  {
                                    firstName: firstName,
                                    lastName: lastName,
                                    jobPlace: jobPlace,
                                    designation: designation,
                                    experienceNo: experienceNo,
                                    qualification: qualificationSelected,
                                    userEmail: userEmail,
                                    phoneNumber:
                                      newPhone == "" ? phoneNumber : newPhone,
                                    objective: objective,
                                    profile_image: profileName,
                                    selectedCountry: selectedCountry,
                                    selectedNationality: selectedNationality,
                                    currentSalary: currentSalary,
                                    expSalary: expSalary,
                                    basicId: basicId,
                                  }
                                )
                                .then((response) => {
                                  setDataLoading(false);
                                  if (response.data.code == 1) {
                                    setStepNumber(2);
                                    messageApi.open({
                                      type: "success",
                                      content: "Updated",
                                    });
                                    localStorage.setItem(
                                      "basic_id",
                                      response.data.basicInfoId
                                    );
                                  }
                                });
                            }
                          }}
                        >
                          {dataLoading ? "Loading Please Wait..." : "Next"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              ) : partSelected == 2 ? (
                <ProfileStep basicId={basicId} />
              ) : partSelected == 3 ? (
                <EmploymentStep basicId={basicId} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminEditResume;
