import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import image1 from "./google.png";

// Define your custom button component
const MyCustomButton = ({ onClick, children }) => {
    
  return (
    <button class="google-login-button py-0  d-flex align-items-center justify-content-center" style={{backgroundColor:"rgb(193, 190, 190)"}} onClick={onClick}>
      <img src={image1} style={iconStyle} alt="Google Icon" />
      {children}
    </button>
  );
};



const iconStyle = {
  width: "22px",
  marginLeft:"16px"

};

const GoogleLogin = (props) => {
  const API_URL = process.env.REACT_APP_BASE_URL;
  let navigate = useNavigate();

  const handleGoogleOAuth = async (name, email, given_name, id) => {
    try {
      
      const response = await fetch(`${API_URL}/signup_login_google`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          full_name: name,
          username: given_name,
          email: email,
          password: id,
        }),
      });

      const json = await response.json();

      if (json.status === "success") {
        // localStorage.setItem("token", json.token);
        localStorage.setItem("accessToken", json.token);
        //navigate("/seeker_portal");
        navigate(
          `/seekeronboard/${name}/${given_name}/${email}/123`
        );
        
      }
    } catch (error) {
        alert("something went wrong! try again")
        console.log(error);
    }
  };

  // Use the useGoogleLogin hook to handle Google OAuth login
  const login = useGoogleLogin({
  
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      try {
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });
        if (!userInfoResponse.ok) {
          throw new Error('Failed to fetch user information');
        }
  
        const data = await userInfoResponse.json();
        console.log(data);
  
        const { name, email, given_name, id } = data;
        await handleGoogleOAuth(name, email, given_name, id);
      } catch (error) {
        console.error('Error fetching or handling user information:', error);
      }
    },
    onFailure: (error) => {
      console.error('Login failed:', error);
    },
  });
  

  return (
    <MyCustomButton  onClick={login}>
      <span className='google-button py-2 px-2'> Continue with Google </span>
    </MyCustomButton>
  );
};

export default GoogleLogin;
