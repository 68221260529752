import React, { useState } from "react";
import Navigation from "../components/Navbar/Navbar";
import { useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import Footer from "../components/Footer";
const Contact = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Navigation />
      <div className="inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-content text-center">
                <h1>Contact</h1>
                <span />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Contact
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="office-location-area pt-120 mb-120">
        <div className="container">
          <div className="row mb-120">
            <div className="col-lg-12 mb-70">
              <div className="office-location"></div>
            </div>
            <div className="col-lg-12">
              <div className="office-location-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.997584090199!2d73.06897717632921!3d33.63130463983872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df94db951a9af1%3A0x23ca33751eb280e!2sOGS%20MANPOWER!5e0!3m2!1sen!2s!4v1687455566717!5m2!1sen!2s"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-support-area mb-120">
        <div className="container">
          <div className="row g-lg-4 gy-5">
            <div className="col-lg-6">
              <div className="contect-content">
                <h4>Need Any Help? Contact Us</h4>
                <p>
                  Alternatively you can also check for the Company email, phone
                  number and address in the official website. <br /> <br />
                  Address: Office No 5, 2nd Floor Noor Plaza, Near Utility
                  Store, Chandni Chowk, Asghar Mall Scheme, Rawalpindi, Punjab
                  46300, Pakistan
                </p>
                <div className="support">
                  <div className="icon">
                    <img
                      src="assets/images/icon/footer-support-icon.svg"
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h5>Support Line:</h5>
                    <a href="tel:+099-03573983465">+923005352636</a>
                  </div>
                </div>
                <div className="service-available">
                  <span>N:B</span>
                  <p>
                    Our Customer Service Available from 9 am to 6 pm (Monday to
                    Saturday){" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form form-wrapper">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="name">Your Name*</label>
                        <div className="input-area">
                          <img src="assets/images/icon/user-2.svg" alt="" />
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Mr. Jackson Mile"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="email">Email*</label>
                        <div className="input-area">
                          <img src="assets/images/icon/email-2.svg" alt="" />
                          <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="info@example.com"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="phonenumber">Phone*</label>
                        <div className="input-area">
                          <img src="assets/images/icon/phone-2.svg" alt="" />
                          <input
                            type="text"
                            id="phonenumber"
                            name="phonenumber"
                            placeholder="+880-17 *** *** **"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner mb-25">
                        <label htmlFor="jobplace">
                          Company Name (Optional)
                        </label>
                        <div className="input-area">
                          <img src="assets/images/icon/company-2.svg" alt="" />
                          <input
                            type="text"
                            id="jobplace"
                            name="jobplace"
                            placeholder="Company Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-inner mb-40">
                        <label htmlFor="description">Message</label>
                        <textarea
                          name="description"
                          id="description"
                          placeholder="Message..."
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-inner">
                        <button
                          className="primry-btn-2 lg-btn w-unset"
                          type="submit"
                          onClick={(e) =>
                            alert("Your message has been sent successfully!")
                          }
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
