import React, { useState } from "react";
import Navigation from "../../components/Navbar/Navbar";
import axios from "axios";
import CustomAlert from "../../components/Alerts/CustomAlert";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";

const ResetPassword = () => {
  const { role } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState([]);
  const [passwordShow, setPasswordShow] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Navigation />
      <div className="login-area pt-120 mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-wrapper">
                <div className="form-title">
                  <h3>Reset Password</h3>
                  <span />
                </div>
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-inner mb-25">
                        <label htmlFor="email">Email*</label>
                        <div className="input-area">
                          <img src="assets/images/icon/email-2.svg" alt="" />
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="info@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      {errorMessage.length > 0 ? (
                        <CustomAlert
                          type={errorMessage[0].type}
                          message={errorMessage[0].message}
                        />
                      ) : (
                        ""
                      )}
                      <br />
                      <br />
                      <div className="form-inner">
                        {/* <button
                          className="primry-btn-2"
                          type="submit"
                          onClick={(e) => {
                            if (email == "") {
                              setErrorMessage([
                                {
                                  type: "error",
                                  message: "All fields are required!",
                                },
                              ]);
                              setTimeout(() => {
                                setErrorMessage([]);
                              }, 3000);
                            } else {
                              axios
                                .post(
                                  "https://backend.ogsmanpower.com/resetpassword",
                                  {
                                    token: btoa(email),
                                    role: role,
                                  }
                                )
                                .then((response) => console.log(response));
                            }
                            e.preventDefault();
                          }}
                        >
                          Send Email
                        </button> */}
                        <button
                          className="primry-btn-2"
                          type="submit"
                          onClick={(e) => {
                            if (email === "") {
                              setErrorMessage([
                                {
                                  type: "error",
                                  message: "All fields are required!",
                                },
                              ]);
                              setTimeout(() => {
                                setErrorMessage([]);
                              }, 3000);
                            } else {
                              axios
                                .post(
                                  "https://backend.ogsmanpower.com/resetpassword",
                                  {
                                    token: btoa(email),
                                    role: role,
                                  }
                                )
                                .then((response) => {
                                  if (
                                    response.data.message ===
                                    "Email sent successfully"
                                  ) {
                                    setErrorMessage([
                                      {
                                        type: "success",
                                        message: "Email sent successfully!",
                                      },
                                    ]);
                                    setTimeout(() => {
                                      setErrorMessage([]);
                                    }, 3000);
                                  } else {
                                    setErrorMessage([
                                      {
                                        type: "error",
                                        message:
                                          response.data.message ||
                                          "An error occurred",
                                      },
                                    ]);
                                    setTimeout(() => {
                                      setErrorMessage([]);
                                    }, 3000);
                                  }
                                })
                                .catch((error) => {
                                  setErrorMessage([
                                    {
                                      type: "error",
                                      message:
                                        error.message || "An error occurred",
                                    },
                                  ]);
                                  setTimeout(() => {
                                    setErrorMessage([]);
                                  }, 3000);
                                });
                            }
                            e.preventDefault();
                          }}
                        >
                          Send Email
                        </button>
                      </div>
                    </div>
                    <h6>
                      Don’t have an account? <a href="/signup">Sign Up</a>{" "}
                      <br />
                      OR
                      <br />
                      Login as a company <a href="/companysignin">Sign In</a>
                    </h6>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
