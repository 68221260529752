import React, { useState } from "react";
import Navigation from "../../components/Navbar/Navbar";
import TrendingCategorySection from "./TrendingCategorySection";
import JobsSection from "./JobsSection";
import { useEffect } from "react";
import axios from "axios";
import Footer from "../../components/Footer";
import JPJobsSection from "./JPJobsSection";

const JPHomePage = () => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [catList, setCatList] = useState([]);
  const [catListF, setCatListF] = useState([]);
  const [selectedCat, setSelectedCat] = useState(0);
  const [customSearch, setCustomSearch] = useState(false);
  const [title, setTitle] = useState("Enter Title");

  const [filteredJobs, setFilteredJobs] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL +"/static/getCategories")
      .then((response) => setCatList(response.data.result));
    axios
      .get(process.env.REACT_APP_BASE_URL +"/static/getrows/category")
      .then((response) => {
        if (response.data.code == 1) {
          setCatListF(response.data.result);
        }
      });
  }, []);

  useEffect(() => {
    if (customSearch) {
      axios
        .post(
          process.env.REACT_APP_BASE_URL +`/static/customSearch/${
            title == "" ? "Enter Title" : title
          }/${selectedCat}`
        )
        .then((response) => {
          setFilteredJobs(response.data.result);
        });
    }
  }, [customSearch, title, selectedCat]);

  return (
    <>
      <Navigation />
      <div className="hero1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-content">
                <h1>夢の仕事を今すぐ見つけましょう</h1>
                <p>
                  <span>数千</span>の求人情報を閲覧して発見
                  使いやすい求人ポータルで次のキャリアの機会を見つけてください。
                </p>
                <div className="counter-area">
                  <div className="row g-lg-4 g-md-5 gy-5 justify-content-center">
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single">
                        <div className="counter-icon">
                          <img src="assets/images/icon/job2.svg" alt="image" />
                        </div>
                        <div className="coundown">
                          <p>ライブジョブ</p>
                          <div className="d-flex align-items-center gap-2">
                            <h3 className="odometer">20223</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single">
                        <div className="counter-icon">
                          <img
                            src="assets/images/icon/companies.svg"
                            alt="image"
                          />
                        </div>
                        <div className="coundown">
                          <p>企業</p>
                          <div className="d-flex align-items-center gap-2">
                            <h3 className="odometer">804</h3>
                            <span>+</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single">
                        <div className="counter-icon">
                          <img
                            src="assets/images/icon/candidates.svg"
                            alt="image"
                          />
                        </div>
                        <div className="coundown">
                          <p>候補者</p>
                          <div className="d-flex align-items-center gap-2">
                            <h3 className="odometer">500</h3>
                            <span>+</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="counter-single">
                        <div className="counter-icon">
                          <img
                            src="assets/images/icon/new-jobs.svg"
                            alt="image"
                          />
                        </div>
                        <div className="coundown">
                          <p>新しい仕事</p>
                          <div className="d-flex align-items-center gap-2">
                            <h3 className="odometer">102</h3>
                            <span>+</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-search-area">
                  <form>
                    <div className="form-inner job-title">
                      <input
                        type="text"
                        placeholder="Job Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-inner category">
                      <select
                        className="select1"
                        style={{
                          border: "none",
                          padding: "10px",
                          width: "200px",
                          background: "transparent",
                          marginTop: "6px",
                        }}
                      >
                        <option value={0}>カテゴリー</option>
                        {catListF.map((c) => (
                          <option value={c.id}>{c.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-inner">
                      <button
                        type="submit"
                        className="primry-btn-2 "
                        onClick={(e) => {
                          e.preventDefault();
                          setCustomSearch(true);
                        }}
                      >
                        <img src="assets/images/icon/search-icon.svg" alt="" />{" "}
                        検索
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JPJobsSection
        type={1}
        customSearch={customSearch}
        filteredJobs={filteredJobs}
      />
      <TrendingCategorySection catList={catList} />

      <div className="home1-work-process mb-120">
        <div className="container">
          <div className="row mb-60">
            <div className="col-12 d-flex justify-content-center">
              <div className="section-title1 text-center">
                <h2>
                  JOBES Working <span>Process</span>
                </h2>
                <p>
                  To choose your trending job dream &amp; to make future bright.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-lg-4 gy-5 justify-content-center">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="single-work-process one text-center">
                <div className="icon">
                  <img src="assets/images/icon/account-create.svg" alt="" />
                </div>
                <div className="work-content">
                  <h5>
                    <a href="register.html">Account Create</a>
                  </h5>
                  <p>To create your account be confident &amp; safely.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="single-work-process two text-center">
                <div className="icon">
                  <img src="assets/images/icon/create-resume.svg" alt="" />
                </div>
                <div className="work-content">
                  <h5>
                    <a href="edit-profile.html">Create Resume</a>
                  </h5>
                  <p>To create your account be confident &amp; safely.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="single-work-process one text-center">
                <div className="icon">
                  <img src="assets/images/icon/job-find.svg" alt="" />
                </div>
                <div className="work-content">
                  <h5>
                    <a href="job-listing1.html">Find Jobs </a>
                  </h5>
                  <p>To create your account be confident &amp; safely.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="single-work-process two text-center">
                <div className="icon">
                  <img src="assets/images/icon/job-apply.svg" alt="" />
                </div>
                <div className="work-content">
                  <h5>
                    <a href="job-listing1.html">Apply Jobs</a>
                  </h5>
                  <p>To create your account be confident &amp; safely.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JPJobsSection type={2} />
      <div className="home1-review-area mb-120">
        <div className="container">
          <div className="row mb-60">
            <div className="col-12 d-flex flex-wrap align-items-end justify-content-md-between justify-content-start gap-3">
              <div className="section-title1">
                <h2>
                  Trusted User <span>Reviews</span>
                </h2>
                <p>
                  To choose your trending job dream &amp; to make future bright.
                </p>
              </div>
              <div className="swiper-btn1 d-flex align-items-center">
                <div className="left-btn prev-2">
                  <img src="assets/images/icon/explore-elliose.svg" alt="" />
                </div>
                <div className="right-btn next-2">
                  <img src="assets/images/icon/explore-elliose.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="swiper testimonial-slider1">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-card">
                    <div className="author-img">
                      <img
                        src="assets/images/bg/testimonial-author1.png"
                        alt=""
                      />
                      <div className="quat-icon">
                        <img src="assets/images/icon/quat-icon.svg" alt="" />
                      </div>
                    </div>
                    <div className="testimonial-content">
                      <div className="author-review-area">
                        <div className="author-area">
                          <h5>Jacoline Frangly</h5>
                          <span>React Developer</span>
                        </div>
                        <ul>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-half" />
                          </li>
                        </ul>
                      </div>
                      <p>
                        I would recommend the OGS MANPOWER portal to anyone
                        looking for reliable and efficient services. I had a
                        great experience with them and was impressed with their
                        professionalism and attention to detail. The team was
                        very helpful and responsive to all my queries and
                        concerns, making the whole process very smooth and
                        hassle-free. I highly recommend them for all your
                        recruitment needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-card">
                    <div className="author-img">
                      <img
                        src="assets/images/bg/testimonial-author2.png"
                        alt=""
                      />
                      <div className="quat-icon">
                        <img src="assets/images/icon/quat-icon.svg" alt="" />
                      </div>
                    </div>
                    <div className="testimonial-content">
                      <div className="author-review-area">
                        <div className="author-area">
                          <h5>Jordan Miycol</h5>
                          <span>UI/UX Engineer</span>
                        </div>
                        <ul>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-fill" />
                          </li>
                          <li>
                            <i className="bi bi-star-half" />
                          </li>
                        </ul>
                      </div>
                      <p>
                        On the other hand, we denounce with to the righteous
                        indignation and dislike the men who are so beguiled to
                        demoralized by the charms of pleasure.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JPHomePage;
