import React, { useEffect, useState } from "react";
import Navigation from "../../components/Navbar/Navbar";
import { Collapse } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Button, Modal, Input, Space, Select } from "antd";
import jwtDecode from "jwt-decode";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Pdffile from "./Pdffile";
import { PDFViewer } from "@react-pdf/renderer";

// import html2canvas from 'html2canvas';

const { Panel } = Collapse;

const Profilepage = () => {
  const [records, setRecords] = useState([]);
  let { id } = useParams();
  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/static/jobseeker_data/" + id
      );
      setRecords(response.data.result[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // createAndDownloadpdf = () =>{
  //   axios.post('/create-pdf', this.state)
  // }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // log company hiring request
  function logCompanyHiringRequest(company_id, seeker_id) {
    axios
      .post(process.env.REACT_APP_BASE_URL + "/static/add_hiring_request", {
        company: company_id,
        seeker: seeker_id,
      })
      .then((response) => {
        if (response.data.code === 0) {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Checking...");
  const token = localStorage.getItem("accessToken");
  let userId = 0;
  if (token) {
    const decodedToken = jwtDecode(token);
    userId = decodedToken.userId;
  } else {
    userId = 0;
  }

  useEffect(() => {
    const fetchRecord = async () => {
      try {
        if (records && records.record_id) {
          // Check if records and record_id are available
          console.log("seeker id is:", records.record_id);
          const recordExists = await checkRecord(userId, records.record_id);
          if (recordExists) {
            setButtonDisabled(true); // If record exists, disable button
            setButtonText("Request Sent");
          } else {
            setButtonDisabled(false); // If record doesn't exist, enable button
            setButtonText("Hire Me");
          }
        } else {
          console.log("Basic info not available yet");
        }
      } catch (error) {
        console.error("Error checking record:", error);
        setButtonDisabled(true);
        setButtonText("Error");
      }
    };
    fetchRecord();
  }, [records]);

  // check record in db
  const checkRecord = async (company_id, seeker_id) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/static/check_hiring_record",
        {
          company: company_id,
          seeker: seeker_id,
        }
      );

      if (response.data.code === 1) {
        console.log(company_id, seeker_id, "match");
        return true;
      } else {
        console.log(company_id, seeker_id, "Not match");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  // Function for sending mail to Admin
  function sendMail(company_id, seeker_id) {
    axios
      .post(process.env.REACT_APP_BASE_URL + "/email_seeker_request", {
        company: company_id,
        seeker: seeker_id,
      })
      .then((response) => {
        if (response.data.code === 0) {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  return (
    <>
      <Navigation />

      <div style={{ backgroundColor: "#eee" }}>
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <nav
                aria-label="breadcrumb"
                className="bg-light rounded-3 p-3 mb-4"
              >
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item active" aria-current="page">
                    <b>Jobseeker Profile</b>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="d-flex justify-content-end mb-2">
            {/* <PDFDownloadLink document={<Pdffile />} fileName="FORM">
              {({ loading }) =>
                loading ? (
                  <button type="button" className="btn btn-primary">
                    loading Document...
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary">
                    Download CV
                  </button>
                )
              }
            </PDFDownloadLink> */}
            <PDFDownloadLink
              document={<Pdffile record_id={records.record_id} />}
              fileName={"CV_OGSManpower_" + records.national_id}
            >
              {({ loading }) =>
                loading ? (
                  <button type="button" className="btn btn-primary">
                    loading Document...
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary">
                    Download CV
                  </button>
                )
              }
            </PDFDownloadLink>

            {/* <div style={{ width: "100%", height: "100vh" }}>
              <PDFViewer width="100%" height="100%">
                <Pdffile />
              </PDFViewer>
            </div> */}

            {/* <button type="button" className="btn btn-primary">
              Download CV
            </button> */}
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="card mb-8 " style={{ height: "345px" }}>
                <div className="card-body text-center">
                  <img
                    //src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                    src={
                      records.profile_image
                        ? process.env.REACT_APP_BASE_URL +
                          "/profile_images/" +
                          records.profile_image
                        : process.env.REACT_APP_BASE_URL +
                          "/profile_images/dummy_profile.jpg"
                    }
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <h5 className="my-3">
                    {records && records.first_name !== undefined
                      ? records.first_name
                      : ""}
                  </h5>
                  <p className="text-muted mb-1">
                    {records && records.designation ? records.designation : ""}
                  </p>
                  <p className="text-muted mb-2">
                    {records && records.country_name
                      ? records.country_name
                      : ""}
                  </p>
                  <div className="d-flex justify-content-center mb-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={buttonDisabled}
                      onClick={async (e) => {
                        e.preventDefault();
                        if (localStorage.getItem("accessToken")) {
                          console.log("ok");
                          const decodedToken = jwtDecode(
                            localStorage.getItem("accessToken")
                          );
                          if (decodedToken.userRole == 2) {
                            {
                              showModal();
                              await logCompanyHiringRequest(
                                decodedToken.userId,
                                records.record_id
                              );
                              setButtonText("Request Sent"); // Update button text
                              setButtonDisabled(true);
                              await sendMail(
                                decodedToken.userId,
                                records.record_id
                              );
                            }
                          } else {
                            alert("Login as company to Hire");
                          }
                        } else {
                          alert("Login as company to Hire");
                        }
                      }}
                    >
                      {buttonText}
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "10px",
                    }}
                  >
                    {/* <Button type="default" onClick={showModal}>
                    Enable Notifications
                  </Button> */}
                    <Modal
                      title=""
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <div>
                        <h4>Thanks for your interest.</h4>
                      </div>
                      <div>
                        <p>You will be contacted soon by OGS Manpower</p>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
            {/* {records && records.map((record, index) => (  */}
            <div className="col-lg-8">
              <div className="card mb-8" style={{ height: "100%" }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">First Name</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {records && records.first_name !== undefined
                          ? records.first_name
                          : ""}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Last Name</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {records && records.last_name ? records.last_name : ""}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {/* {records && records.email ? records.email : ""} */}
                        ******
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Phone</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {/* {records && records.phone ? records.phone : ""} */}
                        ******
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Mobile</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {/* {records && records.phone
                          ? records.phone
                          : "Not Available"} */}
                          ******
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Address</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {records && records.present_address
                          ? records.present_address
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Collapse className="mt-3" defaultActiveKey={["1"]}>
            <Panel header="Basic Information" key="1">
              <div class="card-body ">
                <div className="col-lg-30" style={{ border: "none" }}>
                  {/* <div className="card mb-4"> */}
                  <div className="card-body">
                    <div className="row">
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Career Objective</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.career_objective
                              ? records.career_objective
                              : ""}
                          </p>
                        </div>
                        <hr />
                        <div className="col-sm-3">
                          <p className="mb-0">Current Job Place</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.current_job_place
                              ? records.current_job_place
                              : ""}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Designation</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.designation
                              ? records.designation
                              : ""}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Year Experience</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.years_experience
                              ? records.years_experience
                              : ""}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Qualification</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.qualification
                              ? records.qualification
                              : ""}
                          </p>
                        </div>
                      </div>
                      <hr />
                      {/* <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Profile Image</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records.profile_image}
                          </p>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Current Salary</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.current_salary
                              ? records.current_salary
                              : ""}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Expected - Salary</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.expected_salary
                              ? records.expected_salary
                              : ""}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Category</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.category_name
                              ? records.category_name
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </Panel>
          </Collapse>

          <Collapse className="mt-3" defaultActiveKey={["1"]}>
            <Panel header="Education Information" key="1">
              <div class="card-body">
                <div className="row ">
                  <div className="col-md-20 ">
                    {/* <div className="card mb-4 mb-md-0"> */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Education Level</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.edu_level
                              ? records.edu_level
                              : ""}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Major</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.major ? records.major : "-"}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Institute</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.institute
                              ? records.institute
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">GPA</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records && records.gpa ? records.gpa : ""}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Period Starting</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records &&
                            records.period_starting &&
                            records.period_starting !== "Invalid date"
                              ? records.period_starting
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Period Ending</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records &&
                            records.period_ending &&
                            records.period_ending !== "Invalid date"
                              ? records.period_ending
                              : "-"}
                          </p>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-sm-3">
                          <p className="mb-0">Basic Info-ID</p>
                        </div>
                        <div className="col-sm-9">
                          <p className="text-muted mb-0">
                            {records.record_id}
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </Panel>
          </Collapse>

          <Collapse className="mt-3" defaultActiveKey={["1"]}>
            <Panel header="Employment Information" key="1">
              <div class="card-body">
                <div className="col-md-30 pt-4">
                  {/* <div className="card mb-4 mb-md-0 "> */}
                  <div className="card-body">
                    {/* <p className="mb-4 text-center">
                      <span className="text-primary font-italic me-1">
                        Employment
                      </span>{" "}
                      Information
                    </p> */}
                    {/* <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">ID</p>
                      </div>
                      <div className="col-sm-5">
                        <p className="text-muted mb-0">{records.id}</p>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">CompanyName</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {records && records.companyName
                            ? records.companyName
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">IndustrySelected</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {records && records.industry_name
                            ? records.industry_name
                            : ""}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Designation</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {records && records.designation
                            ? records.designation
                            : ""}
                        </p>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Basic-info-id</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {records.record_id}
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* </div> */}
              </div>
            </Panel>
          </Collapse>

          <Collapse className="mt-3" defaultActiveKey={["1"]}>
            <Panel header="Documents" key="1">
              <div className="card-body">
                <div className="container download" id="downloads">
                  <div className="row">
                    <div className="col-md-3 col-sm-6 col-xs-12">
                      <img
                        style={{ width: "150px", height: "150px" }}
                        src={
                          records.passport_photo
                            ? process.env.REACT_APP_BASE_URL +
                              "/profile_images/" +
                              records.passport_photo
                            : ""
                        }
                        class="rounded float-left"
                        alt="Passport"
                        title="Passport"
                      ></img>
                    </div>

                    <div class="col-md-3 col-sm-6 col-xs-12">
                      <img
                        style={{ width: "150px", height: "150px" }}
                        src={
                          records.id_photo
                            ? process.env.REACT_APP_BASE_URL +
                              "/profile_images/" +
                              records.id_photo
                            : ""
                        }
                        class="rounded float-left"
                        alt="CNIC"
                        title="CNIC"
                      ></img>
                      {/* <a href="LinkToImage">
            <img style={{width: "30%", height: "30px"}} alt="AltText" src="assets/images/products/img2.jpg" className="img-responsive"/>
        </a> */}
                    </div>

                    <div class="col-md-3 col-sm-6 col-xs-12">
                      {/* <img
                        style={{ width: "150px", height: "150px" }}
                        src="assets/images/products/img3.jpg"
                        class="rounded float-left"
                        alt="Driving Licence"
                        title="Driving Licence"
                      ></img> */}
                      <img
                        style={{ width: "150px", height: "150px" }}
                        src="assets/images/products/img3.jpg"
                        class="rounded float-left"
                        alt="Driving Licence"
                        title="Driving Licence"
                        onError={(e) => {
                          e.target.style.display = "none"; // Hide the image if it fails to load
                        }}
                      />
                    </div>

                    <div class="col-md-3 col-sm-6 col-xs-12">
                      <img
                        style={{ width: "150px", height: "150px" }}
                        src="assets/images/products/img4.jpg"
                        class="rounded float-left"
                        alt="..."
                        onError={(e) => {
                          e.target.style.display = "none"; // Hide the image if it fails to load
                        }}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default Profilepage;
