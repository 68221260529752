import logo from "./logo.svg";
import "./App.css";
import HomePage from "./pages/HomePage/homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Authenticate from "./pages/Authenticate/Authenticate";
import SeekerOnboard from "./pages/Onboard/SeekerOnboard";
import CompanyDashboard from "./pages/Dashboard/Company/CompanyDashboard";
import PostAJob from "./pages/Dashboard/Company/PostAJob";
import Company from "./pages/Dashboard/Company/Company";
import Login from "./pages/Authenticate/Login";
import SeekerDashboard from "./pages/Dashboard/Seeker/SeekerDashboard";
import SeekerMainDashboard from "./pages/Dashboard/Seeker/SeekerMainDashboard";
import JobDetails from "./pages/JobDetails";
import CompanyLogin from "./pages/Authenticate/CompanyLogin";
import JobList from "./pages/Dashboard/Company/JobList";
import JobInner from "./pages/Dashboard/Company/JobInner";
import CompanyProfilEdit from "./pages/Dashboard/Company/CompanyProfileEdit";
import CompanyPassword from "./pages/Dashboard/Company/CompanyPassword";
import JobListing from "./pages/HomePage/JobListing";
import JobSeekerListing from "./pages/HomePage/JobSeekerListing";
import AdminDashboard from "./pages/Dashboard/Admin/AdminDashboard";
import ManageSeeker from "./pages/Dashboard/Admin/ManageSeeker";
import EditResume from "./pages/Dashboard/Seeker/EditResume";
import SeekerSettings from "./pages/Dashboard/Seeker/SeekerSettings";
import EditAJob from "./pages/Dashboard/Company/EditAJob";
import JPHomePage from "./pages/HomePage/jpHomePage";
import ResetPassword from "./pages/Authenticate/ResetPassword";
import ManageEmployer from "./pages/Dashboard/Admin/ManageEmployer";
import AllJobs from "./pages/Dashboard/Admin/AllJobs";
import AdminEditJob from "./pages/Dashboard/Admin/AdminEditJob";
import ManageCategories from "./pages/Dashboard/Admin/ManageCategories";
import AdminLogin from "./pages/Authenticate/AdminLogin";
import NewPassword from "./pages/Authenticate/NewPassword";
import OtherSettings from "./pages/Dashboard/Admin/OtherSettings";
import Companies from "./pages/HomePage/Companies";
import Contact from "./pages/Contact.js";
import OldHome from "./pages/HomePage/OldHome";
import ArabicCV from "./pages/Dashboard/Company/ArabicCV";
import About from "./pages/HomePage/About";
import Privacy from "./pages/HomePage/Privacy";
import Terms from "./pages/HomePage/Terms";
import AdminEditResume from "./pages/Dashboard/Admin/AdminEditResume";
import Profilepage from "./pages/HomePage/Profilepage";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<OldHome />} />
        <Route path="/jp" element={<JPHomePage />} />
        <Route path="/jobs" element={<JobListing />} />
        <Route path="/jobseekers" element={<JobSeekerListing />} />
        <Route path="/signup/:typeParams" element={<Authenticate />} />
        <Route path="/seekersignin" element={<Login />} />
        <Route path="/companysignin" element={<CompanyLogin />} />
        <Route
          path="/seekeronboard/:firstname/:lastname/:email/:phone"
          element={<SeekerOnboard />}
        />
        <Route path="/seeker_profile/:id" element={<Profilepage />}/>
        <Route path="/company_portal" element={<Company />}>
          <Route path="" element={<CompanyDashboard />} />
          <Route path="postjob" element={<PostAJob />} />
          <Route path="job_list" element={<JobList />} />
          <Route path="job_inner/:jobId" element={<JobInner />} />
          <Route path="profile_edit" element={<CompanyProfilEdit />} />
          <Route path="company_password" element={<CompanyPassword />} />
          <Route path="edit_job/:jobId" element={<EditAJob />} />
        </Route>
        <Route path="/seeker_portal" element={<SeekerDashboard />}>
          <Route path="" element={<SeekerMainDashboard />} />
          <Route path="postjob" element={<PostAJob />} />
          <Route path="edit_resume" element={<EditResume />} />
          <Route path="settings" element={<SeekerSettings />} />
        </Route>
        <Route path="/job_details/:id" element={<JobDetails />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/admin_resume/:basicIdd" element={<AdminEditResume />} />
        <Route path="/manage_seeker" element={<ManageSeeker />} />
        <Route path="/manage_employer" element={<ManageEmployer />} />
        <Route path="/all_jobs" element={<AllJobs />} />
        <Route path="/admin_login" element={<AdminLogin />} />
        <Route path="/admin_edit/:jobId" element={<AdminEditJob />} />
        <Route path="/manage_categories" element={<ManageCategories />} />
        <Route path="/resetpassword/:role" element={<ResetPassword />} />
        <Route path="/changePassword/:atob/:role" element={<NewPassword />} />
        <Route path="/other" element={<OtherSettings />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/arab" element={<ArabicCV />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/toc" element={<Terms />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
