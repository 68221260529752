import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Navigation from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer";
const Companies = () => {
  const [companiesList, setCompaniesList] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/company_login")
      .then((response) => {
        setCompaniesList(response.data.result);
      });
  }, []);
  return (
    <>
      <Navigation />
      <div className="home1-category pt-120 mb-120">
        <div className="container">
          <div className="row mb-60">
            <div className="col-12 d-flex flex-wrap align-items-end justify-content-md-between justify-content-start gap-3">
              <div className="section-title1">
                <h2>
                  Companies registered with <span>OGS MANPOWER</span>
                </h2>
                <p>
                  Choose your trending job dream &amp; to make future bright.
                </p>
              </div>
              <div className="explore-btn"></div>
            </div>
            <div>
              <input
                style={{
                  width: "100%",
                  padding: "10px 20px",
                  border: "1px solid lightgrey",
                }}
                className="mt-2"
                placeholder="Search Companies"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-3 cf justify-content-center">
            {companiesList.map((c, index) => {
              if (
                c.company_name.toLowerCase().includes(search.toLowerCase()) ||
                search == ""
              ) {
                return (
                  <div className="col" key={index}>
                    <div className="single-category">
                      <div className="category-content">
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            backgroundImage: `url(https://backend.ogsmanpower.com/profile_images/${c.company_logo})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            borderRadius: "100%",
                          }}
                          className="mb-2"
                        ></div>
                        <h5>
                          <a href="">{c.company_name}</a>
                          <p style={{ fontSize: "12px" }}>
                            Created By: {c.first_name + " " + c.last_name}
                          </p>
                        </h5>
                        {/* <p>
                    Job Available: <span>{c.num_jobs}</span>
                  </p> */}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Companies;
