import React from "react";
import DashboardNavbar from "../../../components/Navbar/DashboardNavbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { CKEditor } from "ckeditor4-react";
import CustomAlert from "../../../components/Alerts/CustomAlert";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "./custom_select.css"; // Import a custom CSS file for styling
const PostAJob = () => {
  const [industrySelected, setIndustrySelected] = useState(1);
  const [industryList, setIndustryList] = useState([]);

  const [jobTitle, setJobTitle] = useState("");
  const [positions, setPositions] = useState(1);
  const [salary, setSalary] = useState("10,000 - 20,000");
  const [jobType, setJobType] = useState(1);
  const [jobLocation, setJobLocation] = useState(1);
  const [description, setDescription] = useState("");
  const [minExperience, setMinExperience] = useState("");

  const [postJobLoading, setPostJobLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [jobPosted, setJobPosted] = useState(false);
  const [timer, setTimer] = useState(5);

  const [additionalQuestions, setAdditionalQuestions] = useState([]);

  const [genderSelected, setGenderSelected] = useState("na");

  const [selectedCurrency, setSelectedCurrency] = useState("AED");

  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);

  useEffect(() => {
    axios
      .get(
        "https://backend.ogsmanpower.com/static/subcategory/" + industrySelected
      )
      .then((response) => setSubCategory(response.data.result));
  }, [industrySelected]);

  const [currencyList, setCurrencyList] = useState([
    { cc: "AED", symbol: "\u062f.\u0625;", name: "UAE dirham" },
    { cc: "AFN", symbol: "Afs", name: "Afghan afghani" },
    { cc: "ALL", symbol: "L", name: "Albanian lek" },
    { cc: "AMD", symbol: "AMD", name: "Armenian dram" },
    { cc: "ANG", symbol: "NA\u0192", name: "Netherlands Antillean gulden" },
    { cc: "AOA", symbol: "Kz", name: "Angolan kwanza" },
    { cc: "ARS", symbol: "$", name: "Argentine peso" },
    { cc: "AUD", symbol: "$", name: "Australian dollar" },
    { cc: "AWG", symbol: "\u0192", name: "Aruban florin" },
    { cc: "AZN", symbol: "AZN", name: "Azerbaijani manat" },
    {
      cc: "BAM",
      symbol: "KM",
      name: "Bosnia and Herzegovina konvertibilna marka",
    },
    { cc: "BBD", symbol: "Bds$", name: "Barbadian dollar" },
    { cc: "BDT", symbol: "\u09f3", name: "Bangladeshi taka" },
    { cc: "BGN", symbol: "BGN", name: "Bulgarian lev" },
    { cc: "BHD", symbol: ".\u062f.\u0628", name: "Bahraini dinar" },
    { cc: "BIF", symbol: "FBu", name: "Burundi franc" },
    { cc: "BMD", symbol: "BD$", name: "Bermudian dollar" },
    { cc: "BND", symbol: "B$", name: "Brunei dollar" },
    { cc: "BOB", symbol: "Bs.", name: "Bolivian boliviano" },
    { cc: "BRL", symbol: "R$", name: "Brazilian real" },
    { cc: "BSD", symbol: "B$", name: "Bahamian dollar" },
    { cc: "BTN", symbol: "Nu.", name: "Bhutanese ngultrum" },
    { cc: "BWP", symbol: "P", name: "Botswana pula" },
    { cc: "BYR", symbol: "Br", name: "Belarusian ruble" },
    { cc: "BZD", symbol: "BZ$", name: "Belize dollar" },
    { cc: "CAD", symbol: "$", name: "Canadian dollar" },
    { cc: "CDF", symbol: "F", name: "Congolese franc" },
    { cc: "CHF", symbol: "Fr.", name: "Swiss franc" },
    { cc: "CLP", symbol: "$", name: "Chilean peso" },
    { cc: "CNY", symbol: "\u00a5", name: "Chinese/Yuan renminbi" },
    { cc: "COP", symbol: "Col$", name: "Colombian peso" },
    { cc: "CRC", symbol: "\u20a1", name: "Costa Rican colon" },
    { cc: "CUC", symbol: "$", name: "Cuban peso" },
    { cc: "CVE", symbol: "Esc", name: "Cape Verdean escudo" },
    { cc: "CZK", symbol: "K\u010d", name: "Czech koruna" },
    { cc: "DJF", symbol: "Fdj", name: "Djiboutian franc" },
    { cc: "DKK", symbol: "Kr", name: "Danish krone" },
    { cc: "DOP", symbol: "RD$", name: "Dominican peso" },
    { cc: "DZD", symbol: "\u062f.\u062c", name: "Algerian dinar" },
    { cc: "EEK", symbol: "KR", name: "Estonian kroon" },
    { cc: "EGP", symbol: "\u00a3", name: "Egyptian pound" },
    { cc: "ERN", symbol: "Nfa", name: "Eritrean nakfa" },
    { cc: "ETB", symbol: "Br", name: "Ethiopian birr" },
    { cc: "EUR", symbol: "\u20ac", name: "European Euro" },
    { cc: "FJD", symbol: "FJ$", name: "Fijian dollar" },
    { cc: "FKP", symbol: "\u00a3", name: "Falkland Islands pound" },
    { cc: "GBP", symbol: "\u00a3", name: "British pound" },
    { cc: "GEL", symbol: "GEL", name: "Georgian lari" },
    { cc: "GHS", symbol: "GH\u20b5", name: "Ghanaian cedi" },
    { cc: "GIP", symbol: "\u00a3", name: "Gibraltar pound" },
    { cc: "GMD", symbol: "D", name: "Gambian dalasi" },
    { cc: "GNF", symbol: "FG", name: "Guinean franc" },
    { cc: "GQE", symbol: "CFA", name: "Central African CFA franc" },
    { cc: "GTQ", symbol: "Q", name: "Guatemalan quetzal" },
    { cc: "GYD", symbol: "GY$", name: "Guyanese dollar" },
    { cc: "HKD", symbol: "HK$", name: "Hong Kong dollar" },
    { cc: "HNL", symbol: "L", name: "Honduran lempira" },
    { cc: "HRK", symbol: "kn", name: "Croatian kuna" },
    { cc: "HTG", symbol: "G", name: "Haitian gourde" },
    { cc: "HUF", symbol: "Ft", name: "Hungarian forint" },
    { cc: "IDR", symbol: "Rp", name: "Indonesian rupiah" },
    { cc: "ILS", symbol: "\u20aa", name: "Israeli new sheqel" },
    { cc: "INR", symbol: "\u20B9", name: "Indian rupee" },
    { cc: "IQD", symbol: "\u062f.\u0639", name: "Iraqi dinar" },
    { cc: "IRR", symbol: "IRR", name: "Iranian rial" },
    { cc: "ISK", symbol: "kr", name: "Icelandic kr\u00f3na" },
    { cc: "JMD", symbol: "J$", name: "Jamaican dollar" },
    { cc: "JOD", symbol: "JOD", name: "Jordanian dinar" },
    { cc: "JPY", symbol: "\u00a5", name: "Japanese yen" },
    { cc: "KES", symbol: "KSh", name: "Kenyan shilling" },
    { cc: "KGS", symbol: "\u0441\u043e\u043c", name: "Kyrgyzstani som" },
    { cc: "KHR", symbol: "\u17db", name: "Cambodian riel" },
    { cc: "KMF", symbol: "KMF", name: "Comorian franc" },
    { cc: "KPW", symbol: "W", name: "North Korean won" },
    { cc: "KRW", symbol: "W", name: "South Korean won" },
    { cc: "KWD", symbol: "KWD", name: "Kuwaiti dinar" },
    { cc: "KYD", symbol: "KY$", name: "Cayman Islands dollar" },
    { cc: "KZT", symbol: "T", name: "Kazakhstani tenge" },
    { cc: "LAK", symbol: "KN", name: "Lao kip" },
    { cc: "LBP", symbol: "\u00a3", name: "Lebanese lira" },
    { cc: "LKR", symbol: "Rs", name: "Sri Lankan rupee" },
    { cc: "LRD", symbol: "L$", name: "Liberian dollar" },
    { cc: "LSL", symbol: "M", name: "Lesotho loti" },
    { cc: "LTL", symbol: "Lt", name: "Lithuanian litas" },
    { cc: "LVL", symbol: "Ls", name: "Latvian lats" },
    { cc: "LYD", symbol: "LD", name: "Libyan dinar" },
    { cc: "MAD", symbol: "MAD", name: "Moroccan dirham" },
    { cc: "MDL", symbol: "MDL", name: "Moldovan leu" },
    { cc: "MGA", symbol: "FMG", name: "Malagasy ariary" },
    { cc: "MKD", symbol: "MKD", name: "Macedonian denar" },
    { cc: "MMK", symbol: "K", name: "Myanma kyat" },
    { cc: "MNT", symbol: "\u20ae", name: "Mongolian tugrik" },
    { cc: "MOP", symbol: "P", name: "Macanese pataca" },
    { cc: "MRO", symbol: "UM", name: "Mauritanian ouguiya" },
    { cc: "MUR", symbol: "Rs", name: "Mauritian rupee" },
    { cc: "MVR", symbol: "Rf", name: "Maldivian rufiyaa" },
    { cc: "MWK", symbol: "MK", name: "Malawian kwacha" },
    { cc: "MXN", symbol: "$", name: "Mexican peso" },
    { cc: "MYR", symbol: "RM", name: "Malaysian ringgit" },
    { cc: "MZM", symbol: "MTn", name: "Mozambican metical" },
    { cc: "NAD", symbol: "N$", name: "Namibian dollar" },
    { cc: "NGN", symbol: "\u20a6", name: "Nigerian naira" },
    { cc: "NIO", symbol: "C$", name: "Nicaraguan c\u00f3rdoba" },
    { cc: "NOK", symbol: "kr", name: "Norwegian krone" },
    { cc: "NPR", symbol: "NRs", name: "Nepalese rupee" },
    { cc: "NZD", symbol: "NZ$", name: "New Zealand dollar" },
    { cc: "OMR", symbol: "OMR", name: "Omani rial" },
    { cc: "PAB", symbol: "B./", name: "Panamanian balboa" },
    { cc: "PEN", symbol: "S/.", name: "Peruvian nuevo sol" },
    { cc: "PGK", symbol: "K", name: "Papua New Guinean kina" },
    { cc: "PHP", symbol: "\u20b1", name: "Philippine peso" },
    { cc: "PKR", symbol: "Rs.", name: "Pakistani rupee" },
    { cc: "PLN", symbol: "z\u0142", name: "Polish zloty" },
    { cc: "PYG", symbol: "\u20b2", name: "Paraguayan guarani" },
    { cc: "QAR", symbol: "QR", name: "Qatari riyal" },
    { cc: "RON", symbol: "L", name: "Romanian leu" },
    { cc: "RSD", symbol: "din.", name: "Serbian dinar" },
    { cc: "RUB", symbol: "R", name: "Russian ruble" },
    { cc: "SAR", symbol: "SR", name: "Saudi riyal" },
    { cc: "SBD", symbol: "SI$", name: "Solomon Islands dollar" },
    { cc: "SCR", symbol: "SR", name: "Seychellois rupee" },
    { cc: "SDG", symbol: "SDG", name: "Sudanese pound" },
    { cc: "SEK", symbol: "kr", name: "Swedish krona" },
    { cc: "SGD", symbol: "S$", name: "Singapore dollar" },
    { cc: "SHP", symbol: "\u00a3", name: "Saint Helena pound" },
    { cc: "SLL", symbol: "Le", name: "Sierra Leonean leone" },
    { cc: "SOS", symbol: "Sh.", name: "Somali shilling" },
    { cc: "SRD", symbol: "$", name: "Surinamese dollar" },
    { cc: "SYP", symbol: "LS", name: "Syrian pound" },
    { cc: "SZL", symbol: "E", name: "Swazi lilangeni" },
    { cc: "THB", symbol: "\u0e3f", name: "Thai baht" },
    { cc: "TJS", symbol: "TJS", name: "Tajikistani somoni" },
    { cc: "TMT", symbol: "m", name: "Turkmen manat" },
    { cc: "TND", symbol: "DT", name: "Tunisian dinar" },
    { cc: "TRY", symbol: "TRY", name: "Turkish new lira" },
    { cc: "TTD", symbol: "TT$", name: "Trinidad and Tobago dollar" },
    { cc: "TWD", symbol: "NT$", name: "New Taiwan dollar" },
    { cc: "TZS", symbol: "TZS", name: "Tanzanian shilling" },
    { cc: "UAH", symbol: "UAH", name: "Ukrainian hryvnia" },
    { cc: "UGX", symbol: "USh", name: "Ugandan shilling" },
    { cc: "USD", symbol: "US$", name: "United States dollar" },
    { cc: "UYU", symbol: "$U", name: "Uruguayan peso" },
    { cc: "UZS", symbol: "UZS", name: "Uzbekistani som" },
    { cc: "VEB", symbol: "Bs", name: "Venezuelan bolivar" },
    { cc: "VND", symbol: "\u20ab", name: "Vietnamese dong" },
    { cc: "VUV", symbol: "VT", name: "Vanuatu vatu" },
    { cc: "WST", symbol: "WS$", name: "Samoan tala" },
    { cc: "XAF", symbol: "CFA", name: "Central African CFA franc" },
    { cc: "XCD", symbol: "EC$", name: "East Caribbean dollar" },
    { cc: "XDR", symbol: "SDR", name: "Special Drawing Rights" },
    { cc: "XOF", symbol: "CFA", name: "West African CFA franc" },
    { cc: "XPF", symbol: "F", name: "CFP franc" },
    { cc: "YER", symbol: "YER", name: "Yemeni rial" },
    { cc: "ZAR", symbol: "R", name: "South African rand" },
    { cc: "ZMK", symbol: "ZK", name: "Zambian kwacha" },
    { cc: "ZWR", symbol: "Z$", name: "Zimbabwean dollar" },
  ]);

  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState("Afghanistan");
  useEffect(() => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries")
      .then((response) => {
        setCountryList(response.data.data);
      });
  }, []);

  const handleDuplicate = () => {
    const newForm = {
      label: "",
    };
    setAdditionalQuestions([...additionalQuestions, newForm]);
  };

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`https://backend.ogsmanpower.com/static/getrows/category`)
      .then((response) => {
        setIndustryList(response.data.result);
      });
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0 && jobPosted === true) {
        setTimer(timer - 1);
      } else {
        if (timer < 1) {
          navigate("/company_portal");
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer, jobPosted]);

  const customStyles = {
    control: (provided) => ({
      ...provided,

      background: "transparent",
      border: "none",
    }),
  };

  return (
    <>
      <>
        <div
          className="inner-banner"
          style={{ padding: "0px !important" }}
        ></div>
        <div
          className="job-post-area pt-120 mb-120"
          style={{ background: "#ffffff", marginTop: "30px !important" }}
        >
          <div className="container">
            {jobPosted ? (
              <>
                <CustomAlert
                  type="success"
                  message="Your job has been posted and is pending approval"
                />
                <br />
                <br />
                <center>
                  <h3>
                    Redirecting in <u>{timer}</u>
                  </h3>
                </center>
              </>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-wrapper">
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="section-title2">
                            <h5>Job Information:</h5>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="jobtitle">Job Title*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/company-2.svg"
                                alt=""
                              />
                              <input
                                type="text"
                                id="jobtitle"
                                name="jobtitle"
                                placeholder="Senior UI/UX Engineer"
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label>Job Category*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/category-2.svg"
                                alt=""
                              />
                              {/* <select
                                className="select1"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "none",
                                }}
                                onChange={(e) =>
                                  setIndustrySelected(e.target.value)
                                }
                              >
                                {industryList.map((i) => (
                                  <option value={i.id} key={i.id}>
                                    {i.name}
                                  </option>
                                ))}
                              </select> */}
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    height: "100%",
                                  }}
                                >
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    name="color"
                                    options={industryList.map((industry) => ({
                                      value: industry.id,
                                      label: industry.name,
                                    }))}
                                    onChange={(e) =>
                                      setIndustrySelected(e.value)
                                    }
                                    styles={customStyles}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="vacancies">Vacancies*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/user-2.svg"
                                alt=""
                              />
                              <input
                                type="number"
                                id="vacancies"
                                name="vacancies"
                                placeholder="07 Person"
                                value={positions}
                                onChange={(e) => setPositions(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label>Budget / Salary*</label>
                            <div className="input-area">
                              <select
                                className="select1"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "none",
                                }}
                                onChange={(e) => setSalary(e.target.value)}
                              >
                                <option value="10,000 - 20,000" selected>
                                  {" "}
                                  10,000 - 20,000{" "}
                                </option>
                                <option value="20,000 - 30,000">
                                  20,000 - 30,000
                                </option>
                                <option value="30,000 - 40,000">
                                  30,000 - 40,000
                                </option>
                                <option value="40,000 - 50,000">
                                  40,000 - 50,000
                                </option>
                                <option value="50,000 - 60,000">
                                  50,000 - 60,000
                                </option>
                                <option value="60,000 - 70,000">
                                  60,000 - 70,000
                                </option>
                                <option value="70,000 - 80,000">
                                  70,000 - 80,000
                                </option>
                                <option value="80,000 - 90,000">
                                  80,000 - 90,000
                                </option>
                                <option value="90,000 - 100,000">
                                  90,000 - 100,000
                                </option>
                                <option value="100,000+">100,000+</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label>Job Type*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/company-2.svg"
                                alt=""
                              />
                              <select
                                className="select1"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "none",
                                }}
                                onChange={(e) => setJobType(e.target.value)}
                              >
                                <option value={0}>Full Time</option>
                                <option value={1}>Part Time</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="experiences">
                              Experience Level*
                            </label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/email-2.svg"
                                alt=""
                              />
                              <input
                                type="text"
                                id="experiences"
                                name="experiences"
                                placeholder="3 years"
                                value={minExperience}
                                onChange={(e) => {
                                  setMinExperience(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner select-jobtag mb-25">
                            <label>Job Location*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/search-2.svg"
                                alt=""
                              />
                              <select
                                className="js-example-basic-multiple"
                                data-placeholder="Select an option"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "none",
                                }}
                                value={jobLocation}
                                onChange={(e) => setJobLocation(e.target.value)}
                              >
                                <option value={"On-site"}>On-site</option>
                                <option value="Remote">Remote</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="datepicker9">Deadline*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/calender2.svg"
                                alt=""
                              />
                              <input
                                type="date"
                                id="datepicker9"
                                name="deadline"
                                placeholder="MM/DD/YY"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="datepicker9">Currency*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/calender2.svg"
                                alt=""
                              />

                              <div style={{ width: "100%" }}>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  isSearchable={true}
                                  name="color"
                                  options={currencyList.map((q) => ({
                                    value: q.cc,
                                    label: q.name,
                                  }))}
                                  onChange={(e) => setSelectedCurrency(e.value)}
                                  styles={customStyles}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="datepicker9">Country *</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/calender2.svg"
                                alt=""
                              />
                              <div style={{ width: "100%" }}>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  isSearchable={true}
                                  name="color"
                                  options={countryList.map((q) => ({
                                    value: q.country,
                                    label: q.country,
                                  }))}
                                  onChange={(e) => setCountrySelected(e.value)}
                                  styles={customStyles}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="datepicker9">
                              Gender Preference *
                            </label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/calender2.svg"
                                alt=""
                              />
                              <select
                                className="select1"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "none",
                                }}
                                onChange={(e) =>
                                  setGenderSelected(e.target.value)
                                }
                              >
                                <option value={"na"} selected>
                                  {" "}
                                  No Preference
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="transgender">Transgender</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="datepicker9">Sub Category *</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/calender2.svg"
                                alt=""
                              />
                              <select
                                className="select1"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "none",
                                }}
                                onChange={(e) =>
                                  setSelectedSubCategory(e.target.value)
                                }
                              >
                                {subCategory.map((c) => (
                                  <option value={c.id}>{c.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-inner mb-30">
                            <label htmlFor="summernote1">
                              Job Description*
                            </label>
                            <CKEditor
                              data={description}
                              config={{
                                toolbar: "basic",
                                height: 300,
                              }}
                              onChange={(event) =>
                                setDescription(event.editor.getData())
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          {showError ? (
                            <CustomAlert type="error" message={errorMessage} />
                          ) : (
                            ""
                          )}
                          {additionalQuestions.map((e, index) => (
                            <div className="col-md-12">
                              <div className="form-inner mb-25">
                                <label htmlFor="jobtitle">Label*</label>
                                <div className="input-area">
                                  <input
                                    type="text"
                                    id="jobtitle"
                                    name="jobtitle"
                                    placeholder="Corona Certificate, Driving License"
                                    value={additionalQuestions[index].label}
                                    onChange={(e) => {
                                      const updatedFormData = [
                                        ...additionalQuestions,
                                      ];
                                      updatedFormData[index].label =
                                        e.target.value;
                                      setAdditionalQuestions(updatedFormData);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                            <div className="form-group two">
                              <button
                                type="button"
                                className=""
                                onClick={handleDuplicate}
                              >
                                Ask for additional files +
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-inner">
                            <button
                              className="primry-btn-2 lg-btn w-unset"
                              type="submit"
                              onClick={(e) => {
                                if (
                                  jobTitle == "" ||
                                  positions == "" ||
                                  salary == 0 ||
                                  description == "" ||
                                  minExperience == ""
                                ) {
                                  console.log("One or more fields are empty.");
                                  setErrorMessage("All fields are required");
                                  setShowError(true);
                                  setTimeout(() => {
                                    setShowError(false);
                                  }, 3000);
                                } else {
                                  axios
                                    .post(
                                      "https://backend.ogsmanpower.com/job/postjob",
                                      {
                                        industrySelected: industrySelected,
                                        jobTitle: jobTitle,
                                        positions: positions,
                                        salary: salary,
                                        jobType: jobType,
                                        jobLocation: jobLocation,
                                        description: description,
                                        minExperience: minExperience,
                                        selectedCurrency: selectedCurrency,
                                        countrySelected: countrySelected,
                                        genderSelected: genderSelected,

                                        selectedSubCategory:
                                          selectedSubCategory,
                                      },
                                      {
                                        headers: {
                                          Authorization: `Bearer ${localStorage.getItem(
                                            "accessToken"
                                          )}`,
                                        },
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.code == 0) {
                                        setErrorMessage(response.data.message);
                                        setShowError(true);
                                        setTimeout(() => {
                                          setShowError(false);
                                        }, 3000);
                                      } else {
                                        let jobId = response.data.jobId;
                                        if (additionalQuestions.length > 0) {
                                          for (
                                            let i = 0;
                                            i < additionalQuestions.length;
                                            i++
                                          ) {
                                            axios
                                              .post(
                                                `https://backend.ogsmanpower.com/static/addAdditional/${jobId}/${additionalQuestions[i].label}`
                                              )
                                              .then((response) => {});
                                            if (
                                              i ===
                                              additionalQuestions.length - 1
                                            ) {
                                              setJobPosted(true);
                                            }
                                          }
                                        } else {
                                          setJobPosted(true);
                                        }
                                        //
                                      }
                                    });
                                }
                                e.preventDefault();
                              }}
                            >
                              Post Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default PostAJob;
