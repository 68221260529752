import React from "react";

const TrendingCategorySection = ({ catList }) => {
  return (
    <div className="home1-category pt-120 mb-120">
      <div className="container">
        <div className="row mb-60">
          <div className="col-12 d-flex flex-wrap align-items-end justify-content-md-between justify-content-start gap-3">
            <div className="section-title1">
              <h2>
                Trending Jobseekers <span>Categories</span>
              </h2>
              <p>
                To choose the best resource matching your requirements.
              </p>
            </div>
            <div className="explore-btn">
            </div>
          </div>
        </div>
        <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-3 cf justify-content-center">
          {catList.length > 0
            ? catList.map((c, index) => (
                <div className="col" key={index}>
                  <div className="single-category">
                    <div className="category-top">
                      <div className="sl-no">
                        <h6>{index+1}</h6>
                      </div>
                    </div>
                    <div className="category-content">
                      <h5>
                        <a href="">{c.name}</a>
                      </h5>
                      <p>
                        Jobseekers Available: <span>{c.num_jobs}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default TrendingCategorySection;
