import React from "react";
import DashboardNavbar from "../../../components/Navbar/DashboardNavbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { CKEditor } from "ckeditor4-react";
import CustomAlert from "../../../components/Alerts/CustomAlert";
import { useNavigate, useParams } from "react-router-dom";

const AdminEditJob = () => {
  const { jobId } = useParams();

  const [industrySelected, setIndustrySelected] = useState(1);
  const [industryList, setIndustryList] = useState([]);

  const [jobTitle, setJobTitle] = useState("");
  const [positions, setPositions] = useState(1);
  const [salary, setSalary] = useState("10,000 - 20,000");
  const [jobType, setJobType] = useState(1);
  const [jobLocation, setJobLocation] = useState(1);
  const [description, setDescription] = useState("");
  const [minExperience, setMinExperience] = useState("");

  const [postJobLoading, setPostJobLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [jobPosted, setJobPosted] = useState(false);
  const [timer, setTimer] = useState(5);

  const [additionalQuestions, setAdditionalQuestions] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://backend.ogsmanpower.com/static/getfrom/jobs/job_id/" + jobId
      )
      .then((response) => {
        setJobTitle(response.data.result[0].job_title);
        setPositions(response.data.result[0].positions);
        setSalary(response.data.result[0].salary);
        setJobType(response.data.result[0].jobType);
        setMinExperience(response.data.result[0].min_experience);
        setJobLocation(response.data.result[0].job_locations);
        setDescription(response.data.result[0].description);
      });
  }, []);

  const handleDuplicate = () => {
    const newForm = {
      label: "",
    };
    setAdditionalQuestions([...additionalQuestions, newForm]);
  };

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`https://backend.ogsmanpower.com/static/getrows/category`)
      .then((response) => {
        setIndustryList(response.data.result);
      });
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0 && jobPosted === true) {
        setTimer(timer - 1);
      } else {
        if (timer < 1) {
          navigate("/admin");
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer, jobPosted]);
  return (
    <>
      <>
        <DashboardNavbar />
        <div className="inner-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-content text-center">
                  <h1>Edit Job</h1>
                  <span />
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="index.html">Go Back</a>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="job-post-area pt-120 mb-120"
          style={{ background: "#ffffff" }}
        >
          <div className="container">
            {jobPosted ? (
              <>
                <CustomAlert
                  type="success"
                  message="Your job has been posted and is pending approval"
                />
                <br />
                <br />
                <center>
                  <h3>
                    Redirecting in <u>{timer}</u>
                  </h3>
                </center>
              </>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-wrapper">
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="section-title2">
                            <h5>Job Information:</h5>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="jobtitle">Job Title*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/company-2.svg"
                                alt=""
                              />
                              <input
                                type="text"
                                id="jobtitle"
                                name="jobtitle"
                                placeholder="Senior UI/UX Engineer"
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label>Job Category*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/category-2.svg"
                                alt=""
                              />
                              <select
                                className="select1"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "none",
                                }}
                                onChange={(e) =>
                                  setIndustrySelected(e.target.value)
                                }
                              >
                                {industryList.map((i) => (
                                  <option value={i.id} key={i.id}>
                                    {i.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="vacancies">Vacancies*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/user-2.svg"
                                alt=""
                              />
                              <input
                                type="number"
                                id="vacancies"
                                name="vacancies"
                                placeholder="07 Person"
                                value={positions}
                                onChange={(e) => setPositions(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label>Budget / Salary*</label>
                            <div className="input-area">
                              <select
                                className="select1"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "none",
                                }}
                                onChange={(e) => setSalary(e.target.value)}
                              >
                                <option value="10,000 - 20,000" selected>
                                  {" "}
                                  10,000 - 20,000{" "}
                                </option>
                                <option value="20,000 - 30,000">
                                  20,000 - 30,000
                                </option>
                                <option value="30,000 - 40,000">
                                  30,000 - 40,000
                                </option>
                                <option value="40,000 - 50,000">
                                  40,000 - 50,000
                                </option>
                                <option value="50,000 - 60,000">
                                  50,000 - 60,000
                                </option>
                                <option value="60,000 - 70,000">
                                  60,000 - 70,000
                                </option>
                                <option value="70,000 - 80,000">
                                  70,000 - 80,000
                                </option>
                                <option value="80,000 - 90,000">
                                  80,000 - 90,000
                                </option>
                                <option value="90,000 - 100,000">
                                  90,000 - 100,000
                                </option>
                                <option value="100,000+">100,000+</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label>Job Type*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/company-2.svg"
                                alt=""
                              />
                              <select
                                className="select1"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "none",
                                }}
                                onChange={(e) => setJobType(e.target.value)}
                              >
                                <option value={0}>Full Time</option>
                                <option value={1}>Part Time</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="experiences">
                              Experience Level*
                            </label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/email-2.svg"
                                alt=""
                              />
                              <input
                                type="text"
                                id="experiences"
                                name="experiences"
                                placeholder="3 years"
                                value={minExperience}
                                onChange={(e) => {
                                  setMinExperience(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner select-jobtag mb-25">
                            <label>Job Location*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/search-2.svg"
                                alt=""
                              />
                              <select
                                className="js-example-basic-multiple"
                                data-placeholder="Select an option"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "none",
                                }}
                                value={jobLocation}
                                onChange={(e) => setJobLocation(e.target.value)}
                              >
                                <option value={"On-site"}>On-site</option>
                                <option value="Remote">Remote</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="datepicker9">Deadline*</label>
                            <div className="input-area">
                              <img
                                src="/assets/images/icon/calender2.svg"
                                alt=""
                              />
                              <input
                                type="text"
                                id="datepicker9"
                                name="deadline"
                                placeholder="MM/DD/YY"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-inner mb-30">
                            <label htmlFor="summernote1">
                              Job Description*
                            </label>
                            <CKEditor
                              data={description}
                              config={{
                                toolbar: "basic",
                                height: 300,
                              }}
                              onChange={(event) =>
                                setDescription(event.editor.getData())
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          {showError ? (
                            <CustomAlert type="error" message={errorMessage} />
                          ) : (
                            ""
                          )}
                          <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                            <div className="form-group two"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-inner">
                            <button
                              className="primry-btn-2 lg-btn w-unset"
                              type="submit"
                              onClick={(e) => {
                                if (
                                  jobTitle == "" ||
                                  positions == "" ||
                                  salary == 0 ||
                                  description == "" ||
                                  minExperience == ""
                                ) {
                                  console.log("One or more fields are empty.");
                                  setErrorMessage("All fields are required");
                                  setShowError(true);
                                  setTimeout(() => {
                                    setShowError(false);
                                  }, 3000);
                                } else {
                                  axios
                                    .post(
                                      "https://backend.ogsmanpower.com/job/updatepostjob",
                                      {
                                        industrySelected: industrySelected,
                                        jobTitle: jobTitle,
                                        positions: positions,
                                        salary: salary,
                                        jobType: jobType,
                                        jobLocation: jobLocation,
                                        description: description,
                                        minExperience: minExperience,
                                        jobId: jobId,
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.code == 0) {
                                        setErrorMessage(response.data.message);
                                        setShowError(true);
                                        setTimeout(() => {
                                          setShowError(false);
                                        }, 3000);
                                      } else {
                                        let jobId = response.data.jobId;
                                        setJobPosted(true);

                                        //
                                      }
                                    });
                                }
                                e.preventDefault();
                              }}
                            >
                              Post Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default AdminEditJob;
