import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
const JobsSectionCustom = ({
  type,
  title,
  cat,
  customSearch,
  filteredJobs,
  catList,
  country,
}) => {
  const [jobsList, setJobsList] = useState([]);
  const [iList, setiList] = useState({});
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   axios
  //     .get("https://backend.ogsmanpower.com/static/getrows/category")
  //     .then((response) => {
  //       const data = response.data.reduce((acc, category) => {
  //         acc[category.id] = category;
  //         return acc;
  //         setLoading(false);
  //       }, {});
  //       setiList(data);
  //     });

  // }, []);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL +"/static/getrows/category")
      .then((response) => {
        console.log(response);
        const data = response.data.reduce((acc, category) => {
          acc[category.id] = category;
          return acc;
        }, {});
        setiList(data);
        setLoading(false); // Move setLoading inside the then block after setiList
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Ensure loading state is updated in case of error
      });
  }, []);
  console.log(catList);
  return (
    <>
      {type == 1 ? (
        <>
          {customSearch === true ? (
            <div
              className="home1-featured-area mb-120"
              style={{ background: "white !important", marginTop: "-80px" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex flex-wrap align-items-end justify-content-md-between justify-content-start gap-3">
                    {/* <div className="section-title1">
                      <h2>
                        Latest <span>Recent</span> Jobs
                      </h2>
                      <p>
                        To choose your trending job dream &amp; to make future
                        bright.
                      </p>
                    </div> */}
                    {/* <div className="explore-btn">
                      <a href="/jobs">
                        Explore More{" "}
                        <span>
                          <img
                            src="assets/images/icon/explore-elliose.svg"
                            alt=""
                          />
                        </span>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="row g-4">
                  {filteredJobs.length > 0 &&
                    filteredJobs.map((j, index) => {

                          return (
                            <div className="col-xl-4 col-lg-6">
                              <div
                                className="feature-card"
                                style={{
                                  border: "2px solid #00a4e5",
                                }}
                              >
                                <div className="company-area">
                                  <div className="company-details">
                                    <div className="name-location">
                                      <h5>
                                        <a href={`/job_details/${j.job_id}`}>
                                          {j.job_title}
                                        </a>
                                      </h5>
                                      <p>
                                        {j.country + " | " + j.currency + " | "}
                                        {catList.find(
                                          (item) =>
                                            item.id === j.industrySelected
                                        )?.name || "Category Not Found"}
                                      </p>
                                    </div>
                                    <div className="bookmark">
                                      <i className="bi bi-bookmark" />
                                    </div>
                                  </div>
                                </div>
                                <div className="job-discription">
                                  <ul>
                                    <li>
                                      <img
                                        src="assets/images/icon/arrow2.svg"
                                        alt=""
                                      />
                                      <p>
                                        <span className="title">Salary:</span>{" "}
                                        {j.salary}
                                      </p>
                                    </li>
                                    <li>
                                      <img
                                        src="assets/images/icon/arrow2.svg"
                                        alt=""
                                      />
                                      <p>
                                        <span className="title">Vacancy:</span>{" "}
                                        <span>{j.positions}</span>
                                      </p>
                                    </li>
                                    <li>
                                      <img
                                        src="assets/images/icon/arrow2.svg"
                                        alt=""
                                      />
                                      <p>
                                        <span className="title">
                                          Job Location:
                                        </span>{" "}
                                        <span>
                                          {j.jobLocation == "1"
                                            ? "Office"
                                            : "Remote"}
                                        </span>
                                      </p>
                                    </li>
                                    <li>
                                      <img
                                        src="assets/images/icon/arrow2.svg"
                                        alt=""
                                      />
                                      <p>
                                        <span className="title">
                                          Min Experience:
                                        </span>{" "}
                                        <span> {j.min_experience}</span>
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                                <div className="job-type-apply">
                                  <div
                                    className="apply-btn"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                  >
                                    <a
                                      href={`/job_details/${j.job_id}`}
                                      style={{
                                        background: "#01aff3",
                                        width: "100%",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "white",
                                        padding: "20px 0px",
                                      }}
                                    >
                                      Apply Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                    })}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default JobsSectionCustom;
