import React, { useState, useEffect } from "react";
import axios from "axios";

const Footer = () => {
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL +"/static/getrows/social")
      .then((response) => {
        setFacebook(response.data.result[0].facebook);
        setInstagram(response.data.result[0].instagram);
        setLinkedIn(response.data.result[0].linkedin);
        setTwitter(response.data.result[0].twitter);
      });
  }, []);
  return (
    <footer className="footer1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-4 col-sm-6 mb--50 d-flex justify-content-sm-start justify-content-center">
            <div className="footer-widget">
              <div>
                <a
                  href={facebook}
                  className="text-white me-4"
                  style={{
                    background: "#50A9E4",
                    padding: "5px 10px",
                    borderRadius: "100%",
                  }}
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  href={twitter}
                  className="text-white me-4"
                  style={{
                    background: "#50A9E4",
                    padding: "5px 8px",
                    borderRadius: "100%",
                  }}
                >
                  <i className="fab fa-twitter" />
                </a>
                <a
                  href={instagram}
                  className="text-white me-4"
                  style={{
                    background: "#50A9E4",
                    padding: "5px 8px",
                    borderRadius: "100%",
                  }}
                >
                  <i className="fab fa-instagram" />
                </a>
                <a
                  href={linkedIn}
                  className="text-white me-4"
                  style={{
                    background: "#50A9E4",
                    padding: "5px 8px",
                    borderRadius: "100%",
                  }}
                >
                  <i className="fab fa-linkedin" />
                </a>
              </div>
              <br />
              <div className="widget-title">
                <h5>About Company</h5>
              </div>
              <div className="menu-container">
                <p style={{ color: "white" }}>
                  OGS MANPOWER is the ultimate candidate portal that connects job
                  seekers and employers worldwide. From entry-level to executive
                  roles, we offer a wide range of opportunities to help you
                  achieve your career goals.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb--50 d-flex justify-content-md-center justify-content-sm-end justify-content-center">
            <div className="footer-widget">
              <div className="widget-title">
                <h5>For Jobseeker</h5>
              </div>
              <div className="menu-container">
                <ul style={{ color: "white !important" }}>
                  <li style={{ color: "white" }}>
                    <a href="/signup/1">
                      Sign Up <i className="bx bx-up-arrow-alt" />
                    </a>
                  </li>
                  <li style={{ color: "white" }}>
                    <a href="/jobs">
                      Browse Jobs <i className="bx bx-up-arrow-alt" />
                    </a>
                  </li>
                  <li style={{ color: "white" }}>
                    <a href="/signup/1">
                      Create Resume <i className="bx bx-up-arrow-alt" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mb--50 d-flex justify-content-lg-center justify-content-md-end justify-content-sm-start justify-content-center">
            <div className="footer-widget">
              <div className="widget-title">
                <h5>For Employer</h5>
              </div>
              <div className="menu-container">
                <ul>
                  <li>
                    <a href="/companysignin">
                      Post A Job <i className="bx bx-up-arrow-alt" />
                    </a>
                  </li>
                  <li>
                    <a href="/signup/2">
                      Create An Account <i className="bx bx-up-arrow-alt" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-lg-end justify-content-md-center justify-content-sm-end justify-content-center">
            <div className="footer-widget four">
              <div className="widget-title">
                <h5>Contact Information:</h5>
              </div>
              <div className="app-list">
                <ul style={{ color: "white", paddingBottom: "10px" }}>
                  <li>Email: ceo@ogsmanpower.com</li>
                </ul>
                <ul style={{ color: "white", paddingBottom: "10px" }}>
                  <li>Phone: (051) 490 6572</li>
                </ul>
                <ul style={{ color: "white", paddingBottom: "10px" }}>
                  <li>
                    Address: Noor Plaza Chandni Chowk , Rawalpindi , Pakistan
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
