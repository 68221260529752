import React, { useRef, useState } from "react";
import DashboardNavbar from "../../../components/Navbar/DashboardNavbar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect } from "react";
import axios from "axios";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  BlobProvider,
} from "@react-pdf/renderer";
import { Button, Modal } from "antd";
import AdminMenu from "./AdminMenu";
import { Link } from "react-router-dom";
import { Checkbox } from "antd";
import { CKEditor } from "ckeditor4-react";

const NewsLetter = () => {
  const [seekerSelected, setSeekerSelected] = useState(0);
  const [employerSelected, setEmployerSelected] = useState(0);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const onChange = (e) => {
    if (e.target.checked === true) {
      setSeekerSelected(1);
    } else {
      setSeekerSelected(0);
    }
  };
  const onChangeEmployer = (e) => {
    if (e.target.checked === true) {
      setEmployerSelected(1);
    } else {
      setEmployerSelected(0);
    }
  };

  const sendEmails = async () => {
    await axios.post("https://backend.ogsmanpower.com/sendemail", {
      seeker: seekerSelected,
      employer: employerSelected,
      title: title,
      body: content,
    });

    alert("Sending Emails");
  };
  return (
    <div style={{ width: "100%" }}>
      <h3>Newsletter</h3>
      <hr />
      <label>Message Title:</label>
      <input
        style={{
          width: "100%",
          border: "1px solid lightgrey",
        }}
        onChange={(e) => setTitle(e.target.value)}
      />
      <label className="mt-3">Message Content:</label>
      <textarea
        style={{
          width: "100%",
          border: "1px solid lightgrey",
          height: "200px",
        }}
        onChange={(e) => setContent(e.target.value)}
      ></textarea>
      <label>Select your audience:</label>
      <div>
        <Checkbox onChange={onChange}>Seekers</Checkbox>
        <Checkbox onChange={onChangeEmployer}>Employers</Checkbox>
      </div>
      <button
        onClick={sendEmails}
        style={{
          width: "100px",
          background: "skyblue",
          color: "white",
          padding: "10px 20px",
        }}
        className="mt-4"
      >
        SEND
      </button>
    </div>
  );
};

const PageContent = () => {
  const [pageSelected, setPageSelected] = useState("about");
  const [description, setDescription] = useState("lol");
  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getContent/" + pageSelected)
      .then((response) => setDescription(response.data.message[0].text));
  }, [pageSelected]);
  return (
    <div style={{ width: "100%" }}>
      <h3>Manage Pages</h3>
      <hr />
      <label>Select Page:</label>
      <br />
      <select
        style={{ width: "100%", padding: "10px" }}
        onChange={(e) => setPageSelected(e.target.value)}
      >
        <option value={"about"}>About</option>
        <option value={"privacy"}>Privacy Policy</option>
        <option value={"toc"}>Terms & Conditions</option>
      </select>
      <br />
      <hr />
      <label>Content:</label>
      <br />
      <CKEditor
        config={{
          toolbar: "basic",
          height: 300,
        }}
        data={description}
        onChange={(event) => setDescription(event.editor.getData())}
      />
      <br />

      <hr />
      <label>Content:</label>
      <div
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      ></div>

      <hr />
      <button
        onClick={() => {
          axios
            .post("https://backend.ogsmanpower.com/static/addContent", {
              page: pageSelected,
              data: description,
            })
            .then((response) => alert(response.data.code));
        }}
      >
        Save
      </button>
    </div>
  );
};
const Metas = () => {
  const [pageSelected, setPageSelected] = useState("about");
  // const [description, setDescription] = useState("lol");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getMeta/" + pageSelected)
      .then((response) => {
        setTitle(response.data.message[0].title);
        setDescription(response.data.message[0].description);
        setKeywords(response.data.message[0].keywords);
      });
  }, [pageSelected]);
  return (
    <div style={{ width: "100%" }}>
      <h3>Manage Meta Settings</h3>
      <hr />
      <label>Select Page:</label>
      <br />
      <select
        style={{ width: "100%", padding: "10px" }}
        onChange={(e) => setPageSelected(e.target.value)}
      >
        <option value={"about"}>About</option>
        <option value={"home"}>Home</option>
        <option value={"privacy"}>Privacy Policy</option>
        <option value={"toc"}>Terms & Conditions</option>
      </select>
      <br />
      <hr />
      <div className="mb-3">
        <label>Meta Title</label>
        <input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            border: "1px solid lightgrey",
          }}
          placeholder="Meta Title"
        />
      </div>
      <div className="mb-3">
        <label>Meta Description</label>
        <input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            border: "1px solid lightgrey",
          }}
          placeholder="Meta Description"
        />
      </div>
      <div className="mb-3">
        <label>Meta Keywords</label>
        <input
          value={keywords}
          onChange={(e) => setKeywords(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            border: "1px solid lightgrey",
          }}
          placeholder="Meta Keywords"
        />
      </div>
      <button
        onClick={() => {
          axios
            .post("https://backend.ogsmanpower.com/static/addMeta", {
              page: pageSelected,
              title: title,
              description: description,
              keywords: keywords,
            })
            .then((response) => alert(response.data.code));
        }}
      >
        Save
      </button>
    </div>
  );
};

const SocialMedia = () => {
  const [pageSelected, setPageSelected] = useState("about");
  // const [description, setDescription] = useState("lol");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/social")
      .then((response) => {
        setFacebook(response.data.result[0].facebook);
        setInstagram(response.data.result[0].instagram);
        setLinkedIn(response.data.result[0].linkedin);
        setTwitter(response.data.result[0].twitter);
      });
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <h3>Manage Social Media Links</h3>
      <br />
      <hr />
      <div className="mb-3">
        <label>Facebook</label>
        <input
          value={facebook}
          onChange={(e) => setFacebook(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            border: "1px solid lightgrey",
          }}
          placeholder="Meta Title"
        />
      </div>
      <div className="mb-3">
        <label>Twitter</label>
        <input
          value={twitter}
          onChange={(e) => setTwitter(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            border: "1px solid lightgrey",
          }}
          placeholder="Meta Title"
        />
      </div>
      <div className="mb-3">
        <label>Instagram</label>
        <input
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            border: "1px solid lightgrey",
          }}
          placeholder="Meta Title"
        />
      </div>
      <div className="mb-3">
        <label>LinkedIn</label>
        <input
          value={linkedIn}
          onChange={(e) => setLinkedIn(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            border: "1px solid lightgrey",
          }}
          placeholder="Meta Title"
        />
      </div>
      <button
        onClick={() => {
          axios
            .post("https://backend.ogsmanpower.com/static/savesocial", {
              fb: facebook,
              insta: instagram,
              x: twitter,
              li: linkedIn,
            })
            .then((response) => alert(response.data.message));
        }}
        style={{ background: "lightblue", padding: "10px 30px" }}
      >
        Save
      </button>
    </div>
  );
};

const Categories = () => {
  const [search, setSearch] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [addCatName, setAddCatName] = useState("");
  const [loading, setLoading] = useState(false);
  const showModal2 = () => {
    setIsModalOpen(true);
  };
  const handleOk2 = async () => {
    await axios
      .post("https://backend.ogsmanpower.com/addCategory", {
        categoryName: addCatName,
      })
      .then((response) => {
        if (response.data.code == 1) {
          alert("Catgory Added!");
        } else {
          alert("An error occured!");
        }
      });
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    await axios
      .get(
        "https://backend.ogsmanpower.com/static/category/1/" +
          categoryId +
          "/" +
          categoryName
      )
      .then((response) => {
        if (response.data.code == 1) {
          setRefresh(!refresh);
          alert("Category Updated");
        } else {
          alert("An error occured");
        }
      });
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetch = async () => {
      await axios
        .get("https://backend.ogsmanpower.com/static/getrows/category")
        .then((response) => setCategoryList(response.data.result));
    };
    fetch();
  }, [refresh]);
  return (
    <div>
      <Modal
        title="Edit Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label>Category Name</label>
        <input
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          style={{
            width: "100%",
            border: "1px solid lightgrey",
            padding: "10px 20px",
          }}
        />
      </Modal>
      <h3>Categories</h3>
      <div className="row">
        <div className="col-md-10">
          <input
            style={{
              width: "100%",
              border: "1px solid lightgrey",
              padding: "10px 20px",
            }}
            placeholder="Search Categories"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <Button
            type="primary"
            onClick={showModal2}
            style={{ width: "100%", height: "45px" }}
          >
            Add Category
          </Button>
          <Modal
            title="Add Category"
            open={isModalOpen}
            onOk={handleOk2}
            onCancel={handleCancel2}
          >
            <label>Add Category:</label>
            <input
              style={{
                width: "100%",
                padding: "10px 20px",
                border: "1px solid lightgrey",
              }}
              placeholder="Category Name"
              onChange={(e) => setAddCatName(e.target.value)}
            />
          </Modal>
        </div>
      </div>
      <table className="eg-table table job-list-table mb-0">
        <thead>
          <tr>
            <th>Unique ID.</th>
            <th>Category Name</th>
            <th>Delete</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {categoryList.length > 0
            ? categoryList.map((c) => {
                if (
                  c.name.toLowerCase().includes(search.toLowerCase()) ||
                  search == ""
                ) {
                  return (
                    <tr>
                      <td>{c.id}</td>
                      <td>{c.name}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCategoryName(c.name);
                          setCategoryId(c.id);
                          axios
                            .get(
                              "https://backend.ogsmanpower.com/static/category/2/" +
                                categoryId +
                                "/" +
                                categoryName
                            )
                            .then((response) => {
                              if (response.data.code == 1) {
                                setRefresh(!refresh);
                                alert("Category Deleted");
                              } else {
                                alert("An error occured");
                              }
                            });
                        }}
                      >
                        Delete
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCategoryName(c.name);
                          setCategoryId(c.id);
                          showModal();
                        }}
                      >
                        Edit
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })
            : ""}
        </tbody>
      </table>
    </div>
  );
};
const Qualification = () => {
  const [search, setSearch] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [addCatName, setAddCatName] = useState("");
  const [loading, setLoading] = useState(false);
  const showModal2 = () => {
    setIsModalOpen(true);
  };
  const handleOk2 = async () => {
    await axios
      .post("https://backend.ogsmanpower.com/static/addQualification", {
        categoryName: addCatName,
      })
      .then((response) => {
        if (response.data.code == 1) {
          alert("Catgory Added!");
        } else {
          alert("An error occured!");
        }
      });
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    await axios
      .get(
        "https://backend.ogsmanpower.com/static/qualfication/1/" +
          categoryId +
          "/" +
          categoryName
      )
      .then((response) => {
        if (response.data.code == 1) {
          setRefresh(!refresh);
          alert("Category Updated");
        } else {
          alert("An error occured");
        }
      });
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetch = async () => {
      await axios
        .get("https://backend.ogsmanpower.com/static/getrows/industries")
        .then((response) => setCategoryList(response.data.result));
    };
    fetch();
  }, [refresh]);
  return (
    <div>
      <Modal
        title="Edit Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label>Category Name</label>
        <input
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          style={{
            width: "100%",
            border: "1px solid lightgrey",
            padding: "10px 20px",
          }}
        />
      </Modal>
      <h3>Categories</h3>
      <div className="row">
        <div className="col-md-10">
          <input
            style={{
              width: "100%",
              border: "1px solid lightgrey",
              padding: "10px 20px",
            }}
            placeholder="Search Categories"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <Button
            type="primary"
            onClick={showModal2}
            style={{ width: "100%", height: "45px" }}
          >
            Add Category
          </Button>
          <Modal
            title="Add Category"
            open={isModalOpen}
            onOk={handleOk2}
            onCancel={handleCancel2}
          >
            <label>Add Category:</label>
            <input
              style={{
                width: "100%",
                padding: "10px 20px",
                border: "1px solid lightgrey",
              }}
              placeholder="Category Name"
              onChange={(e) => setAddCatName(e.target.value)}
            />
          </Modal>
        </div>
      </div>
      <table className="eg-table table job-list-table mb-0">
        <thead>
          <tr>
            <th>Unique ID.</th>
            <th>Category Name</th>
            <th>Delete</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {categoryList.length > 0
            ? categoryList.map((c) => {
                if (
                  c.name.toLowerCase().includes(search.toLowerCase()) ||
                  search == ""
                ) {
                  return (
                    <tr>
                      <td>{c.id}</td>
                      <td>{c.name}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCategoryName(c.name);
                          setCategoryId(c.id);
                          axios
                            .get(
                              "https://backend.ogsmanpower.com/static/qualification/2/" +
                                categoryId +
                                "/" +
                                categoryName
                            )
                            .then((response) => {
                              if (response.data.code == 1) {
                                setRefresh(!refresh);
                                alert("Category Deleted");
                              } else {
                                alert("An error occured");
                              }
                            });
                        }}
                      >
                        Delete
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCategoryName(c.name);
                          setCategoryId(c.id);
                          showModal();
                        }}
                      >
                        Edit
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })
            : ""}
        </tbody>
      </table>
    </div>
  );
};
const OtherSettings = () => {
  const [pageSelected, setPageSelected] = useState(1);

  return (
    <>
      <DashboardNavbar />
      <div
        className="dashboard-area company-dashboard pt-120 mb-120"
        style={{ background: "#f8f8f8" }}
      >
        <div className="container" id="ju">
          <div className="row g-lg-4">
            <div className="col-lg-12 mb-20">
              <div className="dashboard-sidebar">
                <AdminMenu />
              </div>
            </div>
            <div className="col-lg-2">
              {/* <div
                style={{
                  width: "100%",
                  padding: "10px 10px",
                  border: "1px solid lightgrey",
                  cursor: "pointer",
                  background: pageSelected == 1 ? "lightgrey" : "white",
                }}
                onClick={() => {
                  setPageSelected(1);
                }}
              >
                <Link to="">Site Settings</Link>
              </div> */}
              <div
                style={{
                  width: "100%",
                  padding: "10px 10px",
                  border: " 1px solid lightgrey",
                  cursor: "pointer",
                  background: pageSelected == 2 ? "lightgrey" : "white",
                }}
                onClick={() => {
                  setPageSelected(2);
                }}
              >
                <Link to="">Newsletters</Link>
              </div>
              <div
                style={{
                  width: "100%",
                  padding: "10px 10px",
                  border: " 1px solid lightgrey",
                  borderTop: "none",
                  cursor: "pointer",
                  background: pageSelected == 3 ? "lightgrey" : "white",
                }}
                onClick={() => {
                  setPageSelected(3);
                }}
              >
                <Link to="">Categories</Link>
              </div>
              <div
                style={{
                  width: "100%",
                  padding: "10px 10px",
                  border: " 1px solid lightgrey",
                  cursor: "pointer",
                  background: pageSelected == 4 ? "lightgrey" : "white",
                }}
                onClick={() => {
                  setPageSelected(4);
                }}
              >
                <Link to="">Pages</Link>
              </div>
              <div
                style={{
                  width: "100%",
                  padding: "10px 10px",
                  border: " 1px solid lightgrey",
                  cursor: "pointer",
                  background: pageSelected == 5 ? "lightgrey" : "white",
                }}
                onClick={() => {
                  setPageSelected(5);
                }}
              >
                <Link to="">Meta Settings</Link>
              </div>
              <div
                style={{
                  width: "100%",
                  padding: "10px 10px",
                  border: " 1px solid lightgrey",
                  cursor: "pointer",
                  background: pageSelected == 6 ? "lightgrey" : "white",
                }}
                onClick={() => {
                  setPageSelected(6);
                }}
              >
                <Link to="">Qualification</Link>
              </div>
              <div
                style={{
                  width: "100%",
                  padding: "10px 10px",
                  border: " 1px solid lightgrey",
                  cursor: "pointer",
                  background: pageSelected == 7 ? "lightgrey" : "white",
                }}
                onClick={() => {
                  setPageSelected(7);
                }}
              >
                <Link to="">Social Media</Link>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="dashboard-inner">
                {pageSelected == 1 ? (
                  ""
                ) : pageSelected == 2 ? (
                  <NewsLetter />
                ) : pageSelected == 3 ? (
                  <Categories />
                ) : pageSelected == 4 ? (
                  <PageContent />
                ) : pageSelected == 5 ? (
                  <Metas />
                ) : pageSelected == 6 ? (
                  <Qualification />
                ) : pageSelected == 7 ? (
                  <SocialMedia />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default OtherSettings;
