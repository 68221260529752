import React, { useEffect, useState } from "react";
import Navigation from "../../components/Navbar/Navbar";
import axios from "axios";
import Footer from "../../components/Footer";
import { Button, Modal, Input, Space, Select } from "antd";

const JobListing = () => {
  const [jobList, setJobList] = useState([]);

  const [catList, setCatList] = useState([]);
  const [selectedSalary, setSelectedSalary] = useState("none");
  const [vacanciesSelected, setVacanciesSelected] = useState(0);

  const [selectedCat, setSelectedCat] = useState(0);

  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState("none");

  const [experienceSelected, setExperienceSelected] = useState(0);
  const [genderSelected, setGenderSelected] = useState("na");

  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);

  useEffect(() => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries")
      .then((response) => {
        setCountryList(response.data.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/getrows/category")
      .then((response) => setCatList(response.data.result));
  }, []);
  useEffect(() => {
    axios
      .get("https://backend.ogsmanpower.com/static/subcategory/" + selectedCat)
      .then((response) => setSubCategory(response.data.result));
  }, [selectedCat]);
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BASE_URL+`/static/jobs/${selectedCat}/${selectedSalary}/${vacanciesSelected}/${countrySelected}/${experienceSelected}/${genderSelected}`
      )
      .then((response) => setJobList(response.data.result));
  }, [
    selectedCat,
    selectedSalary,
    vacanciesSelected,
    countrySelected,
    experienceSelected,
    genderSelected,
  ]);

  const getFilteredDate = (e) => {
    let date = new Date(e);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleGender = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      <Navigation />
      <div className="inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-content text-center">
                <h1>Job Listing</h1>
                <span />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Job Listing{" "}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="job-listing-area pt-120 mb-120">
        <div className="container">
          <div className="row g-lg-4 gy-5">
            <div className="col-lg-4 order-lg-1 order-2">
              <div className="job-sidebar">
                <div className="job-widget style-1 mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Job Category</h5>
                    <div className="checkbox-container">
                      <select
                        className="js-example-basic-multiple"
                        data-placeholder="Select an option"
                        style={{
                          width: "100%",
                          padding: "10px",
                          border: "1px solid lightgrey",
                        }}
                        onChange={(e) => setSelectedCat(e.target.value)}
                      >
                        {catList.map((c) => (
                          <option value={c.id}>{c.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="job-widget style-1 mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Job Sub Category</h5>
                    <div className="checkbox-container">
                      <select
                        className="js-example-basic-multiple"
                        data-placeholder="Select an option"
                        style={{
                          width: "100%",
                          padding: "10px",
                          border: "1px solid lightgrey",
                        }}
                        onChange={(e) => setSelectedSubCategory(e.target.value)}
                      >
                        {subCategory.map((c) => (
                          <option value={c.id}>{c.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="job-widget mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Salary</h5>
                    <div className="checkbox-container">
                      <div className="input-area">
                        <select
                          className="select1"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "1px solid lightgrey",
                          }}
                          onChange={(e) => setSelectedSalary(e.target.value)}
                        >
                          <option value="10,000 - 20,000" selected>
                            {" "}
                            10,000 - 20,000{" "}
                          </option>
                          <option value="20,000 - 30,000">
                            20,000 - 30,000
                          </option>
                          <option value="30,000 - 40,000">
                            30,000 - 40,000
                          </option>
                          <option value="40,000 - 50,000">
                            40,000 - 50,000
                          </option>
                          <option value="50,000 - 60,000">
                            50,000 - 60,000
                          </option>
                          <option value="60,000 - 70,000">
                            60,000 - 70,000
                          </option>
                          <option value="70,000 - 80,000">
                            70,000 - 80,000
                          </option>
                          <option value="80,000 - 90,000">
                            80,000 - 90,000
                          </option>
                          <option value="90,000 - 100,000">
                            90,000 - 100,000
                          </option>
                          <option value="100,000+">100,000+</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-widget mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Country</h5>
                    <div className="checkbox-container">
                      <div className="input-area">
                        <select
                          className="select1"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "1px solid lightgrey",
                          }}
                          onChange={(e) => setCountrySelected(e.target.value)}
                        >
                          <option value={"none"} selected>
                            Select Country
                          </option>
                          {countryList.map((q) => (
                            <option key={q.iso2} value={q.country}>
                              {q.country}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-widget mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Vacancies</h5>
                    <div className="checkbox-container">
                      <div className="input-area">
                        <select
                          className="select1"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "1px solid lightgrey",
                          }}
                          onChange={(e) => setVacanciesSelected(e.target.value)}
                        >
                          <option value="1" selected>
                            {" "}
                            1{" "}
                          </option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-widget mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Experience</h5>
                    <div className="checkbox-container">
                      <div className="input-area">
                        <select
                          className="select1"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "1px solid lightgrey",
                          }}
                          onChange={(e) =>
                            setExperienceSelected(e.target.value)
                          }
                        >
                          <option value="0" selected>
                            Select Experience
                          </option>
                          <option value="1"> 1 </option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10+</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-widget mb-20">
                  <div className="check-box-item">
                    <h5 className="job-widget-title">Gender</h5>
                    <div className="checkbox-container">
                      <div className="input-area">
                        <select
                          className="select1"
                          style={{
                            width: "100%",
                            padding: "10px",
                            border: "1px solid lightgrey",
                          }}
                          onChange={(e) => setGenderSelected(e.target.value)}
                        >
                          <option value={"na"} selected>
                            {" "}
                            No Preference
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="transgender">Transgender</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 order-lg-2 order-1">
              <div className="job-listing-wrrap">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                  }}
                >
                  <Button type="default" onClick={showModal}>
                    Enable Notifications
                  </Button>
                  <Modal
                    title="Enable Notifications"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <div>
                      <label>Enter query / skill / job title / industry:</label>
                      <Input placeholder="Web Developer" />
                    </div>
                    <br />
                    <div>
                      <label>Enter your email:</label>
                      <Input placeholder="myemail@gmail.com" />
                    </div>
                    <br />
                  </Modal>
                </div>
                <div className="row ">
                  {jobList.map((j) => (
                    <div className="col-lg-12 mb-30">
                      <div className="job-listing-card">
                        <div className="job-top">
                          <div className="job-list-content">
                            <div className="company-area">
                              <div className="company-details">
                                <div className="name-location">
                                  <h5>
                                    <a href={`/job_details/${j.job_id}`}>
                                      {j.job_title}
                                    </a>
                                  </h5>
                                  <p>
                                    {j.description
                                      .replace(/<[^>]+>/g, "")
                                      .substring(0, 60) + "..."}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="job-discription">
                              <ul>
                                <li>
                                  <p>
                                    <span className="title">Salary:</span>{" "}
                                    {j.salary}
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    <span className="title">Posted:</span>{" "}
                                    {getFilteredDate(j.posted_on)}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="bookmark">
                            <i className="bi bi-bookmark-fill" />
                          </div>
                        </div>
                        <div className="job-type-apply">
                          <div className="job-type">
                            <span className="light-blue">{j.jobLocation}</span>
                          </div>
                          <div className="apply-btn">
                            <a href={`/job_details/${j.job_id}`}>
                              <span>
                                <img
                                  src="assets/images/icon/apply-ellipse.svg"
                                  alt=""
                                />
                              </span>
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default JobListing;
