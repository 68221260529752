import React from "react";
import DashboardNavbar from "../../../components/Navbar/DashboardNavbar";
import { Outlet } from "react-router-dom";

const SeekerDashboard = () => {
    return (<>
        <DashboardNavbar />
        <Outlet />
    </>);
}

export default SeekerDashboard;