import React, { useState } from "react";
import Navigation from "../../components/Navbar/Navbar";
import axios from "axios";
import CustomAlert from "../../components/Alerts/CustomAlert";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";

const CompanyLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState([]);
  const [passwordShow, setPasswordShow] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Navigation />
      <div className="login-area pt-120 mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-wrapper">
                <div className="form-title">
                  <h3>Log In Here!</h3>
                  <span />
                </div>
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-inner mb-25">
                        <label htmlFor="email">Email*</label>
                        <div className="input-area">
                          <img src="assets/images/icon/email-2.svg" alt="" />
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="info@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-inner">
                        <label htmlFor="email">Password*</label>
                        <input
                          type={passwordShow ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <i
                          className="bi bi-eye-slash"
                          id="togglePassword"
                          onClick={() => setPasswordShow(!passwordShow)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                        <div className="form-group"></div>
                        <a href="/resetpassword/1" className="forgot-pass">
                          Forget Password?
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      {errorMessage.length > 0 ? (
                        <CustomAlert
                          type={errorMessage[0].type}
                          message={errorMessage[0].message}
                        />
                      ) : (
                        ""
                      )}
                      <br />
                      <br />
                      <div className="form-inner">
                        <button
                          className="primry-btn-2"
                          type="submit"
                          onClick={(e) => {
                            if (email == "" || password == "") {
                              setErrorMessage([
                                {
                                  type: "error",
                                  message: "All fields are required!",
                                },
                              ]);
                              setTimeout(() => {
                                setErrorMessage([]);
                              }, 3000);
                            } else {
                              axios
                                .post(
                                  "https://backend.ogsmanpower.com/company_login",
                                  {
                                    email: email,
                                    password: password,
                                  }
                                )
                                .then((response) => {
                                  if (response.data.code == 0) {
                                    setErrorMessage([
                                      {
                                        type: "error",
                                        message: response.data.message,
                                      },
                                    ]);
                                    setTimeout(() => {
                                      setErrorMessage([]);
                                    }, 3000);
                                  } else {
                                    setErrorMessage([
                                      {
                                        type: "success",
                                        message:
                                          "Logged in! Redirecting in 3 seconds...",
                                      },
                                    ]);
                                    setTimeout(() => {
                                      setErrorMessage([]);
                                      localStorage.setItem(
                                        "accessToken",
                                        response.data.token
                                      );
                                      navigate("/company_portal");
                                    }, 3000);
                                  }
                                });
                            }
                            e.preventDefault();
                          }}
                        >
                          LogIn
                        </button>
                      </div>
                    </div>
                    <h6>
                      Don’t have an account? <a href="register.html">Sign Up</a>
                    </h6>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CompanyLogin;
