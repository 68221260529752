import React, { useRef, useState } from "react";
import DashboardNavbar from "../../../components/Navbar/DashboardNavbar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect } from "react";
import axios from "axios";
import CairoFont from "./Cairo.ttf";
import logo from "../../../header1-logo.png";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  BlobProvider,
} from "@react-pdf/renderer";
import { Button, Modal } from "antd";
import AdminMenu from "./AdminMenu";
import { Link, useNavigate } from "react-router-dom";
import { WhatsappShareButton } from "react-share";

const ManageSeeker = () => {
  const [seekerList, setSeekerList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [applicants, setApplicants] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [seekerId, setSeekerId] = useState(0);

  const [nameSearch, setNameSearch] = useState("");
  const [countrySelected, setCountrySelected] = useState("none");
  const [countryList, setCountryList] = useState([]);
  const [nationalitySelected, setNationalitySelected] = useState("none");
  const [seekerProfile, setSeekerProfile] = useState("");
  const [experience, setExperience] = useState("none");

  const [jobsAppliedLength, setJobsAppliedLength] = useState(0);
  const [userSelected, setUserSelected] = useState(0);
  const [userViewed, setUserViewed] = useState(0);
  const [userShort, setUserShort] = useState(0);
  const [userRejected, setUserRejected] = useState(0);

  const [selectedBasicId, setSelectedBasicId] = useState(0);

  const [seekerAppliedList, setSeekerAppliedList] = useState([]);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [oldEmail, setOldEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [D2, setD2] = useState("");
  const [D3, setD3] = useState("");
  const [D4, setD4] = useState("");
  const [D5, setD5] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  const navigate = useNavigate();

  const [nationalityList, setNationalityList] = useState([
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivian",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Ni-Vanuatu",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
  ]);

  useEffect(() => {
    if (selectedBasicId != 0) {
      axios
        .get(
          `https://backend.ogsmanpower.com/job/adminjobApplicants/${selectedBasicId}`
        )
        .then((response) => {
          // setRefreshList(false);
          console.log(response, "response");
          setApplicants(response.data.result);
        });
    }
  }, [selectedBasicId]);

  useEffect(() => {
    axios
      .get(
        "https://backend.ogsmanpower.com/static/getrows/resume_basic_information"
      )
      .then((response) => {
        console.log(response);
      });
    if (selectedBasicId > 0) {
      // user data
      axios
        .get(
          "https://backend.ogsmanpower.com/static/seekeradmin/1/" +
            selectedBasicId
        )
        .then((response) => setJobsAppliedLength(response.data.result.length));
      axios
        .get(
          "https://backend.ogsmanpower.com/static/seekeradmin/2/" +
            selectedBasicId
        )
        .then((response) => setUserSelected(response.data.result.length));
      axios
        .get(
          "https://backend.ogsmanpower.com/static/seekeradmin/3/" +
            selectedBasicId
        )
        .then((response) => setUserViewed(response.data.result.length));
      axios
        .get(
          "https://backend.ogsmanpower.com/static/seekeradmin/4/" +
            selectedBasicId
        )
        .then((response) => {
          setUserRejected(response.data.result.length);
        });
      axios
        .get(
          "https://backend.ogsmanpower.com/static/seekerRecentApplied/" +
            selectedBasicId
        )
        .then((response) => {
          setSeekerAppliedList(response.data.result);
        });
    }
  }, [selectedBasicId]);

  useEffect(() => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries")
      .then((response) => {
        setCountryList(response.data.data);
      });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get("https://backend.ogsmanpower.com/static/getrows/resume_basic_information")
  //     .then((response) => {
  //       setSeekerList(response.data.result);
  //     });
  // }, []);

  const Quixote = ({ data }) => {
    const [educationArray, setEducationArray] = useState([]);
    useEffect(() => {
      axios
        .get(
          `https://backend.ogsmanpower.com/static/getEducation/${data.basic_info_id}`
        )
        .then((response) => {
          console.log(response);
          setEducationArray(response.data.result);
        });
    }, []);
    return (
      <Document>
        <Page>
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ width: "550px", height: "100%" }}>
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "150px",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src={`https://backend.ogsmanpower.com/profile_images/${data.profile_image}`}
                    style={{ width: "150px" }}
                  />
                  <View style={{ marginLeft: "10px" }}>
                    <Text style={{ fontSize: "40px" }}>
                      {data.first_name + " " + data.last_name}
                    </Text>
                    <Text>
                      {selectedJobTitle == null || selectedJobTitle == ""
                        ? data.designation
                        : selectedJobTitle}
                    </Text>
                  </View>
                </View>
              </View>
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: "16px" }}>Contact</Text>
                  <View
                    style={{
                      width: "85%",
                      height: "5px",
                      borderBottom: "2px dotted blue",
                      paddingTop: "8px",
                    }}
                  ></View>
                </View>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Phone: {data.phone}
                </Text>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Email Address: {data.email}
                </Text>
              </View>
              <View style={{ marginTop: "30px" }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: "16px" }}>Personal Information</Text>
                  <View
                    style={{
                      width: "70%",
                      height: "5px",
                      borderBottom: "2px dotted blue",
                      paddingTop: "8px",
                    }}
                  ></View>
                </View>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Father's Name: {data.father_name}
                </Text>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Nationality: {data.selectedNationality}
                </Text>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Date Of Birth: {data.dob}
                </Text>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Permanent Address: {data.perm_address}
                </Text>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Residential Address: {data.present_address}
                </Text>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Marital Status: {data.marital_status_name}
                </Text>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Religion: {data.religion_name}
                </Text>
              </View>
              <View style={{ marginTop: "30px" }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: "16px" }}>
                    Educational Information
                  </Text>
                  <View
                    style={{
                      width: "68%",
                      height: "5px",
                      borderBottom: "2px dotted blue",
                      paddingTop: "8px",
                    }}
                  ></View>
                </View>
                {educationArray.map((e) => (
                  <View>
                    <Text style={{ fontSize: "20px", marginTop: "10px" }}>
                      {e.institute}
                    </Text>
                    <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                      {e.period_starting} / {e.period_ending}
                    </Text>
                    <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                      Major: {e.major}
                    </Text>
                    <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                      GPA: {e.gpa}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={{ marginTop: "30px" }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: "16px" }}>Verification</Text>
                  <View
                    style={{
                      width: "85%",
                      height: "5px",
                      borderBottom: "2px dotted blue",
                      paddingTop: "8px",
                    }}
                  ></View>
                </View>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Government ID Number: {data.national_id}
                </Text>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Passport Number: {data.passport_number}
                </Text>
              </View>
              <View style={{ marginTop: "30px" }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: "16px" }}>Attachments</Text>
                  <View
                    style={{
                      width: "85%",
                      height: "5px",
                      borderBottom: "2px dotted blue",
                      paddingTop: "8px",
                    }}
                  ></View>
                </View>
                <Image
                  src={`https://backend.ogsmanpower.com/profile_images/${data.id_photo}`}
                  style={{ marginTop: "20px", width: "250px" }}
                />
                <Image
                  src={`https://backend.ogsmanpower.com/profile_images/${data.passport_photo}`}
                  style={{ marginTop: "20px", width: "250px" }}
                />
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  Font.register({
    family: "Oswald",
    src: CairoFont,
  });

  const QuixoteSecond = ({ data }) => {
    const [educationArray, setEducationArray] = useState([]);
    useEffect(() => {
      axios
        .get(
          `https://backend.ogsmanpower.com/static/getEducation/${data.basic_info_id}`
        )
        .then((response) => {
          console.log(response);
          setEducationArray(response.data.result);
        });
    }, []);
    return (
      <Document>
        <Page style={styles.body}>
          <View style={styles.topDiv}>
            <Image style={styles.logo} src={logo} />
            <View>
              <Text style={{ fontSize: "10px" }}>
                Email Address: {data.email}
              </Text>
              <Text style={{ fontSize: "10px" }}>
                WhatsApp / Phone No: {data.phone}
              </Text>
            </View>
          </View>
          <View style={styles.dividerBig}>
            <Text>APPLICATION FORM</Text>
          </View>
          <View style={styles.heroSection}>
            <Image
              style={styles.profileImage}
              src={`https://backend.ogsmanpower.com/profile_images/${data.profile_image}`}
            />
            <View>
              <View style={styles.labelContainer}>
                <Text style={styles.font}>Full Name</Text>
                <Text style={styles.font}>الاسم الكامل</Text>
              </View>
              <View style={styles.labelContainer}>
                <Text style={styles.answer}>
                  {data.first_name + " " + data.last_name}
                </Text>
                <Text style={styles.answer}>
                  {data.first_name + " " + data.last_name}
                </Text>
              </View>
              <View style={styles.labelContainerZyada}>
                <Text style={styles.font}>Occupation</Text>
                <Text style={styles.font}>إشغال</Text>
              </View>
              <View style={styles.labelContainer}>
                <Text style={styles.answer}>{selectedJobTitle}</Text>
                <Text style={styles.answer}>{selectedJobTitle}</Text>
              </View>
              <View style={styles.labelContainerZyada}>
                <Text style={styles.font}>Total Work Experience</Text>
                <Text style={styles.font}>إجمالي خبرة العمل</Text>
              </View>
              <View style={styles.labelContainer}>
                <Text style={styles.answer}>{data.years_experience}</Text>
                <Text style={styles.answer}>{data.years_experience}</Text>
              </View>
              <View style={styles.labelContainerZyada}>
                <Text style={styles.font}>Expected Salary</Text>
                <Text style={styles.font}>الراتب المتوقع</Text>
              </View>
              <View style={styles.labelContainer}>
                <Text style={styles.answer}>10,000</Text>
                <Text style={styles.answer}>10,000</Text>
              </View>
            </View>
          </View>

          <View
            style={{
              width: "100%",
              height: "30px",
              marginTop: "10px",

              fontFamily: "Oswald",
            }}
          >
            <View
              style={{
                width: "100%",
                height: "30px",
                border: "1px solid black",
                display: "flex",
                flexDirection: "row",
                fontFamily: "Oswald",
              }}
            >
              <View
                style={{
                  width: "50%",
                  height: "30px",
                  display: "flex",
                  fontSize: "13px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Oswald",
                }}
              >
                <Text>APPLICATION DETAILS</Text>
              </View>
              <View
                style={{
                  width: "50%",
                  height: "30px",
                  display: "flex",
                  fontSize: "13px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Oswald",
                }}
              >
                <Text>PASSPORT DETAILS</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingHorizontal: 35,
              fontFamily: "Oswald",
            }}
          >
            <View style={{ width: "45%" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "10px",
                  fontFamily: "Oswald",
                }}
              >
                <View style={styles.secheader}>
                  <View style={styles.labelContainer2}>
                    <Text style={styles.font}>Father's Name</Text>
                    <Text style={styles.font1}>{data.father_name}</Text>
                    <Text style={styles.font}>اسم الاب</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "10px",
                  fontFamily: "Oswald",
                }}
              >
                <View style={styles.secheader}>
                  <View style={styles.labelContainer2}>
                    <Text style={styles.font}>Nationality</Text>
                    <Text style={styles.font1}>{data.selectedNationality}</Text>
                    <Text style={styles.font}>جنسية</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "10px",
                  fontFamily: "Oswald",
                }}
              >
                <View style={styles.secheader}>
                  <View style={styles.labelContainer2}>
                    <Text style={styles.font}>Religion</Text>
                    <Text style={styles.font1}>{data.religion_name}</Text>
                    <Text style={styles.font}>دِين</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "10px",
                }}
              >
                <View style={styles.secheader}>
                  <View style={styles.labelContainer2}>
                    <Text style={styles.font}>Date Of Birth</Text>
                    <Text style={styles.font1}>{data.dob}</Text>
                    <Text style={styles.font}>تاريخ الميلاد</Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "10px",
                }}
              >
                <View style={styles.secheader}>
                  <View style={styles.labelContainer2}>
                    <Text style={styles.font}>Marital Status</Text>
                    <Text style={styles.font1}>{data.marital_status_name}</Text>
                    <Text style={styles.font}>الحالة الاجتماعية</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "10px",
                }}
              >
                <View style={styles.secheader}>
                  <View style={styles.labelContainer2}>
                    <Text style={styles.font}>Weight</Text>
                    <Text style={styles.font1}>{data.weight}</Text>
                    <Text style={styles.font}>وزن</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "10px",
                }}
              >
                <View style={styles.secheader}>
                  <View style={styles.labelContainer2}>
                    <Text style={styles.font}>Height</Text>
                    <Text style={styles.font1}>{data.height}</Text>
                    <Text style={styles.font}>ارتفاع</Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "10px",
                }}
              >
                <View style={styles.secheader}>
                  <View style={styles.labelContainer2}>
                    <Text style={styles.font}>Education</Text>
                    {educationArray.map((e) => (
                      <Text style={styles.font1}>{e.major}</Text>
                    ))}
                    <Text style={styles.font}>تعليم</Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                width: "50%",
                paddingLeft: "50px",
                fontFamily: "Oswald",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "10px",
                  fontFamily: "Oswald",
                }}
              >
                <View style={styles.secheader}>
                  <View style={styles.labelContainer2}>
                    <Text style={styles.font}>Passport No.</Text>
                    <Text style={styles.font1}>{data.passport_number}</Text>
                    <Text style={styles.font}>الاسم الكامل</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "10px",
                }}
              >
                <View style={styles.secheader}>
                  <View style={styles.labelContainer2}>
                    <Text style={styles.font}>Gov ID No.</Text>
                    <Text style={styles.font1}>{data.national_id}</Text>
                    <Text style={styles.font}>الهوية الحكومية</Text>
                  </View>
                </View>
              </View>
              <Image
                style={{ marginTop: "20px", width: "200px" }}
                src={`https://backend.ogsmanpower.com/profile_images/${data.passport_photo}`}
              />
              <Image
                style={{ marginTop: "20px", width: "200px" }}
                src={`https://backend.ogsmanpower.com/profile_images/${data.id_photo}`}
              />
            </View>
          </View>
        </Page>
      </Document>
    );
  };
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 25,
    },
    logo: {
      width: "150px",
      height: "60px",
    },
    font1: {
      fontFamily: "Oswald",
      color: "#B50202",
    },
    answer: {
      fontFamily: "Oswald",
      color: "#B50202",
    },
    topDiv: {
      width: "100%",
      height: "30px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingHorizontal: 35,
    },
    dividerBig: {
      width: "100%",
      height: "50px",
      backgroundColor: "#FEC750",
      marginTop: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "bolder",
    },
    heroSection: {
      paddingHorizontal: 35,
      marginTop: "20px",
      display: "flex",
      flexDirection: "row",
      fontFamily: "Oswald",
    },
    secheader: {
      marginTop: 5, // Adjust the marginTop value as needed
      display: "flex",
      flexDirection: "row",
      fontFamily: "Oswald",
    },
    secheader2: {
      marginTop: 0, // Adjust the marginTop value as needed
      display: "flex",
      flexDirection: "row",
      fontFamily: "Oswald",
    },
    labelContainer: {
      display: "flex",
      width: "330px",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "14px",
      fontWeight: "bold",
    },
    labelContainer2: {
      display: "flex",
      width: "330px",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "10px",
      fontWeight: "bold",
    },
    labelContainerZyada: {
      display: "flex",
      width: "330px",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "14px",
      fontWeight: "bold",
      marginTop: "10px",
    },
    profileImage: {
      width: "150px",
      height: "180px",
      marginRight: "20px",
    },
    arabicFont: {
      fontFamily: "Oswald",
    },
  });

  // FILTERS
  useEffect(() => {
    const fetch = async () => {
      await axios
        .get(
          "https://backend.ogsmanpower.com/static/filterSeeker/" +
            countrySelected +
            "/" +
            nationalitySelected +
            "/" +
            experience
        )
        .then((response) => {
          setSeekerList(response.data.result);
        });
    };

    fetch();
  }, [countrySelected, nationalitySelected, experience]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    axios
      .post("https://backend.ogsmanpower.com/static/adminUpdateSeeker", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        seekerId: seekerId,
        oldEmail: oldEmail,
      })
      .then((response) => alert(response.data.message));
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDownload = () => {
    window.location.href = "https://backend.ogsmanpower.com/download-csv";
  };

  return (
    <>
      <DashboardNavbar />
      <div
        className="dashboard-area company-dashboard pt-120 mb-120"
        style={{ background: "#f8f8f8" }}
      >
        <div className="container" id="ju">
          <div className="row g-lg-4">
            <div className="col-lg-12 mb-20">
              <div className="dashboard-sidebar">
                <AdminMenu />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="dashboard-inner">
                <div className="new-applied-job-area">
                  <h5>Manage Seekers:</h5>
                  <div className="row">
                    {/* <div className="col-md-3">
                      <input
                        style={{
                          width: "100%",
                          border: "1px solid lightgrey",
                          padding: "10px 20px",
                        }}
                        className="mb-2"
                        placeholder="Search by name"
                        onChange={(e) => setNameSearch(e.target.value)}
                      />
                    </div> */}
                    <div className="col-md-6">
                      <input
                        style={{
                          width: "100%",
                          border: "1px solid lightgrey",
                          padding: "10px 20px",
                        }}
                        className="mb-2"
                        placeholder="Search By Title"
                        onChange={(e) => setJobTitle(e.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <select
                        style={{
                          width: "100%",
                          height: "45px",
                          border: "1px solid lightgrey",
                          padding: "0px 10px",
                        }}
                        onChange={(e) => setCountrySelected(e.target.value)}
                      >
                        <option selected value={"none"}>
                          Filter By Country
                        </option>
                        {countryList.map((q) => (
                          <option key={q.iso2} value={q.country}>
                            {q.country}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <select
                        style={{
                          width: "100%",
                          height: "45px",
                          border: "1px solid lightgrey",
                          padding: "0px 10px",
                        }}
                        onChange={(e) => setNationalitySelected(e.target.value)}
                      >
                        <option selected value={"none"}>
                          Filter By Nationality
                        </option>
                        {nationalityList.map((q) => {
                          return (
                            <>
                              <option key={q} value={q}>
                                {q}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <select
                        style={{
                          width: "100%",
                          height: "45px",
                          border: "1px solid lightgrey",
                          padding: "0px 10px",
                        }}
                        onChange={(e) => setExperience(e.target.value)}
                      >
                        <option selected value={"none"}>
                          Filter By Experience
                        </option>
                        <option value="1">1</option>
                        <option value="1.5">1.5</option>
                        <option value="2">2</option>
                        <option value="2.5">2.5</option>
                        <option value="3">3</option>
                        <option value="3.5">3.5</option>
                        <option value="4">4</option>
                        <option value="4.5">4.5</option>
                        <option value="5">5</option>
                        <option value="5.5">5.5</option>
                        <option value="6">6</option>
                        <option value="6.5">6.5</option>
                        <option value="7">7</option>
                        <option value="7.5">7.5</option>
                        <option value="8">8</option>
                        <option value="8.5">8.5</option>
                        <option value="9">9</option>
                        <option value="9.5">9.5</option>
                        <option value="10+">10+</option>
                      </select>
                    </div>
                    <a
                      href="#"
                      target="_blank"
                      className="mb-2"
                      onClick={handleDownload}
                    >
                      Download CSV
                    </a>
                  </div>
                  <div className="table-wrapper2">
                    <Modal
                      title="Edit Seeker"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <div className="row">
                        <div>
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              backgroundImage: `url(https://backend.ogsmanpower.com/profile_images/${seekerProfile})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              borderRadius: "100%",
                            }}
                            className="mb-3"
                          ></div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="firstname1">First Name*</label>
                            <div className="input-area">
                              <input
                                type="text"
                                id="firstname1"
                                name="firstname"
                                placeholder="Mrs. Jacoline"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                style={{
                                  border: "1px solid lightgrey",
                                  padding: "10px",
                                  width: "100%",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="firstname1">Last Name*</label>
                            <div className="input-area">
                              <input
                                type="text"
                                id="firstname1"
                                name="firstname"
                                placeholder="Mrs. Jacoline"
                                style={{
                                  border: "1px solid lightgrey",
                                  padding: "10px",
                                  width: "100%",
                                }}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-inner mb-25">
                            <label htmlFor="firstname1">Email*</label>
                            <div className="input-area">
                              <input
                                type="text"
                                id="firstname1"
                                name="firstname"
                                placeholder="Mrs. Jacoline"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{
                                  border: "1px solid lightgrey",
                                  padding: "10px",
                                  width: "100%",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-inner mb-25">
                            <label htmlFor="firstname1">Password*</label>
                            <div className="input-area">
                              <input
                                type="password"
                                id="firstname1"
                                name="firstname"
                                placeholder="Mrs. Jacoline"
                                style={{
                                  border: "1px solid lightgrey",
                                  padding: "10px",
                                  width: "100%",
                                }}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                        </div> */}
                        <h6>Activity:</h6>
                        <div className="col-md-6">
                          <div>
                            <label>
                              <b>Jobs Applied: </b>
                            </label>
                            <p>{jobsAppliedLength}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <label>
                              <b>CV Shortlisted: </b>
                            </label>
                            <p>{userSelected}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <label>
                              <b>CV Viewed: </b>
                            </label>
                            <p>{userViewed}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <label>
                              <b>CV Rejected: </b>
                            </label>
                            <p>{userRejected}</p>
                          </div>
                        </div>
                        <h6>Recent Job Activity:</h6>
                        {seekerAppliedList.map((s) => (
                          <Link to={`/job_details/${s.job_id}`}>
                            Applied to <u>{s.job_title}</u> (Click to view job)
                          </Link>
                        ))}
                        <br />
                        <br />
                        <hr />
                        <div className="row">
                          <div className="col-6">
                            <label>
                              <input
                                type="checkbox"
                                value={designation}
                                style={{ marginRight: "10px" }}
                                checked={selectedJobTitle == designation}
                                onChange={() =>
                                  setSelectedJobTitle(designation)
                                }
                              />
                              {designation}
                            </label>
                          </div>
                          {D2 != "none" ? (
                            <div className="col-6">
                              <label>
                                <input
                                  type="checkbox"
                                  value={D2}
                                  style={{ marginRight: "10px" }}
                                  checked={selectedJobTitle == D2}
                                  onChange={() => setSelectedJobTitle(D2)}
                                />
                                {D2}
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                          {D3 != "none" ? (
                            <div className="col-6">
                              <label>
                                <input
                                  type="checkbox"
                                  value={D3}
                                  style={{ marginRight: "10px" }}
                                  checked={selectedJobTitle == D3}
                                  onChange={() => setSelectedJobTitle(D3)}
                                />
                                {D3}
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                          {D4 != "none" ? (
                            <div className="col-6">
                              <label>
                                <input
                                  type="checkbox"
                                  value={D4}
                                  checked={selectedJobTitle == D4}
                                  onChange={() => setSelectedJobTitle(D4)}
                                  style={{ marginRight: "10px" }}
                                />
                                {D4}
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                          {D5 != "none" ? (
                            <div className="col-6">
                              <label>
                                <input
                                  type="checkbox"
                                  value={D5}
                                  checked={selectedJobTitle == D5}
                                  onChange={() => setSelectedJobTitle(D5)}
                                  style={{ marginRight: "10px" }}
                                />
                                {D5}
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="form-inner mb-25">
                            <label htmlFor="firstname1"></label>
                            <hr />
                            {applicants.length > 0
                              ? applicants.map((rc) => {
                                  return (
                                    <BlobProvider
                                      document={<Quixote data={rc} />}
                                    >
                                      {({ blob, url, loading, error }) => {
                                        return (
                                          <>
                                            {loading && (
                                              <Text>Loading PDF...</Text>
                                            )}
                                            {error && (
                                              <Text>
                                                Error generating PDF:{" "}
                                                {error.toString()}
                                              </Text>
                                            )}

                                            {!loading && !error && (
                                              <>
                                                {" "}
                                                <a
                                                  href={url}
                                                  download="my-document.pdf"
                                                >
                                                  Download PDF (English)
                                                </a>
                                                <br />
                                                <WhatsappShareButton
                                                  url={url}
                                                  title={``}
                                                  style={{
                                                    background: "lightgreen",
                                                    padding: "10px",
                                                    marginTop: "10px",
                                                    marginLeft: "2px",
                                                  }}
                                                >
                                                  Share Via WhatsApp
                                                </WhatsappShareButton>
                                              </>
                                            )}
                                          </>
                                        );
                                      }}
                                    </BlobProvider>
                                  );
                                })
                              : ""}
                            <br />
                            <hr />
                            {applicants.length > 0
                              ? applicants.map((rc) => (
                                  <BlobProvider
                                    document={<QuixoteSecond data={rc} />}
                                  >
                                    {({ blob, url, loading, error }) => (
                                      <>
                                        {loading && <Text>Loading PDF...</Text>}
                                        {error && (
                                          <Text>
                                            Error generating PDF:{" "}
                                            {error.toString()}
                                          </Text>
                                        )}

                                        {!loading && !error && (
                                          <>
                                            {" "}
                                            <a
                                              href={url}
                                              download="my-document.pdf"
                                            >
                                              Download PDF (Arabic)
                                            </a>
                                            <br />
                                            <WhatsappShareButton
                                              url={url}
                                              title={``}
                                              style={{
                                                background: "lightgreen",
                                                padding: "10px",
                                                marginTop: "10px",
                                                marginLeft: "2px",
                                              }}
                                            >
                                              Share Via WhatsApp
                                            </WhatsappShareButton>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </BlobProvider>
                                ))
                              : ""}
                            <br />
                            <hr />
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="input-area">
                                  <button
                                    className="btn btn-outline-danger"
                                    style={{ width: "90%", height: "45px" }}
                                    onClick={() => {
                                      axios
                                        .post(
                                          "https://backend.ogsmanpower.com/static/adminDeleteSeeker",
                                          {
                                            email: email,
                                          }
                                        )
                                        .then((response) =>
                                          alert(response.data.message)
                                        );
                                      setIsModalOpen(false);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="input-area">
                                  <button
                                    className="btn btn-outline-success"
                                    style={{ width: "90%", height: "45px" }}
                                    onClick={() => {
                                      navigate(
                                        "/admin_resume/" + selectedBasicId
                                      );
                                    }}
                                  >
                                    Edit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <table className="eg-table table job-list-table mb-0">
                      <thead>
                        <tr>
                          <th>Seeker Id</th>
                          <th>Seeker Name</th>
                          <th>Seeker Email</th>
                          <th></th>
                          <th></th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {seekerList.map((j) => {
                          const fullName = j.first_name + " " + j.last_name;

                          if (
                            jobTitle === "" ||
                            j.designation
                              .toLowerCase()
                              .includes(jobTitle.toLowerCase())
                          ) {
                            return (
                              <tr key={j.basic_info_id}>
                                <td data-label="Seeker Id">
                                  <div className="job-content">
                                    <h5>
                                      <a href="">{j.basic_info_id}</a>
                                    </h5>
                                  </div>
                                </td>
                                <td data-label="Name">{fullName}</td>
                                <td data-label="Email">{j.email}</td>
                                <td data-label="Action">
                                  <ul className="action-btn-group2">
                                    <li>
                                      <a
                                        href="#"
                                        className="primry-btn-2 eg-btn sky--btn"
                                        onClick={() => {
                                          console.log(j);
                                          setFirstName(j.first_name);
                                          setLastName(j.last_name);
                                          setEmail(j.email);
                                          setOldEmail(j.email);
                                          setPassword(j.password);
                                          setPhone(j.phone);
                                          setSeekerId(j.id);
                                          setSeekerProfile(j.profile_image);
                                          setSelectedBasicId(j.basic_info_id);
                                          setD2(j.d_two);
                                          setD3(j.d_three);
                                          setD4(j.d_four);
                                          setD5(j.d_five);
                                          setDesignation(j.designation);
                                          showModal();
                                        }}
                                      >
                                        <svg
                                          width={12}
                                          height={12}
                                          viewBox="0 0 12 12"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M11.8798 1.19201C11.9563 1.26957 11.9993 1.37462 11.9993 1.48414C11.9993 1.59365 11.9563 1.6987 11.8798 1.77627L11.0253 2.64024L9.3868 0.98512L10.2413 0.121149C10.3181 0.0435774 10.4223 0 10.5309 0C10.6395 0 10.7437 0.0435774 10.8205 0.121149L11.8798 1.19118V1.19201ZM10.4461 3.22449L8.8076 1.56938L3.22607 7.20836C3.18098 7.2539 3.14704 7.30944 3.12694 7.37056L2.46745 9.36829C2.45549 9.40471 2.45379 9.44377 2.46254 9.48111C2.4713 9.51844 2.49016 9.55259 2.51702 9.57972C2.54388 9.60685 2.57768 9.62591 2.61464 9.63475C2.65161 9.64359 2.69028 9.64188 2.72633 9.62979L4.70399 8.96361C4.76442 8.94355 4.8194 8.90955 4.86456 8.8643L10.4461 3.22532V3.22449Z" />
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 10.7585C0 11.0878 0.12947 11.4035 0.359928 11.6363C0.590385 11.8691 0.902953 11.9999 1.22887 11.9999H10.2406C10.5665 11.9999 10.8791 11.8691 11.1095 11.6363C11.34 11.4035 11.4694 11.0878 11.4694 10.7585V5.79319C11.4694 5.68345 11.4263 5.5782 11.3495 5.50061C11.2727 5.42301 11.1685 5.37941 11.0598 5.37941C10.9512 5.37941 10.847 5.42301 10.7702 5.50061C10.6934 5.5782 10.6502 5.68345 10.6502 5.79319V10.7585C10.6502 10.8683 10.607 10.9735 10.5302 11.0511C10.4534 11.1287 10.3492 11.1723 10.2406 11.1723H1.22887C1.12023 11.1723 1.01604 11.1287 0.939222 11.0511C0.862403 10.9735 0.819246 10.8683 0.819246 10.7585V1.6554C0.819246 1.54566 0.862403 1.44041 0.939222 1.36281C1.01604 1.28522 1.12023 1.24162 1.22887 1.24162H6.55397C6.66261 1.24162 6.7668 1.19803 6.84362 1.12043C6.92044 1.04283 6.96359 0.937583 6.96359 0.827842C6.96359 0.718101 6.92044 0.612854 6.84362 0.535256C6.7668 0.457657 6.66261 0.414063 6.55397 0.414062H1.22887C0.902953 0.414063 0.590385 0.544846 0.359928 0.777642C0.12947 1.01044 0 1.32618 0 1.6554V10.7585Z"
                                          />
                                        </svg>
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default ManageSeeker;
