import React, { useState } from "react";
import Navigation from "../../components/Navbar/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button, message, Space, Alert } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,

    background: "transparent",
    border: "none",
  }),
};

const ProfileStep = ({ stepNumber, stepHandler }) => {
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [presentAddress, setPresentAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [maritalStatus, setMaritalStatus] = useState(1);
  const [gender, setGender] = useState(1);
  const [religion, setReligion] = useState(1);
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [passportNumber, setPassportNumber] = useState("");

  const [dataLoading, setDataLoading] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [maritalList, setMaritalList] = useState([]);

  const [idCardImage, setIdCardImage] = useState("");
  const [passportImage, setPassportImage] = useState("");

  const handleIdCardUpload = async (e) => {
    const newImage = e.target.files[0];
    const formData = new FormData();
    formData.append("image", newImage);
    console.log(e.target.files[0]);
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL+"/static/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIdCardImage(res.data.fileName);
    } catch (err) {
      alert(err);
    }
  };
  const handlePassportUpload = async (e) => {
    const newImage = e.target.files[0];
    const formData = new FormData();
    formData.append("image", newImage);
    console.log(e.target.files[0]);
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL+"/static/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setPassportImage(res.data.fileName);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL+"/static/getrows/genders")
      .then((response) => {
        setGenderList(response.data.result);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL+"/static/getrows/religions")
      .then((response) => {
        setReligionList(response.data.result);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL+"/static/getrows/marital_status")
      .then((response) => {
        setMaritalList(response.data.result);
      });
  }, []);

  return (
    <div
      className={`tab-pane fade show ${stepNumber == 2 ? "active" : ""}`}
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <form className="edit-profile-form profile-form">
        <div className="row">
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="fathername">Father’s Name*</label>
              <div className="input-area">
                <img src="/assets/images/icon/user-2.svg" alt="" />
                <input
                  type="text"
                  id="fathername"
                  name="fathername"
                  placeholder="Mr. Norman Frankly"
                  value={fatherName}
                  onChange={(e) => setFatherName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="mothername">Mother's Name*</label>
              <div className="input-area">
                <img src="/assets/images/icon/user-2.svg" alt="" />
                <input
                  type="text"
                  id="mothername"
                  name="mothername"
                  placeholder="Mrs. Marcoline Frankly"
                  value={motherName}
                  onChange={(e) => setMotherName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="datepicker5">Date of Birth*</label>
              <div className="input-area">
                <img src="/assets/images/icon/calender2.svg" alt="" />
                <input
                  type="date"
                  id="datepicker5"
                  name="datepicker5"
                  placeholder=""
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="nid">National Id*</label>
              <div className="input-area">
                <img src="/assets/images/icon/nid.svg" alt="" />
                <input
                  type="text"
                  id="nid"
                  name="nid "
                  placeholder="0988 *** *** *** **"
                  value={nationalId}
                  onChange={(e) => setNationalId(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <div className="salary-wrap">
                <label className="label" htmlFor="preAddress">
                  Present Address*
                </label>
                <div className="checkbox-container">
                  <ul></ul>
                </div>
              </div>
              <div className="input-area">
                <img src="/assets/images/icon/home-2.svg" alt="" />
                <input
                  type="text"
                  id="preAddress"
                  name="preAddress "
                  placeholder="Your Address Here..."
                  value={presentAddress}
                  onChange={(e) => setPresentAddress(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="perAddress">Permanent Address*</label>
              <div className="input-area">
                <img src="/assets/images/icon/home-2.svg" alt="" />
                <input
                  type="text"
                  id="perAddress"
                  name="perAddress"
                  placeholder="Your Address Here..."
                  value={permanentAddress}
                  onChange={(e) => setPermanentAddress(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label>Marital Status*</label>
              <div className="input-area">
                <select
                  className="select1"
                  style={{ width: "100%", padding: "10px", border: "none" }}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                >
                  {maritalList.map((m) => (
                    <>
                      <option key={m.id} value={m.id}>
                        {m.status}
                      </option>
                    </>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label>Gender*</label>
              <div className="input-area">
                <img src="/assets/images/icon/gender.svg" alt="" />
                <select
                  className="select1"
                  style={{ width: "100%", padding: "10px", border: "none" }}
                  onChange={(e) => setGender(e.target.value)}
                >
                  {genderList.map((g) => (
                    <>
                      <option key={g.id} value={g.id}>
                        {g.gender}
                      </option>
                    </>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-inner mb-25">
              <label>Religion*</label>
              <div className="input-area">
                <img src="/assets/images/icon/religion.svg" alt="" />
                <select
                  className="select1"
                  style={{ width: "100%", padding: "10px", border: "none" }}
                  onChange={(e) => setReligion(e.target.value)}
                >
                  {religionList.map((r) => (
                    <>
                      <option key={r.id} value={r.id}>
                        {r.religion_name}
                      </option>
                    </>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="hight">Height*</label>
              <div className="input-area">
                <img src="/assets/images/icon/hight.svg" alt="" />
                <input
                  type="text"
                  id="hight"
                  name="hight"
                  placeholder="5.6'"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="weight">Weight*</label>
              <div className="input-area">
                <img src="/assets/images/icon/weight.svg" alt="" />
                <input
                  type="text"
                  id="weight"
                  name="weight "
                  placeholder={56}
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-inner mb-25">
              <label htmlFor="hight">Passport Number*</label>
              <div className="input-area">
                <input
                  type="text"
                  id="hight"
                  name="hight"
                  placeholder="76*************"
                  value={passportNumber}
                  onChange={(e) => setPassportNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="hight">Passport Photo*</label>
              <div className="input-area">
                <input
                  type="file"
                  id="hight"
                  name="hight"
                  placeholder="76*************"
                  onChange={handlePassportUpload}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-inner mb-25">
              <label htmlFor="hight">Government ID Photo*</label>
              <div className="input-area">
                <input
                  type="file"
                  id="hight"
                  name="hight"
                  placeholder="76*************"
                  onChange={handleIdCardUpload}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-inner">
              <button
                className="primry-btn-2 lg-btn w-unset"
                type="submit"
                disabled={dataLoading}
                onClick={(e) => {
                  e.preventDefault();
                  // if (fatherName == "" || motherName == "" || dateOfBirth == "" || nationalId == "" || presentAddress == "" || permanentAddress == "" || height == "" || weight == "") {
                  //   alert("All fields are required!");
                  // } else {
                  setDataLoading(true);
                  axios
                    .post(process.env.REACT_APP_BASE_URL+"/onboard/step_two", {
                      father_name: fatherName,
                      mother_name: motherName,
                      dov: dateOfBirth,
                      national_id: nationalId,
                      persent_address: presentAddress,
                      perm_address: permanentAddress,
                      marital_status: maritalStatus,
                      gender: gender,
                      religion: religion,
                      height: height,
                      weight: weight,
                      basic_info_id: localStorage.getItem("basic_id"),
                      passport_number: passportNumber,
                      passport_photo: passportImage,
                      id_photo: idCardImage,
                    })
                    .then((response) => {
                      setDataLoading(false);
                      if (response.data.code == 1) {
                        alert("done");
                        stepHandler(3);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  // }
                }}
              >
                {dataLoading ? "Please wait..." : "Next"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const EducationalStep = ({ stepNumber, stepHandler }) => {
  const [forms, setForms] = useState([]);

  const [educationList, setEducationList] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL+"/static/getrows/education_levels")
      .then((response) => {
        setEducationList(response.data.result);
      });
  }, []);

  const handleDuplicate = () => {
    const newForm = {
      educationLevel: 1,
      institute: "",
      major: "",
      result: "",
      startingPeriod: "",
      endingPeriod: "",
      gpa: "",
    };
    setForms([...forms, newForm]);
  };
  return (
    <div
      className={`tab-pane fade show ${stepNumber == 3 ? "active" : ""}`}
      id="contact"
      role="tabpanel"
      aria-labelledby="contact-tab"
    >
      <form className="edit-profile-form profile-form">
        <div className="section-title2">
          <h5>Educational Qualification:</h5>
        </div>
        <div className="education-row">
          {forms.map((form, index) => (
            <div className="row" key={index}>
              <div className="col-lg-12">
                <div className="info-title">
                  <h6>Academic Information:</h6>
                  <div className="dash" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-25">
                  <label>Education Level*</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/qualification-2.svg" alt="" />
                    <select
                      className="select1"
                      style={{ width: "100%", padding: "10px", border: "none" }}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].educationLevel = e.target.value;
                        setForms(updatedFormData);
                      }}
                    >
                      {educationList.map((e) => (
                        <option value={e.id} key={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-25">
                  <label>My Major*</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/major.svg" alt="" />
                    <input
                      type="text"
                      id="major"
                      name="major"
                      placeholder="Arts, Science, Business..."
                      value={forms[index].major}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].major = e.target.value;
                        setForms(updatedFormData);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-25">
                  <label htmlFor="institute">Institute/University*</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/univercity.svg" alt="" />
                    <input
                      type="text"
                      id="institute"
                      name="institute"
                      placeholder="Type Your Institute Name..."
                      value={forms[index].institute}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].institute = e.target.value;
                        setForms(updatedFormData);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-30">
                  <label htmlFor="gpa">Result/GPA**</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/gpa-2.svg" alt="" />
                    <input
                      type="text"
                      id="gpa"
                      name="gpa"
                      placeholder="4.75"
                      value={forms[index].gpa}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].gpa = e.target.value;
                        setForms(updatedFormData);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-20">
                  <label htmlFor="datepicker10">Starting Period*</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/calender2.svg" alt="" />
                    <input
                      type="date"
                      id="datepicker10"
                      name="ep"
                      placeholder="DD/MM/YY"
                      value={forms[index].startingPeriod}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].startingPeriod = e.target.value;
                        setForms(updatedFormData);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-inner mb-20">
                  <label htmlFor="datepicker11">Ending Period*</label>
                  <div className="input-area">
                    <img src="/assets/images/icon/calender2.svg" alt="" />
                    <input
                      type="date"
                      id="datepicker11"
                      name="ep"
                      placeholder="DD/MM/YY"
                      value={forms[index].endingPeriod}
                      onChange={(e) => {
                        const updatedFormData = [...forms];
                        updatedFormData[index].endingPeriod = e.target.value;
                        setForms(updatedFormData);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="add-remove-btn d-flex align-items-center justify-content-between mb-50">
          <div className="add-row">
            <button type="button" className="" onClick={handleDuplicate}>
              Add Education+
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-inner">
            <button
              className="primry-btn-2 lg-btn w-unset"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                for (let i = 0; i < forms.length; i++) {
                  axios
                    .post(
                      process.env.REACT_APP_BASE_URL+"/onboard/step_three",
                      {
                        educationLevel: forms[i].educationLevel,
                        institute: forms[i].institute,
                        major: forms[i].major,
                        result: 1,
                        startingPeriod: forms[i].startingPeriod,
                        endingPeriod: forms[i].endingPeriod,
                        gpa: forms[i].gpa,
                        basic_info_id: localStorage.getItem("basic_id"),
                      }
                    )
                    .then((response) => stepHandler(4));
                }
              }}
            >
              Update Change
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const EmploymentStep = ({ stepNumber, stepHandler }) => {
  const [forms, setForms] = useState([]);

  const [industryList, setIndustryList] = useState([]);
  const [industrySelected, setIndustrySelected] = useState(1);
  const [catList, setCatList] = useState([]);
  const [catSelected, setCatSelected] = useState(1);
  const [designation, setDesignation] = useState("");

  const [companyName, setCompanyName] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL+`/static/getrows/category`)
      .then((response) => {
        setIndustryList(response.data.result);
      });
    axios
      .get(
        process.env.REACT_APP_BASE_URL+`/static/getdepartment/${industrySelected}`
      )
      .then((response) => {
        setCatList(response.data.result);
      });
  }, [industrySelected]);

  const [working, setWorking] = useState(true);

  return (
    <div
      className={`tab-pane fade show ${stepNumber == 4 ? "active" : ""}`}
      id="professional"
      role="tabpanel"
      aria-labelledby="professional-tab"
    >
      <form className="edit-profile-form profile-form">
        <div className="section-title2">
          <h5>Employment Information:</h5>
        </div>
        <div className="experiences-row">
          <div className="row">
            <div className="col-lg-12">
              <div className="info-title">
                <h6>Add Your Experiences</h6>
                <div className="dash" />
              </div>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                  onChange={() => {
                    setWorking(!working);
                    setCompanyName("N/A");
                    setDesignation("N/A");
                    setIndustrySelected("490");
                  }}
                />
                Not working currently
              </label>
            </div>
            <br />
            <br />
            {working ? (
              <>
                {" "}
                <div className="col-md-6">
                  <div className="form-inner mb-25">
                    <label htmlFor="companies">Company Name*</label>
                    <div className="input-area">
                      <img src="/assets/images/icon/company-2.svg" alt="" />
                      <input
                        type="text"
                        id="companies"
                        name="companies"
                        placeholder="Egenslab"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-inner mb-25 w-100">
                    <label>Industry*</label>
                    <div className="input-area w-100">
                      {/* <select
                                  className="select1"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "none",
                                  }}
                                  onChange={(e) =>
                                    setQualificationSelected(e.target.value)
                                  }
                                >
                                  {qualification.map((q) => {
                                    return (
                                      <>
                                        <option key={q.id} value={q.name}>
                                          {q.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select> */}
                      <div
                        style={{
                          width: "100%",
                          zIndex: "99",
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                          }}
                        >
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="color"
                            options={industryList.map((q) => ({
                              value: q.id,
                              label: q.name,
                            }))}
                            onChange={(e) => setIndustrySelected(e.value)}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-inner mb-25">
                    <label htmlFor="companies">Designation*</label>
                    <div className="input-area">
                      <img src="/assets/images/icon/company-2.svg" alt="" />
                      <input
                        type="text"
                        id="companies"
                        name="companies"
                        placeholder="Web Developer"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                      />
                    </div>
                  </div>
                </div>{" "}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-inner">
            <button
              className="primry-btn-2 lg-btn w-unset"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                axios
                  .post(process.env.REACT_APP_BASE_URL+"/onboard/step_four", {
                    companyName: companyName,
                    industrySelected: industrySelected,
                    designation: designation,
                    basic_info_id: localStorage.getItem("basic_id"),
                  })
                  .then((response) => {
                    if (response.data.code == 1) {
                      localStorage.setItem("accessToken", "");
                      navigate("/seekersignin");
                    }
                  });
              }}
            >
              Update Change
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const SeekerOnboard = () => {
  const { firstname, lastname, email, phone } = useParams();
  const [stepNumber, setStepNumber] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const [dataLoading, setDataLoading] = useState(false);

  // Basic Information States
  const [firstName, setFirstName] = useState(firstname);
  const [lastName, setLastName] = useState(lastname);
  const [jobPlace, setJobPlace] = useState("");
  const [designation, setDesignation] = useState("");
  const [designationTwo, setDesignationTwo] = useState("none");
  const [designationThree, setDesignationThree] = useState("none");
  const [designationFour, setDesignationFour] = useState("none");
  const [designationFive, setDesignationFive] = useState("none");
  const [designationCount, setDesignationCount] = useState(1);
  const [experienceNo, setExperienceNo] = useState();
  const [qualification, setQualification] = useState([]);
  const [qualificationSelected, setQualificationSelected] = useState();
  const [userEmail, setUserEmail] = useState(email);
  const [phoneNumber, setPhoneNumber] = useState(phone);
  const [objective, setObjective] = useState("");
  const [image, setImage] = useState(null);
  const [profileName, setProfileName] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(1);

  const [working, setWorking] = useState(true);
  const [skills, setSkills] = useState([]);
  const [catList, setCatList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [nationalityList, setNationalityList] = useState([
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivian",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Ni-Vanuatu",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
  ]);
  const [selectedNationality, setSelectedNationality] = useState("afghan");

  const [currentSalary, setCurrentSalary] = useState("10,000 - 20,000");
  const [expSalary, setExpSalary] = useState("10,000 - 20,000");

  const handleDuplicate = () => {
    const newForm = {
      skill_name: "",
    };
    setSkills([...skills, newForm]);
  };

  const handleImageChange = async (e) => {
    const newImage = e.target.files[0];
    setImage(newImage); // Update image in state
    const formData = new FormData();
    formData.append("image", newImage);
    console.log(e.target.files[0]);
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL+"/static/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setProfileName(res.data.fileName);
    } catch (err) {
      alert(err);
    }
  };

  const [firstFieldsError, setFirstFieldsError] = useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL +"/countries")
      .then((response) => {
        setCountryList(response.data.result);
      });
  }, []);
//Category Axios
  useEffect(() => {
    axios
      // .get("np")
      .get(process.env.REACT_APP_BASE_URL+"/static/getrows/category")
      .then((response) => {
        setCatList(response.data.result);
      });
  }, []);
  // Qualification Axios
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL+"/static/getrows/industries")
      .then((response) => {
        setQualification(response.data.result);
      });
  }, []);

  const fieldsError = () => {
    messageApi.open({
      type: "error",
      content: "All Fields Are Required",
    });
  };

  const stepHandler = (e) => {
    setStepNumber(e);
  };
  return (
    <>
      <Navigation />
      {contextHolder}
      <div className="dashboard-area pt-120 mb-120">
        <div className="container">
          <div className="row g-lg-4 gy-5 mb-90">
            <div className="col-lg-12">
              <div className="edit-profile-inner">
                <div className="form-wrapper">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          stepNumber == 1 ? "active" : ""
                        }`}
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        disabled={stepNumber > 1 ? true : false}
                      >
                        <span />
                        Basic Information:
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          stepNumber == 2 ? "active" : ""
                        }`}
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        <span />
                        Profile
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          stepNumber == 3 ? "active" : ""
                        }`}
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact"
                        type="button"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                      >
                        <span />
                        Education
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          stepNumber == 4 ? "active" : ""
                        }`}
                        id="professional-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#professional"
                        type="button"
                        role="tab"
                        aria-controls="professional"
                        aria-selected="false"
                      >
                        <span />
                        Professional Info
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={`tab-pane fade show ${
                        stepNumber == 1 ? "active" : ""
                      }`}
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <form className="edit-profile-form profile-form  mb-60">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="firstname1">First Name*</label>
                              <div className="input-area">
                                <img
                                  src="/assets/images/icon/user-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="firstname1"
                                  name="firstname"
                                  placeholder="Mrs. Jacoline"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-4 col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="lastname">Last Name*</label>
                              <div className="input-area">
                                <img
                                  src="/assets/images/icon/user-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="lastname"
                                  name="lastname"
                                  placeholder="Frankly"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-2 col-lg-12 position-relative">
                            <div
                              className="drag-area"
                              style={{
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundImage: `url(${
                                  profileName == ""
                                    ? ""
                                    : process.env.REACT_APP_BASE_URL+"/profile_images/" +
                                      profileName
                                })`,
                              }}
                            >
                              <p>Upload Images</p>
                              <label
                                htmlFor="file-input"
                                className="upload-btn"
                              >
                                <i className="bi bi-plus-lg" />
                              </label>
                              <input
                                id="file-input"
                                type="file"
                                style={{
                                  position: "absolute",
                                  left: "-9999px",
                                }}
                                onChange={(e) => {
                                  setImage(e.target.files[0]);
                                  handleImageChange(e);
                                }}
                              />
                            </div>
                          </div>{" "}
                          <div className="col-md-12">
                            <div className="form-inner mb-25">
                              <label htmlFor="jobplace">
                                Current Job Place*
                              </label>
                              <div className="input-area">
                                <img
                                  src="/assets/images/icon/company-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="jobplace"
                                  name="jobplace"
                                  placeholder="Company Name"
                                  value={jobPlace}
                                  onChange={(e) => setJobPlace(e.target.value)}
                                  disabled={working ? false : true}
                                />
                              </div>
                            </div>
                          </div>{" "}
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                style={{ marginRight: "10px" }}
                                onChange={() => {
                                  setWorking(!working);
                                  setJobPlace("N/A");
                                }}
                              />
                              Not working currently
                            </label>
                          </div>
                          <br />
                          <br />
                          <div className="col-md-12">
                            <div className="form-inner mb-25">
                              <label htmlFor="desigation">Designation*</label>
                              <div className="input-area">
                                <img
                                  src="/assets/images/icon/designation-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="desigation"
                                  name="desigation"
                                  placeholder="UI/UX Engineer"
                                  value={designation}
                                  onChange={(e) =>
                                    setDesignation(e.target.value)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  flexDirection: "row",
                                }}
                              >
                                <button
                                  style={{
                                    width: "120px",
                                    height: "40px",
                                    color: "red",
                                    background: "none",
                                    padding: "0px",
                                    width: "70px",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (designationCount == 1) {
                                      alert("Atleast 1 is required.");
                                    } else {
                                      if (designationCount == 2) {
                                        setDesignationTwo("none");
                                      } else if (designationCount == 3) {
                                        setDesignationThree("none");
                                      } else if (designationCount == 4) {
                                        setDesignationFour("none");
                                      } else if (designationCount == 5) {
                                        setDesignationFive("none");
                                      }
                                      setDesignationCount(designationCount - 1);
                                    }
                                  }}
                                >
                                  Remove
                                </button>
                                <button
                                  style={{
                                    width: "185px",
                                    height: "40px",
                                    color: "#00a4e5",
                                    background: "none",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (designationCount >= 5) {
                                      alert("Maximum 5 Allowed!");
                                    } else {
                                      setDesignationCount(designationCount + 1);
                                    }
                                  }}
                                >
                                  Add More Title
                                </button>
                              </div>
                            </div>
                          </div>
                          {designationCount > 1 ? (
                            <div className="col-md-12">
                              <div className="form-inner mb-25">
                                <label htmlFor="desigation">
                                  Second Designation*{" "}
                                </label>
                                <div className="input-area">
                                  <img
                                    src="/assets/images/icon/designation-2.svg"
                                    alt=""
                                  />
                                  <input
                                    type="text"
                                    id="desigation"
                                    name="desigation"
                                    placeholder="Web Developer"
                                    value={designationTwo}
                                    onChange={(e) =>
                                      setDesignationTwo(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {designationCount > 2 ? (
                            <div className="col-md-12">
                              <div className="form-inner mb-25">
                                <label htmlFor="desigation">
                                  Third Designation*
                                </label>
                                <div className="input-area">
                                  <img
                                    src="/assets/images/icon/designation-2.svg"
                                    alt=""
                                  />
                                  <input
                                    type="text"
                                    id="desigation"
                                    name="desigation"
                                    placeholder="Server Administrator"
                                    value={designationThree}
                                    onChange={(e) =>
                                      setDesignationThree(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {designationCount > 3 ? (
                            <div className="col-md-12">
                              <div className="form-inner mb-25">
                                <label htmlFor="desigation">
                                  Fourth Designation*
                                </label>
                                <div className="input-area">
                                  <img
                                    src="/assets/images/icon/designation-2.svg"
                                    alt=""
                                  />
                                  <input
                                    type="text"
                                    id="desigation"
                                    name="desigation"
                                    placeholder="SEO Specialist"
                                    value={designationFour}
                                    onChange={(e) =>
                                      setDesignationFour(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {designationCount > 4 ? (
                            <div className="col-md-12">
                              <div className="form-inner mb-25">
                                <label htmlFor="desigation">
                                  Fifth Designation*
                                </label>
                                <div className="input-area">
                                  <img
                                    src="/assets/images/icon/designation-2.svg"
                                    alt=""
                                  />
                                  <input
                                    type="text"
                                    id="desigation"
                                    name="desigation"
                                    placeholder="Backend Developer"
                                    value={designationFive}
                                    onChange={(e) =>
                                      setDesignationFive(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="experiences">
                                Year of Experiences*
                              </label>
                              <div className="input-area">
                                <img
                                  src="/assets/images/icon/experiences-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="experiences"
                                  name="experiences"
                                  placeholder="3.5 Years"
                                  required
                                  value={experienceNo}
                                  onChange={(e) =>
                                    setExperienceNo(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25 w-100">
                              <label>Qualification*</label>
                              <div className="input-area w-100">
                                {/* <select
                                  className="select1"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "none",
                                  }}
                                  onChange={(e) =>
                                    setQualificationSelected(e.target.value)
                                  }
                                >
                                  {qualification.map((q) => {
                                    return (
                                      <>
                                        <option key={q.id} value={q.name}>
                                          {q.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select> */}
                                <div
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                    }}
                                  >
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      name="color"
                                      options={qualification.map((q) => ({
                                        value: q.name,
                                        label: q.name,
                                      }))}
                                      onChange={(e) =>
                                        setQualificationSelected(e.value)
                                      }
                                      styles={customStyles}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="email1">Email*</label>
                              <div className="input-area">
                                <img
                                  src="/assets/images/icon/email-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="email1"
                                  name="email"
                                  placeholder="info@example.com"
                                  value={email}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label htmlFor="phonenumber">Phone Number*</label>
                              <div className="input-area">
                                <img
                                  src="/assets/images/icon/phone-2.svg"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  id="phonenumber"
                                  value={phoneNumber}
                                  name="phonenumber"
                                  placeholder="+880-17 *** *** **"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label>Country*</label>
                              <div className="input-area">
                                <div
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                    }}
                                  >
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      name="color"
                                      options={countryList.map((q) => ({
                                        value: q.id,
                                        label: q.country_name,
                                      }))}
                                      onChange={(e) =>
                                        setSelectedCountry(e.value)
                                      }
                                      styles={customStyles}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label>Nationality*</label>
                              <div className="input-area">
                                {/* <select
                                  className="select1"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "none",
                                  }}
                                  onChange={(e) =>
                                    setSelectedNationality(e.target.value)
                                  }
                                >
                                  {nationalityList.map((q) => {
                                    return (
                                      <>
                                        <option key={q} value={q}>
                                          {q}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select> */}
                                <div
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                    }}
                                  >
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      name="color"
                                      options={nationalityList.map((q) => ({
                                        value: q,
                                        label: q,
                                      }))}
                                      onChange={(e) =>
                                        setSelectedNationality(e.value)
                                      }
                                      styles={customStyles}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label>Current Salary*</label>
                              <div className="input-area">
                                <select
                                  className="select1"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "none",
                                  }}
                                  onChange={(e) =>
                                    setCurrentSalary(e.target.value)
                                  }
                                >
                                  <option value="10,000 - 20,000" selected>
                                    {" "}
                                    10,000 - 20,000{" "}
                                  </option>
                                  <option value="20,000 - 30,000">
                                    20,000 - 30,000
                                  </option>
                                  <option value="30,000 - 40,000">
                                    30,000 - 40,000
                                  </option>
                                  <option value="40,000 - 50,000">
                                    40,000 - 50,000
                                  </option>
                                  <option value="50,000 - 60,000">
                                    50,000 - 60,000
                                  </option>
                                  <option value="60,000 - 70,000">
                                    60,000 - 70,000
                                  </option>
                                  <option value="70,000 - 80,000">
                                    70,000 - 80,000
                                  </option>
                                  <option value="80,000 - 90,000">
                                    80,000 - 90,000
                                  </option>
                                  <option value="90,000 - 100,000">
                                    90,000 - 100,000
                                  </option>
                                  <option value="100,000+">100,000+</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label>Expected Salary*</label>
                              <div className="input-area">
                                <select
                                  className="select1"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "none",
                                  }}
                                  onChange={(e) => setExpSalary(e.target.value)}
                                >
                                  <option value="10,000 - 20,000" selected>
                                    {" "}
                                    10,000 - 20,000{" "}
                                  </option>
                                  <option value="20,000 - 30,000">
                                    20,000 - 30,000
                                  </option>
                                  <option value="30,000 - 40,000">
                                    30,000 - 40,000
                                  </option>
                                  <option value="40,000 - 50,000">
                                    40,000 - 50,000
                                  </option>
                                  <option value="50,000 - 60,000">
                                    50,000 - 60,000
                                  </option>
                                  <option value="60,000 - 70,000">
                                    60,000 - 70,000
                                  </option>
                                  <option value="70,000 - 80,000">
                                    70,000 - 80,000
                                  </option>
                                  <option value="80,000 - 90,000">
                                    80,000 - 90,000
                                  </option>
                                  <option value="90,000 - 100,000">
                                    90,000 - 100,000
                                  </option>
                                  <option value="100,000+">100,000+</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-inner mb-25">
                              <label>Category</label>
                              <div className="input-area">
                                <div
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                    }}
                                  >
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      name="color"
                                      options={catList.map((q) => ({
                                        value: q.id,
                                        label: q.name,
                                      }))}
                                      onChange={(e) =>
                                        setSelectedCategory(e.value)
                                      }
                                      styles={customStyles}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {skills.map((form, index) => (
                            <input
                              style={{
                                border: "1px solid black",
                                marginLeft: "10px",
                                width: "150px",
                                height: "40px",
                              }}
                              value={skills[index].skill_name}
                              onChange={(e) => {
                                const updatedFormData = [...skills];
                                updatedFormData[index].skill_name =
                                  e.target.value;
                                setSkills(updatedFormData);
                              }}
                            />
                          ))}
                          <div className="add-row">
                            {/* <button
                              type="button"
                              className=""
                              onClick={handleDuplicate}
                            >
                              Add Skill +
                            </button> */}
                            <br />
                            <br />
                          </div>
                          <div className="col-md-12">
                            <div className="form-inner mb-50">
                              <label htmlFor="description">
                                Career Objective*
                              </label>
                              <textarea
                                name="description"
                                id="description"
                                placeholder="Something Write Yourself...."
                                defaultValue={""}
                                value={objective}
                                onChange={(e) => setObjective(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <br />
                            {firstFieldsError ? (
                              <Alert
                                message="All fields are required"
                                type="error"
                              />
                            ) : (
                              ""
                            )}
                            <br />
                            <div className="form-inner">
                              <button
                                disabled={dataLoading}
                                className="primry-btn-2 lg-btn w-unset"
                                onClick={(event) => {
                                  event.preventDefault();
                                  if (
                                    firstName == "" ||
                                    lastName == "" ||
                                    jobPlace == "" ||
                                    designation == "" ||
                                    experienceNo == "" ||
                                    qualification == "" ||
                                    userEmail == "" ||
                                    phoneNumber == "" ||
                                    objective == "" ||
                                    profileName == ""
                                  ) {
                                    setFirstFieldsError(true);
                                    fieldsError();
                                  } else {
                                    setDataLoading(true);
                                    axios
                                      .post(
                                        process.env.REACT_APP_BASE_URL+"/onboard/step_one",
                                        {
                                          firstName: firstName,
                                          lastName: lastName,
                                          jobPlace: jobPlace,
                                          designation: designation,
                                          designationTwo: designationTwo,
                                          designationThree: designationThree,
                                          designationFour: designationFour,
                                          designationFive: designationFive,
                                          experienceNo: experienceNo,
                                          qualification: qualificationSelected,
                                          userEmail: userEmail,
                                          phoneNumber: phoneNumber,
                                          objective: objective,
                                          profile_image: profileName,
                                          selectedCountry: selectedCountry,
                                          selectedNationality:
                                            selectedNationality,
                                          currentSalary: currentSalary,
                                          expSalary: expSalary,
                                          category: selectedCategory
                                        }
                                      )
                                      .then((response) => {
                                        setDataLoading(false);
                                        if (response.data.code == 1) {
                                          for (
                                            let i = 0;
                                            i < skills.length;
                                            i++
                                          ) {
                                            axios
                                              .post(
                                                process.env.REACT_APP_BASE_URL+"/addskill",
                                                {
                                                  userId:
                                                    response.data.basicInfoId,
                                                  skillName:
                                                    skills[i].skill_name,
                                                }
                                              )
                                              .then((response) =>
                                                alert(response.data.code)
                                              );
                                          }
                                          setStepNumber(2);
                                          localStorage.setItem(
                                            "basic_id",
                                            response.data.basicInfoId
                                          );
                                        }
                                      });
                                  }
                                }}
                              >
                                {dataLoading
                                  ? "Loading Please Wait..."
                                  : "Next"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <ProfileStep
                      stepNumber={stepNumber}
                      stepHandler={stepHandler}
                    />
                    <EducationalStep
                      stepNumber={stepNumber}
                      stepHandler={stepHandler}
                    />
                    <EmploymentStep
                      stepNumber={stepNumber}
                      stepHandler={stepHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SeekerOnboard;
