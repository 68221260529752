import React, { useState, useEffect } from "react";
import Styles from "./OldHome.module.css";
import searchIcon from "./searchicon.svg";
import HeroImg from "./heroImg.png";
import axios from "axios";
import JobsSection from "./JobsSection";
import { useRef } from "react";
import { Button, Dropdown, Menu } from "antd";
import JobsSectionCustom from "./JobsSectionCustom";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navbar/Navbar";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HomePage from "./homepage";
import Select from "react-select";
import Footer from "../../components/Footer";
const OldHome = () => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [catList, setCatList] = useState([]);
  const [catListF, setCatListF] = useState([]);
  const [selectedCat, setSelectedCat] = useState(0);
  const [customSearch, setCustomSearch] = useState(false);
  const [title, setTitle] = useState("Enter Title");
  const [selectedIndustry, setSelectedIndustry] = useState("0");

  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState("none");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [customFilterJob, setCustomFilterJobs] = useState([]);
  const [pakistan, setpakistan] = useState([]);
  const [gulfcounties, setgulfcountiesn] = useState([]);
  const navigate = useNavigate();
  const [searchIt, setSearchIt] = useState(false);
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  const [euJobs, setEuJobs] = useState([]);

  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/getrows/social")
      .then((response) => {
        setFacebook(response.data.result[0].facebook);
        setInstagram(response.data.result[0].instagram);
        setLinkedIn(response.data.result[0].linkedin);
        setTwitter(response.data.result[0].twitter);
      });
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,

      background: "white",
      border: "none",
      height: "59px",
      textAlign: "left",
    }),
  };

  useEffect(() => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries")
      .then((response) => setCountryList(response.data.data));
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/pakistanjobs")
      .then((response) => {
        console.log(response, "pakistan");
        setpakistan(response.data.result);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/europejobs")
      .then((response) => {
        console.log(response, "pakistan");
        setEuJobs(response.data.result);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/gulfcountries")
      .then((response) => {
        console.log(response, "gulf");
        setgulfcountiesn(response.data.result);
      });
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/getCategories")
      .then((response) => {
        setCatList(response.data.result);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/getrows/category")
      .then((response) => {
        setCatListF(response.data.result);
      });
  }, []);
  useEffect(() => {
    if (searchIt) {
      axios
        .post(
          process.env.REACT_APP_BASE_URL +
            `/static/customSearchNew/${
              title == "" ? "Enter Title" : title
            }/${selectedIndustry}/${countrySelected}`
        )
        .then((response) => {
          setSearchIt(false);
          setCustomSearch(true);

          setCustomFilterJobs(response.data.result);
        });
    }
  }, [searchIt]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  useEffect(() => {
    // axios.get("https://backend.ogsmanpower.com/static/getContent/about").then(response => setContent(response.data.message[0].text))
    axios
      .get(process.env.REACT_APP_BASE_URL + "/static/getMeta/home")
      .then((response) => {
        setMetaTitle(response.data.message[0].title);
        setMetaDescription(response.data.message[0].description);
        setMetaKeywords(response.data.message[0].keywords);
      });
  }, []);
  const items = [
    {
      key: "1",
      label: (
        <a target="" rel="noopener noreferrer" href="/seekersignin">
          As Seeker
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="" rel="noopener noreferrer" href="/companysignin">
          As Employer
        </a>
      ),
    },
  ];
  const secondItems = [
    {
      key: "1",
      label: (
        <a target="" rel="noopener noreferrer" href="/signup/1">
          As Seeker
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="" rel="noopener noreferrer" href="/signup/2">
          As Employer
        </a>
      ),
    },
  ];
  return (
    <HelmetProvider>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
      {/* <div className={`${Styles.mobileNavigation}`}>
        <div className={`${Styles.mobileNavigation}`}>
          <Navigation />
        </div>
        <div
          style={{ padding: "10px 0px" }}
          className={`${Styles.desktopNavigation}`}
        >
          <div className="container d-flex justify-content-between align-items-center">
            <img src="/assets/images/header1-logo.svg" />

            <div className={`${Styles.navLinks}`}>
              <a href="/">Home</a>
              <a href="/jobs">Jobs</a>
              <a href="/about">About</a>
              <a href="/contact">Contact</a>
            </div>

            <div className={`${Styles.buttons}`}>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
                arrow
              >
                <Button style={{ border: "none" }}>Login</Button>
              </Dropdown>

              <Dropdown
                overlay={
                  <Menu>
                    {secondItems.map((item) => (
                      <Menu.Item key={item.key}>{item.label}</Menu.Item>
                    ))}
                  </Menu>
                }
                placement="bottomRight"
                arrow
              >
                <Button
                  style={{
                    border: "none",
                    height: "40px",
                    borderRadius: "0px",
                  }}
                >
                  Register
                </Button>
              </Dropdown>
              <button
                style={{ height: "40px" }}
                onClick={(e) => navigate("/companysignin")}
              >
                Post A Job
              </button>
            </div>
          </div>
        </div>
        <section
          style={{
            background: `url(${HeroImg})`,
            padding: "70px 0px",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className={`${Styles.desktopHeroContent} heroHeading container`}>
            <center>
              <h2 style={{ fontSize: "48px", color: "black" }}>
                <b>Find Your Next Dream Job</b>
              </h2>
              <p>Easiest way to find a perfect job</p>
            </center>
            <div className="inputs d-flex justify-content-between">
              <div>
                <img
                  src={searchIcon}
                  style={{
                    marginRight: "-20px",
                    zIndex: "4",
                    position: "relative",
                    marginLeft: "40px",
                  }}
                />
                <input
                  className={`${Styles.oldInputs}`}
                  placeholder="Enter Job Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div>
                <select
                  onChange={(e) => setCountrySelected(e.target.value)}
                  className={`${Styles.oldSelect}`}
                >
                  <option value="none">Select Country</option>

                  {countryList.length > 0
                    ? countryList.map((q) => (
                        <option key={q.country} value={q.country}>
                          {q.country}
                        </option>
                      ))
                    : "Loading"}
                </select>
              </div>

              <div
                className={`${Styles.heroBtns} d-flex`}
                style={{ width: "410px" }}
              >
                <button onClick={() => setSearchIt(true)}>
                  Search by Title
                </button>
                <button>Search By Country</button>
              </div>
            </div>
          </div>
          <div
            className={`${Styles.mobileHeroContent} container`}
            style={{ marginTop: "-40px" }}
          >
            <center>
              <div
                style={{
                  background: "orange",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottomRight"
                  arrow
                >
                  <Button
                    style={{
                      border: "1px solid #00a4e5",
                      width: "100%",
                      height: "40px",
                      borderRadius: "0px",
                    }}
                  >
                    Login
                  </Button>
                </Dropdown>

                <Dropdown
                  overlay={
                    <Menu>
                      {secondItems.map((item) => (
                        <Menu.Item key={item.key}>{item.label}</Menu.Item>
                      ))}
                    </Menu>
                  }
                  placement="bottomRight"
                  arrow
                >
                  <Button
                    style={{
                      border: "none",
                      height: "40px",
                      borderRadius: "0px",
                      width: "100%",
                      background: "#00a4e5",
                      color: "white",
                    }}
                  >
                    Register
                  </Button>
                </Dropdown>
                <button
                  style={{
                    height: "40px",
                    width: "100%",
                    border: "1px solid #00a4e5",
                  }}
                  onClick={(e) => navigate("/companysignin")}
                >
                  Post A Job
                </button>
              </div>
              <br />
              <h1>
                <b>Find Your Next Dream Job</b>
              </h1>
              <p>Easiest way to find a perfect job</p>
            </center>
            <center>
              <div style={{ display: "flex" }}>
                <img
                  src={searchIcon}
                  style={{
                    marginRight: "-25px",
                    marginLeft: "10px",
                    position: "relative",
                    zIndex: "2",
                  }}
                />
                <input
                  style={{
                    height: "40px",
                    width: "100%",
                    padding: "30px 30px",
                    border: "1px solid #b5b5b5",
                  }}
                  placeholder="Enter Job Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div style={{ display: "flex" }} className="mt-3">
                <div
                  style={{
                    width: "95%",
                    border: "1px solid rgb(181, 181, 181)",
                  }}
                >
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Select Country"
                    name="color"
                    options={countryList.map((q) => ({
                      value: q.country,
                      label: q.country,
                    }))}
                    onChange={(e) => setCountrySelected(e.value)}
                    styles={customStyles}
                  />
                </div>
                <br />
                <br />
                <div
                  style={{
                    width: "95%",
                    marginLeft: "10px",
                    border: "1px solid rgb(181, 181, 181)",
                  }}
                >
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Select Industry"
                    name="color"
                    options={catListF.map((q) => ({
                      value: q.id,
                      label: q.name,
                    }))}
                    onChange={(e) => setSelectedIndustry(e.value)}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }} className="mt-3">
                <button
                  style={{
                    width: "100%",
                    background: "#00a4e5",
                    height: "58px",
                    color: "white",
                    marginRight: "10px",
                  }}
                  onClick={() => setSearchIt(true)}
                >
                  Search By Title
                </button>
                <button
                  style={{
                    width: "100%",
                    background: "#00a4e5",
                    height: "58px",
                    color: "white",
                  }}
                  onClick={() => setSearchIt(true)}
                >
                  Search By Country
                </button>
              </div>
              <button
                style={{
                  width: "100%",
                  background: "#00a4e5",
                  height: "58px",
                  color: "white",
                }}
                onClick={() => setSearchIt(true)}
                className="mt-3"
              >
                Search By Industry
              </button>
            </center>
          </div>
        </section>
        <section>
          <div className="container mt-5">
            {customSearch ? (
              <>
                <h2 style={{ color: "black", fontSize: "36px" }}>
                  Search Results
                </h2>
                <hr />
                <div ref={myRef}>
                  <JobsSectionCustom
                    type={1}
                    customSearch={customSearch}
                    filteredJobs={customFilterJob}
                    catList={catListF}
                    country={countrySelected}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </section>
        <section>
          <div className="container mt-5">
            <h2 style={{ color: "black", fontSize: "36px" }}>
              Gulf Countries Jobs
            </h2>
            <hr />
            <div ref={myRef}>
              <JobsSection
                type={1}
                customSearch={customSearch}
                filteredJobs={gulfcounties}
                catList={catListF}
                country="gulf"
              />
            </div>

            <h2 style={{ color: "black", fontSize: "36px" }}>Pakistani Jobs</h2>
            <hr />
            <div ref={myRef}>
              <JobsSection
                type={1}
                customSearch={customSearch}
                filteredJobs={pakistan}
                catList={catListF}
                country="Pakistan"
              />
            </div>
            <h2 style={{ color: "black", fontSize: "36px" }}>European Jobs</h2>
            <hr />
            <div ref={myRef}>
              <JobsSection
                type={1}
                customSearch={customSearch}
                filteredJobs={euJobs}
                catList={catListF}
                country="Pakistan"
              />
            </div>
          </div>
        </section>
        <footer className="text-center text-lg-start text-dark bg-white"></footer>
        <Footer />
        <div
          className="text-center p-3"
          style={{
            backgroundColor: "#1A1A1A",
            color: "white",
            paddingTop: "40px",
          }}
        >
          Copyright © 2022 OGS MANPOWER | All Rights Reserved
        </div>
      </div> */}
      <div className={`${Styles.desktopNavigation}`}>
        <HomePage />
      </div>
    </HelmetProvider>
  );
};

export default OldHome;
