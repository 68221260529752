import React, { useState, useEffect } from "react";
import Styles from "./OldHome.module.css";
import searchIcon from "./searchicon.svg";
import HeroImg from "./heroImg.png";
import axios from "axios";
import JobsSection from "./JobsSection";
import { useRef } from "react";
import { Button, Dropdown, Menu } from "antd";
import JobsSectionCustom from "./JobsSectionCustom";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navbar/Navbar";
import { Helmet, HelmetProvider } from "react-helmet-async";
const About = () => {
  const navigate = useNavigate();
  const [searchIt, setSearchIt] = useState(false);
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  const [content, setContent] = useState("");

  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");

  useEffect(() => {
    axios.get("https://backend.ogsmanpower.com/static/getContent/about").then(response => setContent(response.data.message[0].text))
    axios.get("https://backend.ogsmanpower.com/static/getMeta/about").then(response => {
    setMetaTitle(response.data.message[0].title);
    setMetaDescription(response.data.message[0].description);
    setMetaKeywords(response.data.message[0].keywords);
  })
},[])
  const items = [
    {
      key: "1",
      label: (
        <a target="" rel="noopener noreferrer" href="/seekersignin">
          As Seeker
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="" rel="noopener noreferrer" href="/companysignin">
          As Employer
        </a>
      ),
    },
  ];
  const secondItems = [
    {
      key: "1",
      label: (
        <a target="" rel="noopener noreferrer" href="/signup/1">
          As Seeker
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="" rel="noopener noreferrer" href="/signup/2">
          As Employer
        </a>
      ),
    },
  ];
  return (
    <HelmetProvider>
        <Helmet>
        <title>{metaTitle}</title>
        <meta name='description' content={metaDescription} />
        <meta name='keywords' content={metaKeywords} />
        </Helmet>
            <div className={`${Styles.mobileNavigation}`}>
      <Navigation />
    </div>
      <div style={{ padding: "10px 0px" }} className={`${Styles.desktopNavigation}`}>
        <div className="container d-flex justify-content-between align-items-center">
          <img src="/assets/images/header1-logo.svg" />

          <div className={`${Styles.navLinks}`}>
            <a href="/">Home</a>
            <a href="/jobs">Jobs</a>
            <a href="/contact">Contact</a>
          </div>

          <div className={`${Styles.buttons}`}>
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              arrow
            >
              <Button style={{ border: "none" }}>Login</Button>
            </Dropdown>

            <Dropdown
              overlay={
                <Menu>
                  {secondItems.map((item) => (
                    <Menu.Item key={item.key}>{item.label}</Menu.Item>
                  ))}
                </Menu>
              }
              placement="bottomRight"
              arrow
            >
              <Button
                style={{ border: "none", height: "40px", borderRadius: "0px" }}
              >
                Register
              </Button>
            </Dropdown>
            <button
              style={{ height: "40px" }}
              onClick={(e) => navigate("/companysignin")}
            >
              Post A Job
            </button>
          </div>
        </div>
      </div>
      {/* Hero Section */}
      <section
        style={{
          background: `url(${HeroImg})`,
          padding: "70px 0px",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className={`${Styles.desktopHeroContent} heroHeading container`}>
          <center>
            <h2 style={{ fontSize: "48px", color: "black" }}>
              <b>About OGS ManPower</b>
            </h2>
            {/* <p>Easiest way to find a perfect job</p> */}
          </center>
        </div>
        <div
          className={`${Styles.mobileHeroContent} container`}
          style={{ marginTop: "-40px" }}
        >
          <center>
            <br />
            <h1>
            <b>About OGS ManPower</b>
            </h1>
            {/* <p>Easiest way to find a perfect job</p> */}
          </center>
        </div>
      </section>
        <div className="container mt-5 mb-5">
        <div
                    dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                  ></div>   
        </div>
      <footer className="text-center text-lg-start text-dark bg-white">
        <section
          className="d-flex justify-content-between m-0 p-4 text-white"
          style={{ backgroundColor: "rgb(1, 175, 243)" }}
        >
          <div className="me-5">
            <span>Get connected with us on social networks:</span>
          </div>
          <div>
            <a href="https://www.facebook.com/ogs.official/" className="text-white me-4">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="https://twitter.com/ogsmanpower" className="text-white me-4">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://www.instagram.com/ogsmanpower/" className="text-white me-4">
              <i className="fab fa-instagram" />
            </a>
            <a href="https://pk.linkedin.com/company/ogs-pvt-limited-&-manpower" className="text-white me-4">
              <i className="fab fa-linkedin" />
            </a>
          </div>
        </section>
        <section className="bg-white">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold"> OGS MANPOWER</h6>
                <p className="ogsfonts16">
                  OGS Group of Companies, OGS Manpower, OGS Travel & Tourism OGS
                  Student Visa
                </p>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">USEFUL LINKS</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: 107,
                    backgroundColor: "rgb(124, 77, 255)",
                    height: 2,
                  }}
                />
                <p>
                  <a href="/" className="text-dark ogsfonts16">
                    Home
                  </a>
                </p>
                <p>
                  <a href="/jobs" className="text-dark ogsfonts16">
                    All Jobs
                  </a>
                </p>
              </div>
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Useful links</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: 107, backgroundColor: "rgb(124, 77, 255)" }}
                />
                <p>
                  <a href="/companysignin" className="text-dark ogsfonts16">
                    Employer login
                  </a>
                </p>
                <p>
                  <a href="/seekersignin" className="text-dark ogsfonts16">
                    Seeker login
                  </a>
                </p>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-2 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: 74,
                    backgroundColor: "rgb(124, 77, 255)",
                    height: 2,
                  }}
                />
                <p>
                  <i className="fas fa-home mr-3" /> Noor plaza chandni chowk ,
                  Rawalpindi , Pakistan
                </p>
                <p>
                  <i className="fas fa-envelope mr-3" /> ceo@ogsmanpower.com
                </p>
                <p>
                  <i className="fas fa-phone mr-3" /> + (051)4906572
                </p>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-2 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold">Visa Request</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: 108,
                    backgroundColor: "rgb(124, 77, 255)",
                    height: 2,
                  }}
                />
                <br />
                OGS MANPOWER can help with issuance of Visa's for more
                information contact us via email or phone
              </div>
            </div>
          </div>
        </section>
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgb(236, 239, 241)" }}
        >
          Copyright © 2022 OGS MANPOWER | All Rights Reserved
        </div>
      </footer>
    </HelmetProvider>
  );
};

export default About;
