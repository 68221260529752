import React, { useEffect, useState } from "react";
import Navigation from "../components/Navbar/Navbar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
const JobDetails = () => {
  let { id } = useParams();
  const [jobDetails, setJobDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [industry, setIndustry] = useState("");
  const [jobDate, setJobDate] = useState("");
  const [jobLoading, setJobLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [myCv, setMyCv] = useState([]);
  const [myCvLoaded, setMyCvLoaded] = useState(false);
  const [getCv, setGetCv] = useState(false);
  const [saved, setSaved] = useState(false);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [applied, setJobApplied] = useState(false);

  const [additionalSelected, setAdditionalSelected] = useState(0);

  const [selectedCv, setSelectedCv] = useState(0);
  const [selectedBasic, setSelectedBasic] = useState(0);

  const [additionalList, setAdditionalList] = useState([]);

  // CHECK IF USER ALREADY APPLIED TO JOB
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      const decodedToken = jwtDecode(localStorage.getItem("accessToken"));
      axios
        .get(
          process.env.REACT_APP_BASE_URL +"/static/checkJobApplied/" +
            id +
            "/" +
            decodedToken.userId
        )
        .then((response) => {
          if (response.data.code > 1) {
            setJobApplied(false);
          } else {
            setJobApplied(true);
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      console.log("accessToken not found");
    }
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = (basic, jobid) => {
    if (selectedJobTitle == null) {
      alert("Select a job title!");
    } else {
      axios
      .post(
        process.env.REACT_APP_BASE_URL +`/job/job_apply/${basic}/${jobid}/${selectedJobTitle}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        setIsModalOpen(false);
        setJobApplied(true);
      })
      .catch((error) => alert(error));
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUpload = async (e) => {
    let newImage = e.target.files[0];
    let formData = new FormData();
    formData.append("image", newImage);
    await axios
      .post(
        process.env.REACT_APP_BASE_URL +`/static/additional_upload/${id}/${selectedBasic}/${additionalSelected}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => console.log(response));
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL +`/job/job_details/${id}`)
      .then((response) => {
        console.log(response);
        setJobDetails(response.data.jobDetails[0]);
        setCompanyDetails(response.data.company[0]);
        setIndustry(
          response.data.industry.length > 0
            ? response.data.industry[0]
            : "Undefined"
        );

        let date = new Date(response.data.jobDetails[0].posted_on);
        let options = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        setJobDate(date.toLocaleDateString("en-US", options));
        setJobLoading(false);
      });
  }, []);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL +`/static/getAdditional/${id}`)
      .then((response) => {
        setAdditionalList(response.data.result);
      });
  }, []);
  useEffect(() => {
    if (getCv === true) {
      axios
        .post(
          process.env.REACT_APP_BASE_URL +"/cv/getCv",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((response) => {
          setMyCv(response.data.cv);
          setGetCv(false);
          setMyCvLoaded(true);
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [getCv]);
  return (
    <>
      <Navigation />
      <div className="inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-content text-center">
                <h1>Job Details</h1>
                <span />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Job Details
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="job-details-pages pt-120 mb-120">
        <div className="container">
          <div className="row g-lg-4 gy-5">
            <div className="col-lg-8">
              <div className="job-details-content">
                <div className="job-list-content">
                  <div className="company-area">
                    <div className="logo"></div>
                    <div className="company-details">
                      <div className="name-location">
                        <h5>
                          <a href="#">
                            {jobLoading ? "" : jobDetails.job_title}
                          </a>
                        </h5>
                        <p>{jobLoading ? "" : companyDetails.company_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="job-discription">
                    <ul className="one">
                      <li>
                        <img src="/assets/images/icon/map-2.svg" alt="" />
                        <p>
                          <span className="title">Location:</span>{" "}
                          {jobLoading ? "" : jobDetails.country}
                        </p>
                      </li>
                      <li>
                        <img src="/assets/images/icon/category-2.svg" alt="" />
                        <p>
                          <span className="title">Category:</span>{" "}
                          {jobLoading ? "" : industry.name}
                        </p>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <img src="/assets/images/icon/company-2.svg" alt="" />
                        <p>
                          <span className="title">Job Type:</span> Full-Time
                        </p>
                      </li>
                      <li>
                        <img src="/assets/images/icon/salary-2.svg" alt="" />
                        <p>
                          <span className="title">Salary:</span>{" "}
                          <div>
                            {jobDetails.salary} {jobDetails.currency}
                          </div>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                  <span>Job Description:</span>{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jobLoading ? "" : jobDetails.description,
                    }}
                  ></div>
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="job-details-sidebar mb-120">
                <div className="save-apply-btn d-flex justify-content-end mb-50">
                  <ul>
                    <li>
                      {saved ? (
                        <a className="save-btn" href="#" disabled>
                          Saved{" "}
                          <span>
                            <i className="bi bi-bookmark-fill" />
                          </span>
                        </a>
                      ) : (
                        <a
                          className="save-btn"
                          href="#"
                          onClick={() => setSaved(true)}
                        >
                          Save Job{" "}
                          <span>
                            <i className="bi bi-bookmark-fill" />
                          </span>
                        </a>
                      )}
                    </li>
                    <li>
                      {localStorage.getItem("accessToken") &&
                      localStorage.getItem("accessToken").trim().length !==
                        0 ? (
                        <>
                          {applied ? (
                            <a
                              className="primry-btn-2 lg-btn"
                              onClick={() => {
                                setGetCv(true);
                              }}
                              style={{ backgroundColor: "gray" }}
                              disabled
                            >
                              Applied
                            </a>
                          ) : (
                            <a
                              className="primry-btn-2 lg-btn"
                              onClick={() => {
                                showModal();
                                setGetCv(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Apply Position
                            </a>
                          )}
                        
                            {myCvLoaded === true && myCv.length > 0
                              ? myCv.map((cv, index) => (
                                <Modal
                                title="Apply To Job"
                                open={isModalOpen}
                                onOk={() => {
                                  handleOk(cv.basic_info_id, id);
                                  console.log(cv.basic_info_id,'selectedBasic');
                                  setSelectedBasic() }}
                                onCancel={handleCancel}
                              >
                                  <div
                                    key={cv.id}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      setSelectedBasic(cv.basic_info_id)
                                    }
                                  >
                                    <div
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "100%",
                                        backgroundImage: `url(`+process.env.REACT_APP_BASE_URL +`/profile_images/${cv.profile_image})`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        border:
                                          selectedCv == index
                                            ? "1px solid blue"
                                            : "",
                                      }}
                                      onClick={() => {
                                        setSelectedBasic(cv.basic_info_id);
                                      }}
                                    ></div>
                                    <div className="row">
                                      <div className="col-12">
                                        <br />
                                        <label>Select Job Title:</label>
                                        <div className="row">
                                        <div className="col-6">
                                        <label>
                                          <input
                                            type="checkbox"
                                            value={cv.designation}
                                            style={{ marginRight: '10px' }}
                                            checked={selectedJobTitle == cv.designation}
                                            onChange={() => setSelectedJobTitle(cv.designation)}
                                          />
                                           { cv.designation }
                                        </label>
                                        </div>
                                        {
                                          cv.d_two != "none" ? <div className="col-6">
                                          <label>
                                            <input
                                              type="checkbox"
                                              value={cv.d_two}
                                              style={{ marginRight: '10px' }}
                                              checked={selectedJobTitle == cv.d_two}
                                              onChange={() => setSelectedJobTitle(cv.d_two)}
                                            />
                                             { cv.d_two }
                                          </label>
                                          </div> : ""
                                        }
                                                                                {
                                          cv.d_three != "none" ? <div className="col-6">
                                          <label>
                                            <input
                                              type="checkbox"
                                              value={cv.d_three}
                                              style={{ marginRight: '10px' }}
                                              checked={selectedJobTitle == cv.d_three}
                                              onChange={() => setSelectedJobTitle(cv.d_three)}
                                            />
                                             { cv.d_three }
                                          </label>
                                          </div> : ""
                                        }
                                                                                {
                                          cv.d_four != "none" ? <div className="col-6">
                                          <label>
                                            <input
                                              type="checkbox"
                                              value={cv.d_four}
                                              checked={selectedJobTitle == cv.d_four}
                                              onChange={() => setSelectedJobTitle(cv.d_four)}
                                              style={{ marginRight: '10px' }}
                                            />
                                             { cv.d_four }
                                          </label>
                                          </div> : ""
                                        }
                                                                                {
                                          cv.d_five != "none" ? <div className="col-6">
                                          <label>
                                            <input
                                              type="checkbox"
                                              value={cv.d_five}
                                              checked={selectedJobTitle == cv.d_five}
                                              onChange={() => setSelectedJobTitle(cv.d_five)}
                                              style={{ marginRight: '10px' }}
                                            />
                                             { cv.d_five }
                                          </label>
                                          </div> : ""
                                        }
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <br />
                                        <label>Full Name:</label> <br />
                                        {cv.first_name + " " + cv.last_name}
                                      </div>
                                      <div className="col-6">
                                        <br />
                                        <label>Designation:</label> <br />
                                        {cv.designation}
                                      </div>
                                      <div className="col-6">
                                        <br />
                                        <label>Phone Numer:</label> <br />
                                        {cv.phone}
                                      </div>
                                      <div className="col-6">
                                        <br />
                                        <label>Email:</label> <br />
                                        {cv.email}
                                      </div>
                                    </div>
                                    <hr />
                                    {additionalList.map((a) => (
                                      <div className="col-md-12">
                                        <div className="form-inner mb-25">
                                          <label htmlFor="jobtitle">
                                            {a.label}*
                                          </label>
                                          <div className="input-area">
                                            <input
                                              type="file"
                                              style={{
                                                border: "1px solid #e7e7e7",
                                                padding: "10px",
                                              }}
                                              onChange={(e) => {
                                                setAdditionalSelected(a.id);
                                                handleUpload(e);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                
                                </Modal>
                                ))
                              : "No CVs Found"}
                        
                        </>
                      ) : (
                        <Link
                          to="/signup"
                          className="primry-btn-2 lg-btn"
                          href="#"
                        >
                          Login to apply
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
                <div className="job-summary-area mb-50">
                  <div className="job-summary-title">
                    <h6>Job Summary:</h6>
                  </div>
                  <ul>
                    <li>
                      <p>
                        <span className="title">Job Posted:</span>{" "}
                        {jobLoading ? "" : jobDate}
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="title">Expiration:</span> 03 March,
                        2023.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="title">Vacancy:</span>{" "}
                        {jobLoading ? "" : jobDetails.positions}.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="title">Min. Experiences:</span>{" "}
                        {jobLoading ? "" : jobDetails.min_experience}.
                      </p>
                    </li>
                  </ul>
                </div>
                {/* <div className="view-job-btn mb-30">
                  <a href="job-listing1.html">
                    <img src="/assets/images/icon/company-2.svg" alt="" />
                    View All Jobs In This Company
                  </a>
                </div>
                <div className="job-share-area mb-50">
                  <h6>Job Link Share:</h6>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="bx bx-link" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/">
                        <i className="bx bxl-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/">
                        <i className="bx bxl-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/">
                        <i className="bx bxl-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/">
                        <i className="bx bxl-instagram-alt" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="email-area mb-50">
                  <div className="title">
                    <h6>
                      <img src="/assets/images/icon/email-2.svg" alt="" />
                      Email Now
                    </h6>
                  </div>
                  <p>
                    Send your resume at{" "}
                    <a href="/cdn-cgi/l/email-protection#533a3d353c13362b323e233f367d303c3e">
                      <span
                        className="__cf_email__"
                        data-cfemail="147d7a727b54716c75796478713a777b79"
                      >
                        [email&nbsp;protected]
                      </span>
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
